import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Toast } from "primereact/toast";
import CardsPage from "./statistics-cards/CardsPage";
import MapsPage from "./maps/MapsPage";
import BarChartsPage from "./bar-charts/BarChartsPage";

function DashboardPage() {
    const toast = useRef(null); // Reference for the PrimeReact Toast
    const location = useLocation(); // Hook to access router state
    console.log("🚀 ~ DashboardPage ~ location:", location);

    useEffect(() => {
        if (location?.state?.message) {
            const timer = setTimeout(() => {
                toast.current.show({
                    severity: "success",
                    summary: `🎉 Welcome, ${location?.state?.userDetails?.name}! 🎉`,
                    detail: location?.state?.message,
                    life: 4000, // Message duration in milliseconds
                });
            }, 2000); // Wait 2 seconds before showing the toast

            // Cleanup the timeout if the component unmounts or dependencies change
            return () => clearTimeout(timer);
        }
    }, [location?.state?.message, location?.state?.userDetails?.name]);

    return (
        <div>
            <Toast ref={toast} /> {/* Add the Toast component */}
            <div className="col-12">
                <CardsPage />
            </div>
            <div className="col-12">
                <MapsPage />
            </div>
            <div className="col-12">
                <BarChartsPage />
            </div>
        </div>
    );
}

export default DashboardPage;
