import React, { useEffect, useState, useRef } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import FeedbackReportDetailsModal from "../global_views/feedback_report/FeedbackReportDetailsModal";

import moment from "moment";

import { useNavigate } from "react-router-dom";

import { getAllFeedbackReports } from "../../services/feedback-reports/feedback-reports-service.js";

import MuiTable from "../../components/general_components/MuiTable";
import { toast } from "react-toastify";

import { Panel } from "primereact/panel";

import { Tag } from "primereact/tag";
import { ProgressBar } from "primereact/progressbar";

//
import AdvancedOverviewFilterForm from "./AdvancedOverviewFilterForm";

//
import useHandleQueryError from "../../hooks/useHandleQueryError";

import InlineExpandableText from "../../components/helpers/InlineExpandableText";
function OverviewListPage({ loggedInUserData, level, spatieCurrentStatuses, showProposingColumns, showAgreedColumns, multipleSelect = true, ...props }) {
    const navigate = useNavigate();

    //
    const [selectedFilters, setSelectedFilters] = useState({
        startDate: moment().subtract(90, "days").toDate(),
        endDate: moment().toDate(),
        selectedStatuses: [],
    });

    const queryClient = useQueryClient();

    const { data, isLoading, isError, error, status } = useQuery({
        queryKey: ["feedback_reports", selectedFilters],
        queryFn: () =>
            getAllFeedbackReports({
                startDate: selectedFilters?.startDate ? moment(selectedFilters?.startDate).format("YYYY-MM-DD HH:mm:ss") : null,
                endDate: selectedFilters?.endDate ? moment(selectedFilters?.endDate).format("YYYY-MM-DD HH:mm:ss") : null,
                financial_year_id: selectedFilters?.financial_year?.id,
                quarter_id: selectedFilters?.quarter?.id,
                spatie_current_statuses: selectedFilters?.selectedStatuses,
            }),
    });
    console.log("🚀 ~Feedback Report OverviewListPage ~ data: ", data);

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(isError, error);

    // const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;
    const activeUser = loggedInUserData;

    //
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [selectedFeedback, setSelectedFeedback] = useState(null);

    const handleShowFeedbackModal = (rowData) => {
        setSelectedFeedback(rowData);
        setShowFeedbackModal(true);
    };

    const handleCloseFeedbackModal = () => {
        setShowFeedbackModal(false);
    };

    //
    // Helper function to determine progress bar color
    const getProgressBarColor = (completion) => {
        if (completion <= 33) {
            return "red"; // Low completion
        } else if (completion <= 66) {
            return "orange"; // Medium completion
        }
        return "green"; // High completion
    };

    const capitalizeFirstLetter = (str) => {
        if (!str) return "N/A";
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const columns = [
        { title: "#", width: "5%", field: "id", hidden: true },
        {
            title: "Type",
            field: "type",
            render: (rowData) => {
                return capitalizeFirstLetter(rowData?.type);
            },
        },

        {
            title: "Financial Year",
            field: "financial_year.name",
            render: (rowData) => (
                <Tag
                    value={rowData?.financial_year?.name ? rowData?.financial_year?.name : "N/A"}
                    severity={rowData?.financial_year?.name ? "primary" : "info"} // 'info' for blue, 'success' for green
                />
            ),
        },
        {
            title: "Quarter",
            field: "quarter.name",
            render: (rowData) => (
                <Tag
                    value={rowData?.quarter?.name ? rowData?.quarter?.name : "N/A"}
                    severity={rowData?.quarter?.name ? "warning" : "info"} // 'info' for blue, 'success' for green
                />
            ),
        },

        {
            title: "Title",
            field: "name",
            render: (rowData) => {
                const maxLength = 20; // Define max length before truncation
                const title = rowData?.name || "No title";

                return <InlineExpandableText text={title} maxLength={maxLength} />;
            },
        },
        {
            title: "Description",
            field: "description",
            render: (rowData) => {
                const maxLength = 20; // Define max length before truncation
                const description = rowData?.description || "No description";

                return <InlineExpandableText text={description} maxLength={maxLength} />;
            },
        },
        {
            title: "Completion",
            field: "completion",
            render: (rowData) => {
                const completionValue = rowData?.completion ? rowData.completion : 0; // Explicit null/undefined check
                return (
                    <div>
                        {/* <center>
                            <small>{completionValue}%</small>
                        </center> */}
                        <ProgressBar value={completionValue} color={getProgressBarColor(completionValue)} showValue={true} style={{ height: "16px" }} />
                    </div>
                );
            },
        },

        {
            title: "Start Date",
            field: "start_date",
            render: (rowData) => (rowData?.start_date ? moment(rowData.start_date).format("dddd, MMMM D, YYYY") : "N/A"),
        },
        {
            title: "End Date",
            field: "end_date",
            render: (rowData) => (rowData?.end_date ? moment(rowData.end_date).format("dddd, MMMM D, YYYY") : "N/A"),
        },

        { title: "Current Status", field: "spatie_current_status.name", hidden: true },
        { title: "Current Status Reason", field: "spatie_current_status.reason", hidden: true },
        { title: "Created By", field: "created_by.name", hidden: true },
        { title: "Created By Email", field: "created_by.email", hidden: true },
        { title: "Updated By", field: "updated_by.name", hidden: true },
        {
            title: "Created At",
            field: "created_at",
            hidden: true,
            render: (rowData) => {
                return moment(rowData?.created_at).format("lll");
            },
        },
        {
            title: "Updated At",
            field: "updated_at",
            hidden: true,
            render: (rowData) => {
                return moment(rowData?.updated_at).format("lll");
            },
        },
        { title: "Status", field: "status", hidden: true },
    ];

    //

    //============== selected filters ===================

    const handleSubmitAdvancedFilter = (formData) => {
        console.log("Form submitted with:", formData);

        setSelectedFilters(formData);
        // Handle form submission (e.g., API call, state update)
    };

    return (
        <div style={{ width: "100%" }}>
            <div>
                <AdvancedOverviewFilterForm initialData={selectedFilters} onSubmit={handleSubmitAdvancedFilter} />
            </div>
            <Panel header="Feedback Reports" style={{ marginBottom: "20px" }} toggleable>
                <MuiTable
                    // tableTitle="Citized Feedback"
                    tableData={data?.data?.data ?? []}
                    tableColumns={columns}
                    loading={isLoading || status === "loading"}
                    // //
                    handleViewPage={(rowData) => {
                        // navigate("product-category-brand", { state: { productCategoryBrandData: rowData } });
                        handleShowFeedbackModal(rowData);
                    }}
                    showViewPage={true}
                    hideRowViewPage={false}
                    //
                    // exportButton={true}
                    // pdfExportTitle="Feedback Reports"
                    // csvExportTitle="Feedback Reports"
                />

                {showFeedbackModal && <FeedbackReportDetailsModal showProposingColumns={showProposingColumns} showAgreedColumns={showAgreedColumns} feedbackReportData={selectedFeedback} show={showFeedbackModal} onHide={handleCloseFeedbackModal} onClose={handleCloseFeedbackModal} />}
            </Panel>
        </div>
    );
}

export default OverviewListPage;
