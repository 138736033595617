import React, { useState, useEffect } from "react";

import { Dialog } from "primereact/dialog";

import { getAllServicesFeedbackComments, getServicesFeedbackCommentById, postServicesFeedbackComment, updateServicesFeedbackComment, deleteServicesFeedbackCommentById } from "../../../services/services-feedback/services-feedback-comments-service.js";

import CommentsRow from "./widgets/CommentsRow";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

//
import handleMutationError from "../../../hooks/useHandleMutationError";

//
import useHandleMutationError from "../../../hooks/useHandleMutationError.js";

function SingleCommentsEditForm({ selectedTableRowData, ...props }) {
    const queryClient = useQueryClient();

    const [editMutationIsLoading, setEditMutationIsLoading] = useState(false);
    const editMutation = useMutation({
        mutationFn: (variables) => updateServicesFeedbackComment(props?.rowData?.id, variables),
        onSuccess: () => {
            queryClient.invalidateQueries(["services_feedback"]);
            // queryClient.resetQueries(["summarised_feedback"]);
            toast.success("Comment Edited Successfully");
            props.onClose();
            setEditMutationIsLoading(false);
            // setTableSelectedRows([]);
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(editMutation?.error, setEditMutationIsLoading);

    const handleSubmit = async (data) => {
        // event.preventDefault();
        setEditMutationIsLoading(true);

        console.log("comments form data we are submitting edit comments : ", data);
        let finalData = {
            comment: data?.comment,
        };
        console.log("🚀 ~ handleSubmit ~ finalData edit comments :", finalData);
        editMutation.mutate(finalData);
    };

    return (
        <Dialog header="Comment" visible={props.show} maximizable style={{ minWidth: "50vw" }} onHide={() => props.onHide()}>
            <p>Comment on report</p>
            <CommentsRow onSubmit={handleSubmit} initialData={props?.rowData} />
            {/* <h4>{creactProgramsMutation.status}</h4> */}
            {editMutationIsLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
            {/* <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Program Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            </Modal.Body>
        </Modal> */}
        </Dialog>
    );
}

export default SingleCommentsEditForm;
