import React, { useMemo } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const FeedbackByRegionPieChart = ({ data = [] }) => {
    // Aggregate feedback by region
    const feedbackByRegion = useMemo(() => {
        const aggregation = {};
        data.forEach((row) => {
            const regionName = row["region.name"]; // Extract region name from the nested structure
            if (regionName) {
                aggregation[regionName] = (aggregation[regionName] || 0) + 1;
            }
        });
        return Object.entries(aggregation).map(([name, count]) => ({
            name,
            y: count,
        }));
    }, [data]);

    // Generate chart options
    const chartOptions = {
        chart: {
            type: "pie",
        },
        title: {
            text: "Feedback By Region",
        },
        series: [
            {
                name: "Feedback Count",
                colorByPoint: true,
                data: feedbackByRegion,
            },
        ],
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: true,
                    format: "<b>{point.name}</b>: {point.y}",
                },
            },
        },
        exporting: {
            enabled: false, // Disable exporting module
        },
        credits: {
            enabled: false, // Disable credits
        },
    };

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default FeedbackByRegionPieChart;
