import React, { useState, useEffect } from "react";

import { getAllFinancialYears, getFinancialYearsById, postFinancialYears, updateFinancialYears, deleteFinancialYears } from "../../../services/financial-years/financial-years-service";

import RowForm from "./widgets/RowForm";
import { useQueryClient, useMutation } from "@tanstack/react-query";

import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

//
import useHandleMutationError from "../../../hooks/useHandleMutationError.js";

import moment from "moment";

function EditForm(props) {
    const queryClient = useQueryClient();

    const [editMutationIsLoading, setEditMutationIsLoading] = useState(false);
    const editMutation = useMutation({
        mutationFn: (variables) => updateFinancialYears(props?.rowData?.id, variables),
        onSuccess: () => {
            setEditMutationIsLoading(false);
            props.onClose();
            toast.success("Edited Successfully");
            queryClient.invalidateQueries(["financial-years"]);
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(editMutation?.error, setEditMutationIsLoading);

    const handleSubmit = (data) => {
        // Format `start_date` and `end_date` to MySQL-compatible datetime
        if (data.start_date) {
            data.start_date = moment(data.start_date).format("YYYY-MM-DD HH:mm:ss");
        }
        if (data.end_date) {
            data.end_date = moment(data.end_date).format("YYYY-MM-DD HH:mm:ss");
        }

        setEditMutationIsLoading(true);
        console.log(data);

        editMutation.mutate(data);
    };

    return (
        <Dialog header="financial Year Form" visible={props.show} maximizable onHide={() => props.onHide()}>
            {/* <h3>Programs Edit Form</h3> */}
            <p>Edit Data Below</p>
            <RowForm initialData={props.rowData} handleSubmit={handleSubmit} />
            {/* <h4>{creactProgramsMutation.status}</h4> */}

            {editMutationIsLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
        </Dialog>
    );
}

export default EditForm;
