import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { classNames } from "primereact/utils";

//
import { toast } from "react-toastify";
import { AutoComplete } from "primereact/autocomplete";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteIcon from "@mui/icons-material/Delete";

import { Calendar } from "primereact/calendar";

function RowForm({ handleSubmit, initialData, ...props }) {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const queryClient = useQueryClient();

    const validate = (values) => {
        const errors = {};

        if (!values.name) errors.name = "Name is required";
        if (!values.start_month) errors.start_month = "Start Month is required";
        if (!values.end_month) errors.end_month = "End Month is required";
        // if (!values.number_of_months) errors.number_of_months = "End Month is required";
        if (!values.status) {
            errors.status = "Status is required";
        }

        return errors;
    };

    const onSubmitForm = (data) => {
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            // No validation errors
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            toast.warning("First Fill In All Required Fields");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                console.log("values hhh : ", values);
                                console.log("event fffff : ", event);
                                onSubmitForm(values);
                                // handleSubmit(event, values);
                            }}
                        >
                            <Field name="name">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="name">Name</label>
                                        <InputText {...input} id="name" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="start_month">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="start_month">Start Month</label>
                                        <Dropdown
                                            {...input}
                                            id="start_month"
                                            options={[
                                                { label: "January", value: 1 },
                                                { label: "February", value: 2 },
                                                { label: "March", value: 3 },
                                                { label: "April", value: 4 },
                                                { label: "May", value: 5 },
                                                { label: "June", value: 6 },
                                                { label: "July", value: 7 },
                                                { label: "August", value: 8 },
                                                { label: "September", value: 9 },
                                                { label: "October", value: 10 },
                                                { label: "November", value: 11 },
                                                { label: "December", value: 12 },
                                            ]}
                                            placeholder="Select Start Month"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="end_month">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="end_month">End Month</label>
                                        <Dropdown
                                            {...input}
                                            id="end_month"
                                            options={[
                                                { label: "January", value: 1 },
                                                { label: "February", value: 2 },
                                                { label: "March", value: 3 },
                                                { label: "April", value: 4 },
                                                { label: "May", value: 5 },
                                                { label: "June", value: 6 },
                                                { label: "July", value: 7 },
                                                { label: "August", value: 8 },
                                                { label: "September", value: 9 },
                                                { label: "October", value: 10 },
                                                { label: "November", value: 11 },
                                                { label: "December", value: 12 },
                                            ]}
                                            placeholder="Select end Month"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            {/* <Field name="number_of_months">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="number_of_months">Number of Months</label>
                                        <Dropdown
                                            {...input}
                                            id="number_of_months"
                                            options={[
                                                { label: "2 months", value: 2 },
                                                { label: "3 months", value: 3 },
                                                { label: "4 months", value: 4 },
                                            ]}
                                            placeholder="Select Number of Months"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field> */}

                            <Field name="status">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="status">Status</label>
                                        <Dropdown
                                            {...input}
                                            options={[
                                                { id: "active", name: "Active" },
                                                { id: "deactive", name: "Deactive" },
                                            ].map((role) => ({ label: role.name, value: role.id }))}
                                            placeholder="Select a Status"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    maximizable
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default RowForm;
