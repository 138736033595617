import React, { useState, useEffect } from "react";

import { Dialog } from "primereact/dialog";

import { getAllSummarisedFeedbackComments, getSummarisedFeedbackCommentById, postSummarisedFeedbackComment, updateSummarisedFeedbackComment, deleteSummarisedFeedbackCommentById } from "../../../services/feedback/summarised-feedback-comments-service";

import CommentsRow from "./widgets/CommentsRow";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

//
import handleMutationError from "../../../hooks/useHandleMutationError";

//
import useHandleMutationError from "../../../hooks/useHandleMutationError.js";

function SingleCommentsForm({ selectedTableRowData, ...props }) {
    const queryClient = useQueryClient();

    const [creactMutationIsLoading, setCreactMutationIsLoading] = useState(false);
    const creactMutation = useMutation({
        mutationFn: postSummarisedFeedbackComment,
        onSuccess: () => {
            queryClient.invalidateQueries(["summarised_feedback", "comments", "summarised_feedback_id", selectedTableRowData?.summarised_feedback_id]);
            // queryClient.resetQueries(["summarised_feedback"]);
            toast.success("Comment Added Successfully");
            props.onClose();
            setCreactMutationIsLoading(false);
            // setTableSelectedRows([]);
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(creactMutation?.error, setCreactMutationIsLoading);

    const handleSubmit = async (data) => {
        // event.preventDefault();
        setCreactMutationIsLoading(true);

        console.log("comments form data we are submitting : ", data);
        let finalData = {
            comment: data?.comment,
            summarised_feedback_id: selectedTableRowData?.id,
        };
        console.log("🚀 ~ handleSubmit ~ finalData:", finalData);
        creactMutation.mutate(finalData);
    };

    return (
        <Dialog header="Comment" visible={props.show} maximizable style={{ minWidth: "50vw" }} onHide={() => props.onHide()}>
            <p>Comment on report</p>
            <CommentsRow onSubmit={handleSubmit} productCategoryBrandData={props?.productCategoryBrandData} />
            {/* <h4>{creactProgramsMutation.status}</h4> */}
            {creactMutationIsLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
            {/* <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Program Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            </Modal.Body>
        </Modal> */}
        </Dialog>
    );
}

export default SingleCommentsForm;
