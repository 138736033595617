import React, { useState } from "react";

const InlineExpandableText = ({ text = "", maxLength }) => {
    const [expanded, setExpanded] = useState(false);

    // Click handler to toggle text expansion
    const toggleText = () => setExpanded(!expanded);

    // Conditional style
    const toggleStyle = text.length > maxLength ? { cursor: "pointer" } : {};

    return (
        <span>
            {expanded ? (
                <span style={toggleStyle} onClick={toggleText}>
                    {text}{" "}
                </span>
            ) : (
                <span style={toggleStyle} onClick={toggleText}>
                    {text.length > maxLength ? `${text.substring(0, maxLength)}...` : text}{" "}
                </span>
            )}
        </span>
    );
};

export default InlineExpandableText;
