import React, { useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";
import { RadioButton } from "primereact/radiobutton";

import { Dropdown } from "primereact/dropdown";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { classNames } from "primereact/utils";
import { getAllGroupTypes } from "../../../services/grouptypes/grouptypes-service";

import setFieldTouched from "final-form-set-field-touched";

//
import { toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteIcon from "@mui/icons-material/Delete";
import { FileUpload } from "primereact/fileupload";

import useHandleQueryError from "../../../hooks/useHandleQueryError";
import { ProgressSpinner } from "primereact/progressspinner";

//
import useAuthContext from "../../../context/AuthContext";

function RowForm({ handleSubmit, initialData, ...props }) {
    console.log("🚀df ~ RowForm ~ initialData:", initialData);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);

    const { getUserQuery } = useAuthContext();
    const loggedInUserData = getUserQuery?.data?.data;

    const groupTypesQuery = useQuery({
        queryKey: ["groupTypes", loggedInUserData?.role],
        queryFn: () => getAllGroupTypes({ get_by_logged_in_user: ["CSO Admin"].includes(loggedInUserData?.role) ? true : false }),
    });

    useHandleQueryError(groupTypesQuery.isError, groupTypesQuery.error);

    const validate = (values) => {
        const errors = {};

        if (!values.name) errors.name = "Name is required";
        if (!values.code) errors.code = "code is required";
        if (!values.status) errors.status = "status is required";
        if (!values.grouptype_id) errors.grouptype_id = "Group Type is required";
        // if (!values.price) errors.price = "Price is required";
        // if (!values.quantity) errors.quantity = "Quantity is required";

        return errors;
    };

    const onSubmitForm = (data, form) => {
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            // No validation errors
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("Please first fill in all required fields");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            console.log("pending Data groups", pendingData);
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    mutators={{ setFieldTouched }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                console.log("values hhh : ", values);
                                console.log("event fffff : ", event);
                                onSubmitForm(values, form);
                                // handleSubmit(event, values);
                            }}
                        >
                            <Field name="grouptype_id">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="grouptype_id">Group Type</label>
                                        <Dropdown
                                            {...input}
                                            options={(groupTypesQuery?.data?.data?.data || []).map((role) => ({ label: role.name, value: role.id }))}
                                            placeholder="Select Group Type"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                            // value={geography}
                                            disabled={groupTypesQuery?.isLoading}
                                            onChange={(e) => {
                                                input.onChange(e.value); // Sync with form
                                            }}
                                        />
                                        {groupTypesQuery.isLoading && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="name">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="name">Name</label>
                                        <InputText {...input} id="name" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="code">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="code">Code</label>
                                        <InputText {...input} id="code" type="text" className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Field name="status">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="status">Status</label>
                                        <Dropdown
                                            {...input}
                                            options={[
                                                { id: "active", name: "Active" },
                                                { id: "deactive", name: "Deactive" },
                                            ].map((role) => ({ label: role.name, value: role.id }))}
                                            placeholder="Select a Status"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <div className="d-grid gap-2">
                                <Button type="submit" label="Save" className="p-button-primary" icon="pi pi-check" />
                            </div>
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    maximizable
                    style={{ minWidth: "30vw" }}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default RowForm;
