import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import Tooltip from "@mui/material/Tooltip";
import FeedbackDetailsModal from "../../global_views/summarised_feedback/SummarisedFeedbackDetailsModal";

import moment from "moment";

const TaggedSummarisedFeedbackOnCreateTable = ({ feedbacks = [], setFeedbacks = () => {}, form }) => {
    console.log("🚀 ~ TaggedSummarisedFeedbackOnCreateTable ~ feedbacks:", feedbacks);
    // const [feedbacks, setFeedbacks] = useState([
    //     {
    //         id: 12,
    //         feedback_no: "FB-SRH1MSSS",
    //         message: "Poor roads",
    //         created_at: "2024-09-20T12:16:46.000000Z",
    //         county: { name: "Agago west county" },
    //         district: { name: "Agago" },
    //         region: { name: "ACHOLI" },
    //         sector: { name: "Government" },
    //         created_by: { name: "John Doe" },
    //     },
    //     // Add more sample data here if needed
    // ]);

    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [selectedFeedback, setSelectedFeedback] = useState(null);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);

    const confirmDelete = (rowData) => {
        setSelectedFeedback(rowData);
        setDeleteDialogVisible(true);
    };

    const deleteFeedback = () => {
        // Remove the selected feedback
        const updatedFeedbacks = feedbacks.filter((f) => f.id !== selectedFeedback.id);
        setFeedbacks(updatedFeedbacks);
        setDeleteDialogVisible(false);

        // Recompute the date range
        if (updatedFeedbacks.length > 0) {
            const dates = updatedFeedbacks.map((f) => moment(f.created_at, "YYYY-MM-DD"));

            // Calculate start and end of the range
            const startDate = moment.min(dates).startOf("day");
            const endDate = moment.max(dates).endOf("day");

            // Update the form with the new dates
            form.change("start_date", startDate.toISOString());
            form.change("endDate", endDate.toISOString());
        } else {
            // If no feedbacks are left, clear the form dates
            form.change("start_date", null);
            form.change("end_date", null);
        }

        // Optional: Make an API call to delete the feedback on the server
        // api.delete(`/feedback/${selectedFeedback.id}`).then(...).catch(...);
    };

    const viewFeedback = (rowData) => {
        setSelectedFeedback(rowData);
        setShowFeedbackModal(true);
    };

    const handleCloseFeedbackModal = () => {
        setShowFeedbackModal(false);
        setSelectedFeedback(null);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <Button
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-info p-button-text mr-2"
                    onClick={(e) => {
                        e.preventDefault();
                        viewFeedback(rowData);
                    }}
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-button-text"
                    onClick={(e) => {
                        e.preventDefault();
                        confirmDelete(rowData);
                    }}
                />
            </>
        );
    };

    const messageBodyTemplate = (rowData) => {
        const message = rowData?.message ?? "";
        const words = message.split(" ");
        const truncatedMessage = words.length > 2 ? words.slice(0, 2).join(" ") + "..." : message;

        return (
            <div style={{ maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                <Tooltip title={message} arrow placement="top">
                    <span>{truncatedMessage}</span>
                </Tooltip>
            </div>
        );
    };

    // Pagination Helper Function
    const getDynamicPaginationOptions = (data = []) => {
        const dataLength = data.length;
        if (dataLength <= 6) {
            return [2, 3, 6];
        }

        const portionSize = Math.ceil(dataLength / 3);
        const halfPortionSize = Math.ceil(portionSize / 2);
        const doublePortionSize = portionSize * 2;

        return [halfPortionSize, portionSize, doublePortionSize];
    };

    return (
        <div>
            <Toast />
            <h6>Tagged Summarised Feedback</h6>
            <DataTable
                value={feedbacks}
                responsiveLayout="scroll"
                paginator
                rows={getDynamicPaginationOptions(feedbacks)[0]} // Number of rows per page
                rowsPerPageOptions={getDynamicPaginationOptions(feedbacks)} // Options for rows per page
            >
                <Column field="feedback_no" header="Feedback No" />
                <Column field="message" header="Message" body={messageBodyTemplate} />
                <Column field="sector.name" header="Sector" />

                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: "8rem" }} />
            </DataTable>

            <ConfirmDialog visible={deleteDialogVisible} onHide={() => setDeleteDialogVisible(false)} message="Are you sure you want to remove this feedback?" header="Confirmation" icon="pi pi-exclamation-triangle" accept={deleteFeedback} reject={() => setDeleteDialogVisible(false)} />

            {showFeedbackModal && <FeedbackDetailsModal feedbackDetailData={selectedFeedback} show={showFeedbackModal} onHide={handleCloseFeedbackModal} onClose={handleCloseFeedbackModal} />}
        </div>
    );
};

export default TaggedSummarisedFeedbackOnCreateTable;
