import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useActive } from "./context/ActiveContext";
import { Menu } from "primereact/menu";

//
import { Sidebar } from "primereact/sidebar";
import { confirmDialog } from "primereact/confirmdialog";
import useAuthContext from "./context/AuthContext";
import { Button } from "primereact/button";
import Tooltip from "@mui/material/Tooltip";

//
import UpdateUserProfile from "./views/users/UpdateUserProfile";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";

//
import { useIdleTimer } from "react-idle-timer";
import { Howl } from "howler";

export const AppTopbar = (props) => {
    const { active, setActive } = useActive();
    const [profileMenuVisible, setProfileMenuVisible] = useState(false);
    const profileMenuRef = useRef(null);

    //
    const { getUserQuery } = useAuthContext();

    const toggleConfigurator = (event) => {
        setActive((prevState) => {
            if (prevState === "true") {
                return false;
            } else if (!prevState) {
                return "true";
            }
        });
    };

    const toggleProfileMenu = (event) => {
        setProfileMenuVisible((prevState) => !prevState);
        if (profileMenuRef.current) {
            profileMenuRef.current.toggle(event);
        }
    };

    const hideProfileMenu = () => {
        setProfileMenuVisible(false);
    };

    const profileMenuItems = [
        {
            label: "Profile Details",
            icon: "pi pi-fw pi-user",
            command: () => {
                hideProfileMenu();
                // Handle profile details click
            },
        },
        {
            label: "Sign Out",
            icon: "pi pi-fw pi-sign-out",
            command: () => {
                hideProfileMenu();
                // Handle sign out click
            },
        },
    ];

    //=============== logout =======================

    const { logoutMutation, logoutMutationIsLoading, setLogoutMutationIsLoading } = useAuthContext();
    const [dialogVisible, setDialogVisible] = useState(false);

    const handleLogout = () => {
        setDialogVisible(true);
    };

    const confirmLogout = async () => {
        try {
            setLogoutMutationIsLoading(true);
            await logoutMutation.mutateAsync();
        } catch (e) {
            console.log(e);
        } finally {
            setLogoutMutationIsLoading(false);
            setDialogVisible(false); // Close the dialog after logout completes
        }
    };

    const cancelLogout = () => {
        setDialogVisible(false); // Hide dialog on cancel
    };

    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const showSidebar = () => setIsSidebarVisible(true);
    const hideSidebar = () => setIsSidebarVisible(false);
    const userDetails = getUserQuery?.data?.data;
    console.log("user details hcbdkshcd : ", userDetails);

    //
    const [showUpdateUserForm, setShowUpdateUserForm] = useState(false);

    const handleShowUpdateUserForm = () => {
        setShowUpdateUserForm(true);
    };
    const handleCloseUpadateUserForm = () => {
        setShowUpdateUserForm(false);
    };

    //=================== handle user inactivity ===================
    const [isIdle, setIsIdle] = useState(false);
    const [countdown, setCountdown] = useState(10);
    const idleTimerRef = useRef(null);
    const countdownInterval = useRef(null);

    // Initialize Howler sound
    const sound = new Howl({
        src: ["/media/bell.mp3"],
    });

    // Start countdown timer
    const startCountdown = () => {
        // Ensure the user is logged in before starting the countdown
        if (!userDetails) return;

        let timer = 10; // 10 seconds countdown
        setCountdown(timer);
        countdownInterval.current = setInterval(() => {
            timer--;
            setCountdown(timer);
            sound.play(); // Play beep sound every second
            if (timer === 0) {
                handleIdleInactivityLogout(); // Auto logout when countdown ends
                clearInterval(countdownInterval.current);
            }
        }, 1000);
    };

    // Stop countdown timer
    const stopCountdown = () => {
        clearInterval(countdownInterval.current);
        setCountdown(10); // Reset countdown to 10 seconds
    };

    // Handle idle state
    const handleOnIdle = () => {
        // Ensure the user is logged in before handling idle state
        if (!userDetails) return;

        setIsIdle(true);
        sound.play(); // Play beep sound
        startCountdown();
    };

    // Handle "Yes" button click
    const handleStayLoggedIn = () => {
        setIsIdle(false);
        stopCountdown();
        reset(); // Reset idle timer
    };

    // Handle logout
    const handleIdleInactivityLogout = async () => {
        setIsIdle(false);
        stopCountdown();
        try {
            setLogoutMutationIsLoading(true);
            await logoutMutation.mutateAsync(); // Perform logout
        } finally {
            setLogoutMutationIsLoading(false);
        }
    };

    // Initialize the idle timer
    const { reset } = useIdleTimer({
        timeout: 300000, // 5 minutes in milliseconds
        // timeout: 10000, // 10 seconds in milliseconds
        onIdle: handleOnIdle,
        debounce: 500,
    });

    // Cleanup intervals on unmount
    useEffect(() => {
        return () => {
            clearInterval(countdownInterval.current);
        };
    }, []);
    return (
        <div className="layout-topbar">
            <Link className="layout-topbar-logo">
                <img src={props.layoutColorMode === "light" ? "assets/oag_photos/uganda_oag.png" : "assets/oag_photos/uganda_oag.png"} alt="logo" style={{ height: "50px" }} />
                {/* <span style={{ textDecoration: "!important none", cursor: "auto", color: "var(--primary-color)", fontWeight: "700" }}>OAG</span> */}
            </Link>

            <button type="button" className="p-link layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars" />
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

            <ul
                className={classNames("layout-topbar-menu lg:flex origin-top", {
                    "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive,
                })}
            >
                {/* <li>
                    <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                        <i className="pi pi-calendar" />
                        <span>Events</span>
                    </button>
                </li>
                <li>
                    <button
                        className="p-link layout-topbar-button"
                        onClick={(e) => {
                            toggleConfigurator(e);
                            props.onMobileSubTopbarMenuClick(e);
                        }}
                    >
                        <i className="pi pi-cog" />
                        <span>Settings</span>
                    </button>
                </li> */}
                <li>
                    <button
                        className="p-link layout-topbar-button"
                        onClick={(e) => {
                            toggleProfileMenu(e);
                            props.onMobileSubTopbarMenuClick(e);
                            showSidebar();
                        }}
                    >
                        <i className="pi pi-user" />
                        <span>Profile</span>
                    </button>
                    {/* <Menu ref={profileMenuRef} model={profileMenuItems} popup appendTo={document.body} onHide={hideProfileMenu} /> */}
                </li>
                {/* Add the logout button */}
                {/* Add the logout button */}
                {logoutMutationIsLoading ? (
                    <li>
                        <i className="pi pi-sign-out" />
                        <span>Logging out...</span>
                    </li>
                ) : (
                    <li>
                        <Tooltip title="Logout" arrow>
                            <button className="p-link layout-topbar-button" onClick={handleLogout}>
                                <i className="pi pi-power-off" />
                                <span>Logout</span>
                            </button>
                        </Tooltip>
                    </li>
                )}
            </ul>

            <Sidebar visible={isSidebarVisible} position="right" onHide={hideSidebar}>
                <h2>Profile</h2>
                <div className="user-details">
                    <p>
                        <strong>Name:</strong> {userDetails?.name}
                    </p>
                    <p>
                        <strong>Email:</strong> {userDetails?.email}
                    </p>
                    <p>
                        <strong>Role:</strong> {userDetails?.role}
                    </p>
                    {["Directorate Officer"].includes(userDetails?.role) && (
                        <>
                            <p>
                                <strong>Directorate:</strong> {userDetails?.user_directorate?.directorate?.name}
                            </p>
                            {/* <p>
                                <strong>Directorate Office:</strong> {userDetails?.user_directorate_office?.directorate_office?.name}
                            </p> */}
                        </>
                    )}

                    <p>
                        <strong>Registration Channel:</strong> {userDetails?.registration_channel}
                    </p>
                    <p>
                        <strong>Allow Notifications:</strong> {userDetails?.allow_notifications ? "Yes" : "No"}
                    </p>

                    <p>
                        <strong>Last Login:</strong> {userDetails?.lastlogin}
                    </p>

                    {["CSO Admin", "Group Reporter"].includes(userDetails?.role) && (
                        <>
                            <br />
                            <p>
                                <strong>Group Details:</strong>
                            </p>
                            {/* Check and display userDetails groups if the array exists and has elements */}
                            {Array.isArray(userDetails?.user_groups) && userDetails.user_groups.length > 0 ? (
                                userDetails.user_groups.map((userGroup, index) => (
                                    <p key={index}>
                                        <p key={index}>
                                            <strong>Name:</strong> {userGroup?.group?.name ?? "N/A"}
                                        </p>
                                        <p key={index}>
                                            <strong>Code:</strong> {userGroup?.group?.code ?? "N/A"}
                                        </p>
                                    </p>
                                ))
                            ) : (
                                <div className="p-col-12">
                                    <strong>No user groups available</strong>
                                </div>
                            )}

                            {/* Check and display user group types if the array exists and has elements */}
                            {Array.isArray(userDetails?.user_group_types) && userDetails.user_group_types.length > 0 ? (
                                userDetails.user_group_types.map((userGroupType, index) => (
                                    <p key={index}>
                                        <strong>Type:</strong> {userGroupType?.group_type?.name ?? "N/A"}
                                    </p>
                                ))
                            ) : (
                                <p className="p-col-12">
                                    <strong>No user group types available</strong>
                                </p>
                            )}
                        </>
                    )}
                    {/* You can add more details or format differently as needed */}
                </div>
                <div style={{ marginTop: "1rem" }}>
                    <Button onClick={() => handleShowUpdateUserForm()}>Update User Profile</Button>
                    <UpdateUserProfile show={showUpdateUserForm} onHide={handleCloseUpadateUserForm} onClose={handleCloseUpadateUserForm} loggedInUserData={userDetails} />
                </div>
            </Sidebar>

            <Dialog
                header="Confirm Logout"
                visible={dialogVisible}
                onHide={cancelLogout}
                maximizable
                footer={
                    <div style={{ padding: "0.5rem" }}>
                        <Button label="No" icon="pi pi-times" onClick={cancelLogout} className="p-button-text" />
                        <Button label="Yes" icon="pi pi-check" onClick={confirmLogout} autoFocus disabled={logoutMutationIsLoading} />
                    </div>
                }
            >
                <p>Are you sure you want to log out?</p>
                {logoutMutationIsLoading && (
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
                        <ProgressSpinner style={{ width: "30px", height: "30px" }} strokeWidth="4" />
                    </div>
                )}
            </Dialog>

            {/* Dialog */}
            <Dialog
                header="Hello! 👋 Are you still there?"
                visible={isIdle}
                maximizable
                modal
                closable={false}
                footer={
                    <div>
                        <Button label="Yes" icon="pi pi-check" onClick={handleStayLoggedIn} autoFocus />
                        <Button label="No" icon="pi pi-times" className="p-button-danger" onClick={handleIdleInactivityLogout} disabled={logoutMutationIsLoading} />
                    </div>
                }
            >
                <p>You will be logged out in {countdown} seconds if no action is taken.</p>
            </Dialog>
        </div>
    );
};
