import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { Form, Field } from "react-final-form";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { toast } from "react-toastify";
import moment from "moment";
import { Accordion, AccordionTab } from "primereact/accordion";

//
import { AutoComplete } from "primereact/autocomplete";
import { ProgressSpinner } from "primereact/progressspinner";

//
import { getAllGroupTypes, deleteGroupTypeById } from "../../../services/grouptypes/grouptypes-service";

import { getAllGroups, deleteGroupById } from "../../../services/groups/groups-service";

import useHandleQueryError from "../../../hooks/useHandleQueryError";

//
import useAuthContext from "../../../context/AuthContext";

//
import { getAllFinancialYears } from "../../../services/financial-years/financial-years-service";
import { getAllQuarters } from "../../../services/financial-years/quarters";

const AdvancedFilterForm = ({ onSubmit, initialData, selectedStatuses = [] }) => {
    const { getUserQuery } = useAuthContext();
    const loggedInUserData = getUserQuery?.data?.data;

    const [accordionActiveIndex, setAccordionActiveIndex] = useState(null);

    // ============= Auto Complete States =================
    const [dropdownGroupTypes, setDropdownGroupTypes] = useState([]);
    const [selectedGroupTypes, setSelectedGroupTypes] = useState(loggedInUserData?.role === "CSO Admin" ? loggedInUserData?.group_types ?? [] : initialData?.groupTypes ?? []);

    const [dropdownGroups, setDropdownGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState(loggedInUserData?.role === "CSO Admin" ? loggedInUserData?.groups ?? [] : initialData?.groups ?? []);
    // Fetch group types
    // Group Types Query
    const groupTypesQuery = useQuery({
        queryKey: ["groupTypes", loggedInUserData?.role],
        queryFn: () => getAllGroupTypes({ get_by_logged_in_user: ["CSO Admin"].includes(loggedInUserData?.role) ? true : false }),
    });
    useHandleQueryError(groupTypesQuery.isError, groupTypesQuery.error);

    // Groups Query (depends on selected group type)
    const groupsQuery = useQuery({
        queryKey: ["groups", selectedGroupTypes, loggedInUserData?.role],
        queryFn: () => getAllGroups({ get_by_logged_in_user: ["CSO Admin"].includes(loggedInUserData?.role) ? true : false, groupTypes: selectedGroupTypes }),
        enabled: selectedGroupTypes.length > 0, // Enabled only if group types are selected
    });
    useHandleQueryError(groupsQuery.isError, groupsQuery.error);

    // Financial Year and Quarter States
    const [dropdownFinancialYears, setDropdownFinancialYears] = useState([]);
    const [selectedFinancialYear, setSelectedFinancialYear] = useState(initialData?.financial_year);

    const [dropdownQuarters, setDropdownQuarters] = useState([]);
    const [selectedQuarter, setSelectedQuarter] = useState(initialData?.quarter);

    // Fetch Financial Years
    const financialYearsQuery = useQuery({
        queryKey: ["financialYears"],
        queryFn: getAllFinancialYears, // Replace with your actual API service
    });

    useHandleQueryError(financialYearsQuery?.isError, financialYearsQuery?.error);

    // Fetch Quarters (Dependent on Selected Financial Year)
    const quartersQuery = useQuery({
        queryKey: ["quarters"],
        queryFn: () => getAllQuarters(), // Replace with your actual API service
        enabled: selectedFinancialYear ? true : false, // Fetch only when a financial year is selected
    });

    useHandleQueryError(quartersQuery?.isError, quartersQuery?.error);

    const statuses = [
        { id: 1, name: "Submitted", code: "submitted" },
        { id: 2, name: "auditable", code: "auditable" },
        { id: 3, name: "tagged", code: "tagged" },
        { id: 4, name: "non audit", code: "non audit" },
    ];

    const validate = (values) => {
        const errors = {};
        if (values.startDate && values.endDate && moment(values.startDate).isAfter(values.endDate)) {
            errors.startDate = "Start date cannot be after end date";
        }
        return errors;
    };

    const onSubmitForm = (data, form) => {
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            onSubmit(data);
        } else {
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("Please correct the errors before submitting");
        }
    };

    const initialValues = {
        // startDate: initialData?.startDate ?? moment().startOf("month").toDate(),
        startDate: initialData?.startDate ?? moment().subtract(30, "days").toDate(),
        endDate: initialData?.endDate ?? moment().toDate(),
    };

    return (
        <div className="card p-fluid">
            <Accordion activeIndex={accordionActiveIndex} onTabChange={(e) => setAccordionActiveIndex(e.index)}>
                <AccordionTab header="Filters">
                    <Form
                        onSubmit={onSubmitForm}
                        initialValues={initialValues}
                        initialValuesEqual={() => true}
                        // initialValuesEqual with a function returning true prevents the form from
                        // reinitializing when the initialValues prop changes. This is useful when you
                        // want to avoid re-rendering or reinitializing the form due to changes in initial values.
                        // Be cautious using this if your initial values are meant to be dynamic and responsive
                        // to changes in your application's state.
                        validate={validate}
                        render={({ handleSubmit, form, values }) => (
                            <form onSubmit={handleSubmit} className="p-grid p-fluid">
                                <div className="grid">
                                    {/* Financial Year Field */}
                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Field name="financial_year">
                                            {({ input, meta }) => {
                                                const financialYearsData = financialYearsQuery?.data?.data?.data || [];

                                                const fetchFinancialYearSuggestions = (event) => {
                                                    let query = event.query.toLowerCase();
                                                    let filtered = financialYearsData.filter((year) => year?.name?.toLowerCase().includes(query));
                                                    setDropdownFinancialYears(filtered);
                                                };

                                                return (
                                                    <div className="p-field m-4">
                                                        <label htmlFor="financial_year">Financial Year</label>
                                                        <AutoComplete
                                                            {...input}
                                                            multiple={false}
                                                            suggestions={dropdownFinancialYears}
                                                            completeMethod={fetchFinancialYearSuggestions}
                                                            field="name"
                                                            value={selectedFinancialYear}
                                                            onChange={(e) => {
                                                                setSelectedFinancialYear(e.value);
                                                                input.onChange(e.value);

                                                                // Reset quarter when financial year changes
                                                                setSelectedQuarter(null);
                                                                setDropdownQuarters([]);
                                                            }}
                                                            dropdown={true}
                                                            disabled={financialYearsQuery?.isLoading}
                                                            placeholder="Select Financial Year"
                                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                        />
                                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                        {/* Show loading spinner if financialYearsQuery is loading */}
                                                        {financialYearsQuery?.isLoading && financialYearsQuery?.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                    </div>
                                                );
                                            }}
                                        </Field>
                                    </div>

                                    {/* Quarter Field */}
                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Field name="quarter">
                                            {({ input, meta }) => {
                                                const quartersData = quartersQuery?.data?.data?.data || [];

                                                const fetchQuarterSuggestions = (event) => {
                                                    let query = event.query.toLowerCase();
                                                    let filtered = quartersData.filter((quarter) => quarter?.name?.toLowerCase().includes(query));
                                                    setDropdownQuarters(filtered);
                                                };

                                                return (
                                                    <div className="p-field m-4">
                                                        <label htmlFor="quarter">Quarter</label>
                                                        <AutoComplete
                                                            {...input}
                                                            multiple={false}
                                                            suggestions={dropdownQuarters}
                                                            completeMethod={fetchQuarterSuggestions}
                                                            field="name"
                                                            value={selectedQuarter}
                                                            onChange={(e) => {
                                                                setSelectedQuarter(e.value);
                                                                input.onChange(e.value);

                                                                // Prefill logic
                                                                if (selectedFinancialYear && e.value) {
                                                                    const { start_month, end_month } = e.value; // Use end_month instead of number_of_months
                                                                    const { start_date, end_date } = selectedFinancialYear;

                                                                    // Ensure start_date and end_date are valid
                                                                    if (moment(start_date, "YYYY-MM-DD", true).isValid() && moment(end_date, "YYYY-MM-DD", true).isValid()) {
                                                                        const financialYearStart = moment(start_date, "YYYY-MM-DD");
                                                                        const financialYearEnd = moment(end_date, "YYYY-MM-DD");

                                                                        // Ensure start_month and end_month are valid
                                                                        if (start_month > 0 && start_month <= 12 && end_month > 0 && end_month <= 12) {
                                                                            // Calculate the number of months
                                                                            let number_of_months;
                                                                            if (end_month >= start_month) {
                                                                                number_of_months = end_month - start_month + 1;
                                                                            } else {
                                                                                // Handles cases where end_month is in the next calendar year
                                                                                number_of_months = 12 - start_month + 1 + end_month;
                                                                            }

                                                                            // Calculate the start date within the financial year
                                                                            let startDate = financialYearStart.clone().month(start_month - 1);

                                                                            // If the start month exceeds the calendar year, adjust the year
                                                                            if (startDate.isBefore(financialYearStart)) {
                                                                                startDate.add(1, "year");
                                                                            }

                                                                            // Calculate the end date
                                                                            let endDate = startDate.clone().add(number_of_months, "months").subtract(1, "day");

                                                                            // Ensure the calculated dates are within the financial year range
                                                                            if (startDate.isBefore(financialYearStart)) {
                                                                                startDate = financialYearStart.clone();
                                                                            }
                                                                            if (endDate.isAfter(financialYearEnd)) {
                                                                                endDate = financialYearEnd.clone();
                                                                            }

                                                                            // Update the form with calculated dates
                                                                            form.change("startDate", startDate.toDate());
                                                                            form.change("endDate", endDate.toDate());
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                            dropdown={true}
                                                            disabled={quartersQuery?.isLoading || !selectedFinancialYear}
                                                            placeholder="Select Quarter"
                                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                        />
                                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                        {quartersQuery?.isLoading && quartersQuery?.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                    </div>
                                                );
                                            }}
                                        </Field>
                                    </div>

                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Field name="startDate">
                                            {({ input, meta }) => (
                                                <div className="p-field m-4">
                                                    <label htmlFor="startDate">Start Date</label>
                                                    <Calendar
                                                        id="startDate"
                                                        {...input}
                                                        value={input.value ? moment.utc(input.value).toDate() : null}
                                                        dateFormat="dd/mm/yy"
                                                        showButtonBar
                                                        showIcon
                                                        // showTime
                                                        // hourFormat="12"
                                                        className={classNames({ "p-invalid": meta.error && meta.touched })}
                                                    />
                                                    {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                                </div>
                                            )}
                                        </Field>
                                    </div>

                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Field name="endDate">
                                            {({ input, meta }) => (
                                                <div className="p-field m-4">
                                                    <label htmlFor="endDate">End Date</label>
                                                    <Calendar
                                                        id="endDate"
                                                        {...input}
                                                        value={input.value ? moment.utc(input.value).toDate() : null}
                                                        dateFormat="dd/mm/yy"
                                                        showButtonBar
                                                        showIcon
                                                        // showTime
                                                        // hourFormat="12"
                                                        className={classNames({ "p-invalid": meta.error && meta.touched })}
                                                    />
                                                    {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                                </div>
                                            )}
                                        </Field>
                                    </div>

                                    <div className="col-12 md:col-6 lg:col-3">
                                        <div className="p-field m-4">
                                            <div style={{ height: "1rem" }}></div>
                                            <Button type="submit" onClick={() => setAccordionActiveIndex(null)} label="Apply Filters" className="p-button-primary" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    />
                </AccordionTab>
            </Accordion>
        </div>
    );
};

export default AdvancedFilterForm;
