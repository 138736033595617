import axiosAPI from "../axiosApi";

export async function getAllServicesFeedbackComments(params = {}) {
    const response = await axiosAPI.get("oag-service-feedback-comments", { params: params });
    return response;
}

export async function getServicesFeedbackCommentById(id) {
    const response = await axiosAPI.get(`oag-service-feedback-comments/` + id);
    return response;
}

export async function postServicesFeedbackComment(data) {
    const response = await axiosAPI.post(`oag-service-feedback-comments`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function updateServicesFeedbackComment(id, data) {
    const response = await axiosAPI.put(`oag-service-feedback-comments/${id}`, data);
    return response;
}

export async function deleteServicesFeedbackCommentById(id) {
    const response = await axiosAPI.delete(`oag-service-feedback-comments/${id}`);
    return response;
}
