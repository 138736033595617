import React, { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressBar } from "primereact/progressbar";
import { Tag } from "primereact/tag";
import moment from "moment";
import { Button } from "primereact/button";
import Tooltip from "@mui/material/Tooltip";
//
import * as XLSX from "xlsx";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";

//
import FeedbackDetailsModal from "../summarised_feedback/SummarisedFeedbackDetailsModal";

// Import your existing chart components
import FeedbackBySectorBarChart from "./charts/FeedbackBySectorBarChart";
import FeedbackByRegionPieChart from "./charts/FeedbackByRegionPieChart";
import FeedbackByLocationScopeDoughnutChart from "./charts/FeedbackByLocationScopeDoughnutChart";
import InlineExpandableText from "../../../components/helpers/InlineExpandableText";

//
import FeedbackByRegionTable from "./tables/primereact/FeedbackByRegionTable";
import FeedbackBySectorTable from "./tables/primereact/FeedbackBySectorTable";
import FeedbackByLocationScopeTable from "./tables/primereact/FeedbackByLocationScopeTable";

import PrintableFeedbackReportTemplate from "./PrintableFeedbackReportTemplate";

//
//
import jsPDF from "jspdf";
import { isMobile } from "react-device-detect";
import { useReactToPrint } from "react-to-print";

import useAuthContext from "../../../context/AuthContext";

//
import { getFeedbackReportById } from "../../../services/feedback-reports/feedback-reports-service.js";
//
import useHandleQueryError from "../../../hooks/useHandleQueryError";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

//
//lotties
import MaterialUiLoaderLottie from "../../../assets/lotties/oag-lotties/material-ui-loading-lottie.json";
import SnailErrorLottie from "../../../assets/lotties/oag-lotties/snail-error-lottie.json";
import SateLiteLottie from "../../../assets/lotties/oag-lotties/satelite-loading-lottie.json";
import FileLoadingLottie from "../../../assets/lotties/oag-lotties/FileLoadingLottie.json";
import SkeletonLoadingLottie from "../../../assets/lotties/oag-lotties/SkeletonLoadingLottie.json";
import Lottie from "lottie-react";

//
import NotificationsForm from "./NotificationsForm";

const FeedbackReportDetailsModal = ({ showProposingColumns, showAgreedColumns, ...props }) => {
    const pdfReportRef = useRef();
    const [printConfig, setPrintConfig] = useState();

    const handleExportToExcel = (summarisedFeedback, feedbackReportData) => {
        // Determine the title based on the report type
        const title =
            feedbackReportData?.type === "urgent report"
                ? `Urgent Report (${feedbackReportData?.start_date} to ${feedbackReportData?.end_date})`
                : feedbackReportData?.type === "quarterly report"
                ? `Quarterly Report (FY: ${feedbackReportData?.financial_year?.name}, ${feedbackReportData?.quarter?.name})`
                : "Feedback Report";

        // Prepare the data for export
        const formattedData = summarisedFeedback.map((item) => ({
            ID: item?.id,
            FeedbackNo: item?.feedback_no,
            IsAnonymous: item?.is_anonymous ? "Yes" : "No",
            Sector: item?.sector?.name,
            Message: item?.message,
            HasAttachments: item?.has_attachments ? "Yes" : "No",
            CurrentStatus: item?.spatie_current_status?.name,
            CurrentStatusReason: item?.spatie_current_status?.reason,
            LocationScope: item?.location_scope,
            Country: item?.country?.name,
            Latitude: item?.latitude,
            Longitude: item?.longitude,
            Region: item?.region?.name,
            District: item?.district?.name,
            County: item?.county?.name,
            SubCounty: item?.subcounty?.name,
            Parish: item?.parish?.name,
            Village: item?.village?.name,
            Status: item?.status,
            SubmitStatus: item?.feedback_submit_status,
            CreatedByName: item?.created_by?.name,
            CreatedByEmail: item?.created_by?.email,
            UpdatedByName: item?.updated_by?.name,
            CreatedAt: item?.created_at ? moment(item?.created_at).format("YYYY-MM-DD HH:mm:ss") : "",
            UpdatedAt: item?.updated_at ? moment(item?.updated_at).format("YYYY-MM-DD HH:mm:ss") : "",
            // Always included fields for export
            AuditAreas: Array.isArray(item?.audit_areas) && item?.audit_areas.length > 0 ? item.audit_areas.map((area) => area?.name).join(", ") : "N/A",
            // Join multiple areas as a comma-separated list

            // Proposed columns (always included in the export)
            ProposedActions: item?.proposed_actions || "N/A",
            ProposedResponsibleDirectorate: item?.proposed_directorate?.name || "N/A",

            // Agreed columns (always included in the export)
            AgreedActions: item?.agreed_actions || "N/A",
            AgreedResponsibleDirectorate: item?.responsible_directorate?.name || "N/A",
        }));

        // Create a worksheet
        const worksheet = XLSX.utils.json_to_sheet(formattedData);

        // Create a workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Summarised Feedback");

        // Set the file name dynamically
        const fileName = `${title.replace(/[:/\\*?"<>|]/g, "")}.xlsx`;

        // Export to Excel file
        XLSX.writeFile(workbook, fileName);
    };

    const printDocument = useReactToPrint({
        contentRef: pdfReportRef,
        documentTitle: printConfig?.title || "Citizen Feedback Plaform Report", // Fall back to "Default Document Title" if title is undefined
        // pageStyle: `
        //     @page { size: A4 ${printConfig?.orientation}; margin: 1cm; }
        //     @media print {
        //       body { -webkit-print-color-adjust: exact; }
        //       div#print-content { height: 100%; }
        //       .header, .footer { background-color: transparent !important; }
        //       img { display: block; margin: 0 auto; }
        //     }
        //   `,
        pageStyle: `
        @page {
            size: A4 ${printConfig?.orientation || "portrait"};
            margin: 1cm;
        }
        @media print {
            body {
                -webkit-print-color-adjust: exact;
                margin: 0;
            }
        
            /* Reserved Spaces */
            .header-space,
            .footer-space {
                height: 100px;
            }
        
            /* Fixed Header */
            .header {
                position: fixed;
                top: 0;
                width: 100%;
                background-color: white !important;
                text-align: center;
                z-index: 10;
                border-bottom: 1px solid #ddd;
            }
        
            /* Fixed Footer */
            .footer {
                position: fixed;
                bottom: 0;
                width: 100%;
                background-color: white !important;
                text-align: center;
                font-size: 10px;
                color: #666;
                z-index: 10;
                border-top: 1px solid #ddd;
            }
        
            /* Content Space Adjustments */
            .content {
                margin-top: -50px; /* Match header-space height */
                margin-bottom: 100px; /* Match footer-space height */
            }
        }
        `,
        onAfterPrint: () => {
            setPrintConfig(null); // Reset printConfig to null after printing
        },
        onBeforeGetContent: () => {
            if (!window.matchMedia("print").matches) {
                // Fallback: Save as PDF if the print dialog cannot be triggered
                saveAsPDF();
            }
        },
        onPrintError: (error) => {
            console.error("Print Error:", error);
            saveAsPDF();
            setPrintConfig(null); // Reset printConfig on error
        },
    });

    const saveAsPDF = async () => {
        const content = pdfReportRef?.current; // Ensure pdfReportRef is a useRef pointing to the HTML content
        console.log("🚀 ~ saveAsPDF ~ content:", content);

        if (content) {
            // Set the orientation dynamically based on printConfig
            const orientation = printConfig?.orientation || "portrait"; // Default to "portrait" if not defined

            // Define PDF options
            const pdfOptions = {
                orientation,
                filename: printConfig?.title || "Citizen Feedback Plaform Report.pdf", // File name for the PDF
                unit: "px", // Use points to control dimensions better
                format: "a4",
            };

            // Adding the fonts.

            // Create a new jsPDF instance with the specified orientation and options
            const pdf = new jsPDF(pdfOptions);

            // pdf.setFont("Inter-Regular", "normal");
            pdf.setFontSize(12);

            const pdfWidth = pdf.internal.pageSize.getWidth();

            try {
                // Render HTML content directly into the PDF
                await pdf.html(content, {
                    autoPaging: "text",
                    width: pdfWidth,
                    windowWidth: pdfWidth,
                    margin: [10, 10, 10, 10],

                    callback: async (pdf) => {
                        await pdf.save(pdfOptions.filename); // Save the PDF after rendering is complete
                        setPrintConfig(null);
                    },
                });
            } catch (error) {
                setPrintConfig(null);
                console.error("Error generating PDF:", error);
            }
        }
    };

    // Handle Print
    const handlePrint = () => {
        printDocument();
    };

    //============================ handle feedback report data  ===================================================
    const { feedbackReportData } = props;

    const { getUserQuery } = useAuthContext();
    const activeUser = getUserQuery?.data?.data;

    const getFeedbackReportByIdQuery = useQuery({
        queryKey: ["feedback-reports", "by_id", feedbackReportData?.id],
        queryFn: () => getFeedbackReportById(feedbackReportData?.id),
    });

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(getFeedbackReportByIdQuery?.isError, getFeedbackReportByIdQuery?.error);

    const feedbackReportDetailData = getFeedbackReportByIdQuery?.data?.data;

    console.log("🚀 ~ FeedbackReportDetailsModal ~ feedbackReportDetailData:", feedbackReportDetailData);
    const summarisedFeedback = (feedbackReportDetailData?.feedback_report_summarised_feedback || []).map((feedbackReportDetailData) => feedbackReportDetailData?.summarised_feedback);
    console.log("🚀 ~ FeedbackReportDetailsModal ~ summarisedFeedback:", summarisedFeedback);

    // Statistics Calculations
    // Calculate total feedback entries
    const totalFeedback = summarisedFeedback.length;

    // Calculate unique regions by accessing the nested "region.name" key
    const uniqueRegions = new Set(
        summarisedFeedback
            .map((row) => row.region?.name) // Access "region.name" safely using optional chaining
            .filter((name) => name) // Filter out undefined or null values
    ).size;

    // Calculate unique sectors by accessing the nested "sector.name" key
    const uniqueSectors = new Set(
        summarisedFeedback
            .map((row) => row.sector?.name) // Access "sector.name" safely using optional chaining
            .filter((name) => name) // Filter out undefined or null values
    ).size;

    const styles = {
        statsContainer: {
            marginTop: "10px",
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
            fontSize: "14px",
            fontWeight: "bold",
            borderBottom: "0.2px solid #ddd",
            padding: "10px",
            borderRadius: "5px",
        },
        chartsContainer: {
            marginTop: "20px",
        },
        tablesContainer: {
            marginTop: "20px",
        },
        tableWrapper: {
            marginBottom: "20px",
        },
    };

    const getFeedbackReportSeverityColor = (status) => {
        switch (status) {
            case "submitted":
                return "info"; // Blue
            case "Corporate Management":
                return "primary"; // Dark Blue or any strong color to represent high-level management
            case "AAGC":
                return "warning"; // Yellow/Orange (indicating it's under AAGC review)
            case "Accepted by AAGC":
                return "success"; // Green (indicating approval or completion by AAGC)
            case "Rejected by AAGC":
                return "danger"; // Red (indicating rejection or failure by AAGC)
            case "Directorate Officer":
                return "info"; // Blue (indicating it's being handled by a Directorate Officer)
            case "in progress":
                return "warning"; // Yellow/Orange (indicating it's still in progress)
            case "done":
                return "success"; // Green (indicating completion)
            case "failed":
                return "danger"; // Red (indicating failure)
            case "not satisfied":
                return "danger"; // Red (indicating the task is completed but with an unsatisfactory outcome)
            case "completed":
                return "success"; // Green (indicating completion)
            default:
                return "secondary"; // Gray or any other default color for unknown statuses
        }
    };

    // Action buttons for the DataTable

    const [selectedFeedback, setSelectedFeedback] = useState(null);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);

    const viewFeedback = (rowData) => {
        setSelectedFeedback(rowData);
        setShowFeedbackModal(true);
    };

    const handleCloseFeedbackModal = () => {
        setShowFeedbackModal(false);
        setSelectedFeedback(null);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <Button
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-info p-button-text mr-2"
                    onClick={(e) => {
                        e.preventDefault();
                        viewFeedback(rowData);
                    }}
                />
            </>
        );
    };

    const messageBodyTemplate = (rowData) => {
        const message = rowData?.message;
        const words = message.split(" ");
        const truncatedMessage = words.length > 2 ? words.slice(0, 2).join(" ") + "..." : message;

        return (
            <div style={{ maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                <Tooltip title={message} arrow placement="top">
                    <span>{truncatedMessage}</span>
                </Tooltip>
            </div>
        );
    };

    // Helper function to determine progress bar color
    const getProgressBarColor = (completion) => {
        if (completion <= 33) {
            return "red"; // Low completion
        } else if (completion <= 66) {
            return "orange"; // Medium completion
        }
        return "green"; // High completion
    };

    // Pagination Helper Function
    const getDynamicPaginationOptions = (data = []) => {
        const dataLength = data.length;
        if (dataLength <= 6) {
            return [2, 3, 6];
        }

        const portionSize = Math.ceil(dataLength / 3);
        const halfPortionSize = Math.ceil(portionSize / 2);
        const doublePortionSize = portionSize * 2;

        return [halfPortionSize, portionSize, doublePortionSize];
    };

    //================================= sent notifications through report =================

    const [showNotificationsForm, setShowNotificationsForm] = useState(false);

    const handleShowNotificationsForm = () => {
        setShowNotificationsForm(true);
    };

    const handleCloseNotificationsForm = () => {
        // setTableSelectedRows([]);
        setShowNotificationsForm(false);
    };

    const capitalizeFirstLetter = (str) => {
        if (!str) return "N/A";
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    return (
        <Dialog visible={props.show} onHide={props.onHide} maximizable style={{ minWidth: "80vw" }}>
            {getFeedbackReportByIdQuery?.isLoading ? (
                <div className="col-12">
                    {/* <ProgressBar mode="indeterminate" style={{ height: "6px" }} /> */}
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ maxWidth: "100%" }}>
                            <Lottie animationData={SkeletonLoadingLottie} loop={true} style={{ height: "300px" }} autoplay={true} />
                            <Lottie animationData={MaterialUiLoaderLottie} style={{ height: "50px" }} loop={true} autoplay={true} />
                        </div>
                    </div>
                </div>
            ) : getFeedbackReportByIdQuery?.isError ? (
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ maxWidth: "400px" }}>
                        <Lottie animationData={SnailErrorLottie} loop={true} autoplay={true} />
                    </div>
                </div>
            ) : (
                <>
                    <div>
                        <div
                            className="header"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                width: "100%",
                            }}
                        >
                            <img src="assets/oag_photos/uganda_oag.png" alt="Logo" style={{ height: "50px" }} />
                            <h6>Feedback Report</h6>
                        </div>
                        {/* Report Details */}
                        <div className="grid" style={{ padding: "10px" }}>
                            {/* col 1 */}
                            <div className="col-12 md:col-6 lg:col-4" style={{ padding: "5px" }}>
                                <div className="p-1">
                                    <strong>Title:</strong> {feedbackReportDetailData?.name || "N/A"}
                                </div>
                                <div className="p-1">
                                    <strong>Type:</strong> {capitalizeFirstLetter(feedbackReportDetailData?.type)}
                                </div>

                                {feedbackReportDetailData?.type === "quarterly report" && (
                                    <>
                                        <div className="p-1">
                                            <strong>Financial Year:</strong>
                                            <Tag value={feedbackReportDetailData?.financial_year?.name || "N/A"} severity="primary" />
                                        </div>
                                        <div className="p-1">
                                            <strong>Quarter:</strong>
                                            <Tag value={feedbackReportDetailData?.quarter?.name || "N/A"} severity="warning" />
                                        </div>
                                    </>
                                )}

                                <div className="p-1">
                                    <strong>Start Date:</strong>
                                    {feedbackReportDetailData?.start_date ? moment(feedbackReportDetailData.start_date).format("dddd, MMMM D, YYYY") : "N/A"}
                                </div>

                                <div className="p-1">
                                    <strong>End Date:</strong>
                                    {feedbackReportDetailData?.end_date ? moment(feedbackReportDetailData.end_date).format("dddd, MMMM D, YYYY") : "N/A"}
                                </div>
                            </div>

                            {/* Description */}
                            <div className="col-12 md:col-6 lg:col-4" style={{ padding: "5px" }}>
                                <div className="p-1">
                                    <strong>
                                        Completion: <small>{feedbackReportDetailData?.completion ?? 0}%</small>
                                    </strong>
                                    <div>
                                        <ProgressBar value={feedbackReportDetailData?.completion || 0} color={getProgressBarColor(feedbackReportDetailData?.completion)} showValue={true} style={{ height: "16px", width: "100px" }} />
                                    </div>
                                </div>
                                <div className="p-1">
                                    <strong>Description:</strong>
                                    {feedbackReportDetailData?.description || "No description"}
                                </div>

                                <div className="p-1">
                                    <strong>Current Status: </strong>
                                    <Tag
                                        value={feedbackReportDetailData?.spatie_current_status?.name ? feedbackReportDetailData?.spatie_current_status?.name : "N/A"}
                                        severity={getFeedbackReportSeverityColor(feedbackReportDetailData?.spatie_current_status?.name)} // 'info' for blue, 'success' for green
                                    />
                                </div>

                                <div className="p-1">
                                    <strong>Reason: </strong>
                                    {feedbackReportDetailData?.spatie_current_status?.reason || "N/A"}
                                </div>
                            </div>

                            {/* col 2 */}
                            <div className="col-12 md:col-6 lg:col-4" style={{ padding: "5px" }}>
                                <div className="p-1">
                                    <strong>Created By:</strong>
                                    {feedbackReportDetailData?.created_by?.name || "N/A"}
                                </div>

                                <div className="p-1">
                                    <strong>Created By Email:</strong>
                                    {feedbackReportDetailData?.created_by?.email || "N/A"}
                                </div>

                                <div className="p-1">
                                    <strong>Updated By:</strong>
                                    {feedbackReportDetailData?.updated_by?.name || "N/A"}
                                </div>

                                <div className="p-1">
                                    <strong>Created At:</strong>
                                    {feedbackReportDetailData?.created_at ? moment(feedbackReportDetailData.created_at).format("lll") : "N/A"}
                                </div>

                                {/* <div>
                            <strong>Updated At:</strong>
                            {feedbackReportDetailData?.updated_at ? moment(feedbackReportDetailData.updated_at).format("lll") : "N/A"}
                        </div> */}
                            </div>
                        </div>

                        {/* Statistics Section */}
                        <div style={styles.statsContainer}>
                            <div>Total Feedback: {totalFeedback}</div>
                            <div>Number of Regions: {uniqueRegions}</div>
                            <div>Number of Sectors: {uniqueSectors}</div>
                        </div>

                        {/* Charts Section */}
                        <div
                            className="grid"
                            style={{
                                padding: "10px",
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <div className="col-12 md:col-6 lg:col-3" style={{ padding: "5px" }}>
                                <FeedbackByLocationScopeDoughnutChart data={summarisedFeedback} />
                            </div>
                            <div className="col-12 md:col-6 lg:col-3" style={{ padding: "5px" }}>
                                <FeedbackByRegionPieChart data={summarisedFeedback} />
                            </div>
                            <div className="col-12 md:col-6 lg:col-3" style={{ padding: "5px" }}>
                                <FeedbackBySectorBarChart data={summarisedFeedback} />
                            </div>
                        </div>

                        {/* Tables Section */}
                        <div
                            className="grid"
                            style={{
                                padding: "10px",
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <div className="col-12 md:col-6 lg:col-3" style={{ padding: "5px" }}>
                                <FeedbackByLocationScopeTable data={summarisedFeedback} />
                            </div>
                            <div className="col-12 md:col-6 lg:col-3" style={{ padding: "5px" }}>
                                <FeedbackByRegionTable data={summarisedFeedback} />
                            </div>
                            <div className="col-12 md:col-6 lg:col-3" style={{ padding: "5px" }}>
                                <FeedbackBySectorTable data={summarisedFeedback} />
                            </div>
                        </div>

                        {/* DataTable Section */}

                        {activeUser?.permissions.includes("notify users through report") && summarisedFeedback.length > 0 && (
                            <div style={{ height: "3rem", margin: "0.4rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                                <Button
                                    label="Notify"
                                    className="p-button-primary"
                                    onClick={() => {
                                        handleShowNotificationsForm();
                                    }}
                                />
                            </div>
                        )}
                        {showNotificationsForm && <NotificationsForm summarisedFeedback={summarisedFeedback} show={showNotificationsForm} onHide={handleCloseNotificationsForm} onClose={handleCloseNotificationsForm} />}

                        <small>Tagged Summarised Feedback</small>
                        <DataTable value={summarisedFeedback} responsiveLayout="scroll" paginator rows={10} rowsPerPageOptions={[10, 20, 50]} showGridlines stripedRows>
                            {/* Always visible columns */}
                            <Column field="feedback_no" header="No." />
                            <Column field="created_at" header="Date Submitted" body={(rowData) => moment(rowData?.created_at).format("MMMM Do YYYY, h:mm:ss a")} />
                            <Column field="message" header="Issue as Recorded in CFP" />
                            <Column field="location_scope" header="Scope" />
                            <Column field="district.name" header="District" />
                            <Column field="sector.name" header="Sector" />
                            <Column field="has_attachments" header="Attachment Y/N" body={(rowData) => (rowData?.has_attachments ? "Yes" : "No")} />
                            <Column
                                field="audit_areas"
                                header="Identified Audit Areas"
                                body={(rowData) => {
                                    console.log("dsgsdfgsegRow Data:", rowData);
                                    const auditAreas = Array.isArray(rowData?.audit_areas) ? rowData.audit_areas : [];
                                    return auditAreas.length > 0 ? auditAreas.map((area, index) => <div key={index}>{area?.name}.</div>) : "N/A";
                                }}
                            />

                            {/* Conditional columns */}
                            {showProposingColumns && <Column field="proposed_actions" header="Proposed Actions" body={(rowData) => rowData?.proposed_actions || "N/A"} />}
                            {showProposingColumns && <Column field="proposed_directorate.name" header="Proposed Responsible Directorate" body={(rowData) => rowData?.proposed_directorate?.name || "N/A"} />}
                            {showAgreedColumns && <Column field="agreed_actions" header="Agreed Actions" body={(rowData) => rowData?.agreed_actions || "N/A"} />}
                            {showAgreedColumns && <Column field="responsible_directorate.name" header="Agreed Responsible Directorate" body={(rowData) => rowData?.responsible_directorate?.name || "N/A"} />}

                            {/* Action column */}
                            <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: "8rem" }} />
                        </DataTable>
                    </div>

                    <div style={{ textAlign: "right", marginBottom: "10px" }}>
                        <Button variant="contained" color="primary" startIcon={<PrintIcon />} onClick={handlePrint} style={{ marginRight: "10px" }}>
                            Print
                        </Button>
                        <Button variant="contained" color="success" startIcon={<DownloadIcon />} onClick={() => handleExportToExcel(summarisedFeedback, feedbackReportDetailData)}>
                            Export to Excel
                        </Button>
                    </div>

                    {showFeedbackModal && <FeedbackDetailsModal feedbackDetailData={selectedFeedback} show={showFeedbackModal} onHide={handleCloseFeedbackModal} onClose={handleCloseFeedbackModal} />}

                    <div style={{ display: "none" }}>
                        <div ref={pdfReportRef}>{<PrintableFeedbackReportTemplate showAgreedColumns={showAgreedColumns} showProposingColumns={showProposingColumns} feedbackReportData={feedbackReportDetailData} loggedInUserDetails={getUserQuery?.data?.data} />}</div>
                    </div>
                </>
            )}
        </Dialog>
    );
};

export default FeedbackReportDetailsModal;
