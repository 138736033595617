import React, { useState } from "react";
import ListPage from "./ListPage";
import AuditableFeedbackListPage from "./auditable-feedback/ListPage";
import NonAuditableFeedbackListPage from "./non-audit-feedback/ListPage";
import SummarisedFeedbackListPage from "./summarised-feedback/ListPage";

//

import SummarisedFeedbackStatusOverviewListPage from "./summarised-feedback-status-overview/ListPage";
import FeedbackOverviewListPage from "./feedback-status-overview/ListPage";

import BreadcrumbNav from "../../components/general_components/BreadcrumbNav";
import useAuthContext from "../../context/AuthContext";
import { TabView, TabPanel } from "primereact/tabview";

import FeedbackReportsListPage from "../feedback-reports/ListPage";

//
function FeedbacksPage({ loggedInUserData }) {
    const { getUserQuery } = useAuthContext();
    // State to manage the active tab
    let tabIndex = 0;

    if (getUserQuery?.data?.data?.role === "Directorate Officer") {
        tabIndex = 3;
    }

    if (getUserQuery?.data?.data?.role === "CSO Admin") {
        tabIndex = 8;
    }
    const [activeTabIndex, setActiveTabIndex] = useState(tabIndex);

    console.log("🚀 ~ FeedbacksPage ~ getUserQuery:", getUserQuery);
    return (
        <div>
            <BreadcrumbNav />

            <TabView scrollable={true} activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
                {["Feedback Coordinator", "OAG Admin"].includes(getUserQuery?.data?.data?.role) && (
                    <TabPanel header="New Feedback">
                        <ListPage loggedInUserData={getUserQuery?.data?.data} />
                    </TabPanel>
                )}
                {["Feedback Coordinator", "OAG Admin"].includes(getUserQuery?.data?.data?.role) && (
                    <TabPanel header="Accepted For Audit">
                        <AuditableFeedbackListPage loggedInUserData={getUserQuery?.data?.data} />
                    </TabPanel>
                )}
                {["Feedback Coordinator", "OAG Admin"].includes(getUserQuery?.data?.data?.role) && (
                    <TabPanel header="Summarized Feedback">
                        <SummarisedFeedbackListPage spatieCurrentStatuses={["draft", "submitted", "new"]} loggedInUserData={getUserQuery?.data?.data} />
                    </TabPanel>
                )}

                {["Directorate Officer", "OAG Admin"].includes(getUserQuery?.data?.data?.role) && (
                    <TabPanel header="Not Started">
                        <SummarisedFeedbackListPage level="Directorate Officer" spatieCurrentStatuses={["Not Started"]} loggedInUserData={getUserQuery?.data?.data} />
                    </TabPanel>
                )}

                {["Directorate Officer", "OAG Admin"].includes(getUserQuery?.data?.data?.role) && (
                    <TabPanel header="In Progress">
                        <SummarisedFeedbackListPage level="Directorate Officer" spatieCurrentStatuses={["In Progress"]} loggedInUserData={getUserQuery?.data?.data} />
                    </TabPanel>
                )}

                {["Directorate Officer", "OAG Admin"].includes(getUserQuery?.data?.data?.role) && (
                    <TabPanel header="Completed">
                        <SummarisedFeedbackListPage level="Directorate Officer" spatieCurrentStatuses={["Completed"]} loggedInUserData={getUserQuery?.data?.data} />
                    </TabPanel>
                )}

                {["Directorate Officer", "OAG Admin"].includes(getUserQuery?.data?.data?.role) && (
                    <TabPanel header="Not Applicable">
                        <SummarisedFeedbackListPage level="Directorate Officer" spatieCurrentStatuses={["Not Applicable"]} loggedInUserData={getUserQuery?.data?.data} />
                    </TabPanel>
                )}

                {["Feedback Coordinator", "OAG Admin"].includes(getUserQuery?.data?.data?.role) && (
                    <TabPanel header="Non Audit Feedback">
                        <NonAuditableFeedbackListPage loggedInUserData={getUserQuery?.data?.data} />
                    </TabPanel>
                )}

                {/* {["Feedback Coordinator", "Corporate Management", "AAGC", "AAGA", "Directorate Officer", "OAG Admin", "AG"].includes(getUserQuery?.data?.data?.role) && (
                    <TabPanel header="Summarised Feedback Overview">
                        <SummarisedFeedbackStatusOverviewListPage loggedInUserData={getUserQuery?.data?.data} />
                    </TabPanel>
                )} */}
                {["Feedback Coordinator", "Corporate Management", "AAGC", "AAGA", "Directorate Officer", "OAG Admin", "AG", "CSO Admin"].includes(getUserQuery?.data?.data?.role) && (
                    <TabPanel header="Feedback Overview">
                        <FeedbackOverviewListPage loggedInUserData={getUserQuery?.data?.data} />
                    </TabPanel>
                )}
            </TabView>
        </div>
    );
}

export default FeedbacksPage;
