import React, { useState, useEffect } from "react";

import { Dialog } from "primereact/dialog";

import UpdateSumFbStatusMutationRow from "./mutations/UpdateSumFbStatusMutationDiaglog";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

function TakeActionForm({ ...props }) {
    return (
        <Dialog header="Take Action" visible={props.show} maximizable style={{ minWidth: "50vw" }} onHide={() => props.onHide()}>
            <p>Choose report fate</p>
            <UpdateSumFbStatusMutationRow {...props} />
        </Dialog>
    );
}

export default TakeActionForm;
