import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import ListPage from "./ListPage";
import BreadcrumbNav from "../../components/general_components/BreadcrumbNav";

import useAuthContext from "../../context/AuthContext";

//
import EditForm from "./EditForm";
// import MembersListPage from "./members/List";
import MembersListPage from "../users/UserList";
import FeedbackListPage from "./feedback-status-overview/ListPage";
import { Button } from "primereact/button";

//
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getGroupById } from "../../services/groups/groups-service";
import useHandleQueryError from "../../hooks/useHandleQueryError";
import { ProgressBar } from "primereact/progressbar";

const GroupsViewPage = () => {
    const { getUserQuery } = useAuthContext();
    const location = useLocation();
    const { groupData } = location.state;
    const [activeIndex, setActiveIndex] = useState(0);

    const queryClient = useQueryClient();
    const { data, isLoading, isError, error, status } = useQuery({
        queryKey: ["groups", "groups_by_id", groupData?.id],
        queryFn: () => getGroupById(groupData?.id),
    });

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(isError, error);

    const activeUser = getUserQuery?.data?.data;

    const [showEditForm, setShowEditForm] = useState(false);

    const handleShowEditForm = () => {
        setShowEditForm(true);
    };
    const handleCloseEditForm = () => {
        setShowEditForm(false);
    };
    const groupDetails = data?.data?.data;

    return (
        <div className="group">
            <BreadcrumbNav />
            {/* Conditionally show the loading bar */}
            {isLoading && <ProgressBar mode="indeterminate" style={{ height: "6px", marginBottom: "1rem" }} />}
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="Group Details">
                    <div style={{ margin: "0.5rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                        {activeUser?.permissions.includes("update group") && groupDetails && <Button label="Edit Group" className="p-button-primary" onClick={() => handleShowEditForm()} />}
                        {groupDetails && <EditForm rowData={groupDetails} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />}
                    </div>
                    <div className="card" title="Group">
                        <p>
                            <strong>Name:</strong> {groupDetails?.name}
                        </p>
                        <p>
                            <strong>Code:</strong> {groupDetails?.code}
                        </p>
                        <p>
                            <strong>Group Type:</strong> {groupDetails?.group_type?.name}
                        </p>

                        <p>
                            <strong>Created At:</strong> {moment(groupDetails?.created_at).format("YYYY-MM-DD HH:mm:ss")}
                        </p>
                        {/* Add more details as needed */}
                    </div>
                </TabPanel>
                <TabPanel header="Members">
                    <MembersListPage groupDetails={groupDetails} loggedInUserData={getUserQuery?.data?.data} />
                </TabPanel>

                <TabPanel header="Feedback">
                    <FeedbackListPage groupDetails={groupDetails} loggedInUserData={getUserQuery?.data?.data} />
                </TabPanel>
            </TabView>
        </div>
    );
};

export default GroupsViewPage;
