import axiosAPI from "../axiosApi";

export async function getAllTopics(params = {}) {
    const response = await axiosAPI.get("topics", { params: params });
    return response;
}

export async function getTopicById(id) {
    const response = await axiosAPI.get(`topics/` + id);
    return response;
}

export async function postTopic(data) {
    const response = await axiosAPI.post(`topics`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function updateTopic(id, data) {
    const response = await axiosAPI.post(`topics/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function deleteTopicById(id) {
    const response = await axiosAPI.delete(`topics/${id}`);
    return response;
}
