import React, { useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const FeedbackByRegionTable = ({ data = [] }) => {
    // Aggregate feedback by region
    const feedbackByRegion = useMemo(() => {
        const aggregation = {};
        data.forEach((row) => {
            const regionName = row?.region?.name; // Access region name from the nested structure
            if (regionName) {
                if (!aggregation[regionName]) {
                    aggregation[regionName] = 1;
                } else {
                    aggregation[regionName]++;
                }
            }
        });
        return Object.entries(aggregation).map(([name, count]) => ({
            name,
            count,
        }));
    }, [data]);

    return (
        <div>
            <small style={{ marginBottom: "5px" }}>Feedback By Region</small>
            <DataTable value={feedbackByRegion} showGridlines paginator rows={10} style={{ width: "100%" }}>
                <Column field="name" header="Region" sortable />
                <Column field="count" header="Feedback Count" sortable />
            </DataTable>
        </div>
    );
};

export default FeedbackByRegionTable;
