import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

//
import DirectorateRow from "./widgets/DirectorateRow";
import useAuthContext from "../../../context/AuthContext.jsx";

//
import { postToUpdateSumFBResDirectorate } from "../../../services/feedback/summarised-feedback-service";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

import moment from "moment";

//
import useHandleMutationError from "../../../hooks/useHandleMutationError.js";

function ResponsibleDirectorate({ feedbackDetailData, type, ...props }) {
    const { getUserQuery } = useAuthContext();
    const activeUser = getUserQuery?.data?.data;

    const queryClient = useQueryClient();

    const [creactMutationIsLoading, setCreactMutationIsLoading] = useState(false);
    const creactMutation = useMutation({
        mutationFn: postToUpdateSumFBResDirectorate,
        onSuccess: () => {
            queryClient.invalidateQueries(["summarised_feedback", "by_id", feedbackDetailData?.id]);
            // queryClient.invalidateQueries(["summarised_feedback"]);
            queryClient.invalidateQueries(["feedback-reports"]);
            toast.success("Updated Successfully");
            setCreactMutationIsLoading(false);
            props.onClose();
        },
    });
    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(creactMutation?.error, setCreactMutationIsLoading);

    const handleSubmit = async (data) => {
        setCreactMutationIsLoading(true);

        creactMutation.mutate({ directorate_id: data?.directorate?.id, type, summarised_feedback_id: feedbackDetailData?.id });
    };

    return (
        <Dialog header="Directorate" visible={props.show} maximizable style={{ minWidth: "50vw" }} onHide={() => props.onClose()}>
            <p>Fill in the form below</p>
            <DirectorateRow onSubmit={handleSubmit} type={type} initialData={{ directorate: type === "proposed" ? feedbackDetailData?.proposed_directorate : feedbackDetailData?.responsible_directorate }} />
            {/* <h4>{creactProgramsMutation.status}</h4> */}
            {creactMutationIsLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
        </Dialog>
    );
}

export default ResponsibleDirectorate;
