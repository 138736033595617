import React, { useEffect, useState, useRef } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import CreateForm from "./CreateForm";

import EditForm from "./EditForm";
import TakeActionForm from "./TakeActionForm";
import FeedbackReportDetailsModal from "../global_views/feedback_report/FeedbackReportDetailsModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";

import { useNavigate } from "react-router-dom";

import { getAllFeedbackReports, getFeedbackReportById, postFeedbackReport, updateFeedbackReport, deleteFeedbackReportById, postToBulkDestroyFeedbackReports } from "../../services/feedback-reports/feedback-reports-service.js";

import { Dropdown } from "primereact/dropdown";
import MuiTable from "../../components/general_components/MuiTable";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import { Panel } from "primereact/panel";
import Tooltip from "@mui/material/Tooltip";
import { Toast as PrimeReactToast } from "primereact/toast";

import { Tag } from "primereact/tag";
import { ProgressBar } from "primereact/progressbar";

//
import CreateFormQuarterlyReport from "./CreateFormQuarterlyReport";
//
import AdvancedFilterForm from "./AdvancedFilterForm";

//
import useHandleQueryError from "../../hooks/useHandleQueryError";
//
import useHandleMutationError from "../../hooks/useHandleMutationError.js";

import InlineExpandableText from "../../components/helpers/InlineExpandableText";
function ListPage({ loggedInUserData, level, spatieCurrentStatuses, showProposingColumns, showAgreedColumns, multipleSelect = true, ...props }) {
    const navigate = useNavigate();

    //
    const [selectedFilters, setSelectedFilters] = useState({
        // startDate: moment().subtract(30, "days").toDate(),
        // endDate: moment().toDate(),
        // selectedTargetAudience: [],
    });
    console.log("🚀 ~Feedback Report ListPage ~ selectedFilters:", selectedFilters);

    const queryClient = useQueryClient();
    const { data, isLoading, isError, error, status } = useQuery({
        queryKey: ["feedback_reports", spatieCurrentStatuses, selectedFilters],
        queryFn: () =>
            getAllFeedbackReports({
                spatie_current_statuses: spatieCurrentStatuses,
                ...selectedFilters,
                startDate: selectedFilters?.startDate ? moment(selectedFilters?.startDate).format("YYYY-MM-DD HH:mm:ss") : null,
                endDate: selectedFilters?.endDate ? moment(selectedFilters?.endDate).format("YYYY-MM-DD HH:mm:ss") : null,
            }),
    });
    console.log("🚀 ~Feedback Report ListPage ~ data: ", data);

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(isError, error);

    const [deleteMutationIsLoading, setDeleteMutationIsLoading] = useState(false);
    const deleteMutation = useMutation({
        mutationFn: (variables) => postToBulkDestroyFeedbackReports(variables),
        onSuccess: (data) => {
            queryClient.invalidateQueries(["feedback_reports"]);
            toast.success("Deleted Successfully");
            setDeleteMutationIsLoading(false);
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(deleteMutation?.error, setDeleteMutationIsLoading);
    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         ProgramDeleteMutation.mutate(id);
    //     }
    // };

    const handleDelete = (e, data) => {
        console.log("🚀 ~ handleDelete ~ data:", data);
        // let selectedDeleteId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(data),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (selectedDeleteData) => {
        if (selectedDeleteData !== null) {
            setDeleteMutationIsLoading(true);

            if (multipleSelect) {
                deleteMutation.mutate({ feedback_reports: selectedDeleteData });
            } else {
                deleteMutation.mutate({ feedback_reports: [selectedDeleteData] });
            }
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    const validateSelectedRowsOnBulkEdit = () => {
        if (tableSelectedRows.length === 0) {
            toast.warning("No rows selected");
            return false;
        }

        if (tableSelectedRows.length < 0 || tableSelectedRows.length > 1) {
            toast.warning("To edit you must choose one row at a time");
            return false;
        }

        return true;
    };

    const handleShowEditForm = (e, item) => {
        console.log("🚀 ~ handleShowEditForm on bulke edit ~ item:", item);
        if (multipleSelect) {
            if (validateSelectedRowsOnBulkEdit()) {
                setSelectedItem(item[0]);
                setShowEditForm(true);
            }
        } else {
            setSelectedItem(item);
            setShowEditForm(true);
        }
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    // const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;
    const activeUser = loggedInUserData;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    //
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [selectedFeedback, setSelectedFeedback] = useState(null);

    const handleShowFeedbackModal = (rowData) => {
        setSelectedFeedback(rowData);
        setShowFeedbackModal(true);
    };

    const handleCloseFeedbackModal = () => {
        setShowFeedbackModal(false);
    };

    //
    // Helper function to determine progress bar color
    const getProgressBarColor = (completion) => {
        if (completion <= 33) {
            return "red"; // Low completion
        } else if (completion <= 66) {
            return "orange"; // Medium completion
        }
        return "green"; // High completion
    };

    const capitalizeFirstLetter = (str) => {
        if (!str) return "N/A";
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const columns = [
        { title: "#", width: "5%", field: "id", hidden: true },
        {
            title: "Type",
            field: "type",
            render: (rowData) => {
                return capitalizeFirstLetter(rowData?.type);
            },
        },

        {
            title: "Financial Year",
            field: "financial_year.name",
            render: (rowData) => (
                <Tag
                    value={rowData?.financial_year?.name ? rowData?.financial_year?.name : "N/A"}
                    severity={rowData?.financial_year?.name ? "primary" : "info"} // 'info' for blue, 'success' for green
                />
            ),
        },
        {
            title: "Quarter",
            field: "quarter.name",
            render: (rowData) => (
                <Tag
                    value={rowData?.quarter?.name ? rowData?.quarter?.name : "N/A"}
                    severity={rowData?.quarter?.name ? "warning" : "info"} // 'info' for blue, 'success' for green
                />
            ),
        },

        {
            title: "Title",
            field: "name",
            render: (rowData) => {
                const maxLength = 20; // Define max length before truncation
                const title = rowData?.name || "No title";

                return <InlineExpandableText text={title} maxLength={maxLength} />;
            },
        },
        {
            title: "Description",
            field: "description",
            render: (rowData) => {
                const maxLength = 20; // Define max length before truncation
                const description = rowData?.description || "No description";

                return <InlineExpandableText text={description} maxLength={maxLength} />;
            },
        },
        {
            title: "Completion",
            field: "completion",
            render: (rowData) => {
                const completionValue = rowData?.completion ? rowData.completion : 0; // Explicit null/undefined check
                return (
                    <div>
                        {/* <center>
                            <small>{completionValue}%</small>
                        </center> */}
                        <ProgressBar value={completionValue} color={getProgressBarColor(completionValue)} showValue={true} style={{ height: "16px" }} />
                    </div>
                );
            },
        },

        {
            title: "Start Date",
            field: "start_date",
            render: (rowData) => (rowData?.start_date ? moment(rowData.start_date).format("dddd, MMMM D, YYYY") : "N/A"),
        },
        {
            title: "End Date",
            field: "end_date",
            render: (rowData) => (rowData?.end_date ? moment(rowData.end_date).format("dddd, MMMM D, YYYY") : "N/A"),
        },

        { title: "Current Status", field: "spatie_current_status.name", hidden: true },
        { title: "Current Status Reason", field: "spatie_current_status.reason", hidden: true },
        { title: "Created By", field: "created_by.name", hidden: true },
        { title: "Created By Email", field: "created_by.email", hidden: true },
        { title: "Updated By", field: "updated_by.name", hidden: true },
        {
            title: "Created At",
            field: "created_at",
            hidden: true,
            render: (rowData) => {
                return moment(rowData?.created_at).format("lll");
            },
        },
        {
            title: "Updated At",
            field: "updated_at",
            hidden: true,
            render: (rowData) => {
                return moment(rowData?.updated_at).format("lll");
            },
        },
        { title: "Status", field: "status", hidden: true },
        ...(multipleSelect
            ? [
                  {
                      title: "View",
                      field: "created_by.name",
                      render: (rowData) => {
                          return (
                              <div style={{ cursor: "pointer" }} onClick={() => handleShowFeedbackModal(rowData)}>
                                  <Tooltip title="Click to view report">
                                      <VisibilityIcon style={{ color: "blue" }} />
                                  </Tooltip>
                              </div>
                          );
                      },
                  },
              ]
            : []),
    ];

    //
    //table selection
    const primeReactToastRef = useRef(null);
    const [tableSelectedRows, setTableSelectedRows] = useState([]);
    const [showTakeActionForm, setShoTakeActionForm] = useState(false);
    const [showFeedbackReportActionForm, setShowFeedbackReportActionForm] = useState(false);

    const validateSelectedRows = () => {
        if (tableSelectedRows.length === 0) {
            primeReactToastRef.current.show({ severity: "warn", summary: "Warning", detail: "No rows selected", life: 3000 });
            return false;
        }

        return true;
    };

    const handleShowTakeActionForm = () => {
        if (validateSelectedRows()) {
            setShoTakeActionForm(true);
        }
    };

    const handleCloseTakeActionForm = () => {
        // setTableSelectedRows([]);
        setShoTakeActionForm(false);
    };

    // Handle showing the feedback report action form
    const handleShowFeedbackReportActionForm = () => {
        setShowFeedbackReportActionForm(true);
    };

    // Handle closing the feedback report action form
    const handleCloseFeedbackReportActionForm = () => {
        setShowFeedbackReportActionForm(false);
    };

    //============== selected filters ===================

    const handleSubmitAdvancedFilter = (formData) => {
        console.log("Form submitted with:", formData);

        setSelectedFilters(formData);
        // Handle form submission (e.g., API call, state update)
    };

    const showTakeActionButton = level !== "Rejected by AAGC" || (level === "Rejected by AAGC" && activeUser?.permissions.includes("reject feedback reports"));

    return (
        <div style={{ width: "100%" }}>
            <PrimeReactToast ref={primeReactToastRef} />
            <div>
                <AdvancedFilterForm initialData={selectedFilters} onSubmit={handleSubmitAdvancedFilter} />
            </div>
            <Panel header="Feedback Reports" style={{ marginBottom: "20px" }} toggleable>
                <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                    {showTakeActionButton && activeUser?.permissions.includes("take action on feedback reports") && tableSelectedRows.length > 0 && (
                        <Button
                            label="Take Action"
                            className="p-button-primary"
                            onClick={() => {
                                handleShowTakeActionForm();
                            }}
                        />
                    )}
                    {showTakeActionForm && <TakeActionForm level={level} setTableSelectedRows={setTableSelectedRows} selectedTableRowsData={tableSelectedRows} show={showTakeActionForm} onHide={handleCloseTakeActionForm} onClose={handleCloseTakeActionForm} />}

                    {/* {activeUser?.permissions.includes("create feedback") && <Button label="Add Feedback" className="p-button-primary" onClick={() => setShowAddForm(true)} />}
                    <CreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} productCategoryBrandData={productCategoryBrandData} /> */}

                    {activeUser?.permissions.includes("create feedback report") && level === "reports" && <Button label="Create Quarterly Report" severity="info" onClick={handleShowFeedbackReportActionForm} />}
                    {showFeedbackReportActionForm && <CreateFormQuarterlyReport show={showFeedbackReportActionForm} onHide={handleCloseFeedbackReportActionForm} onClose={handleCloseFeedbackReportActionForm} />}
                </div>

                <MuiTable
                    // tableTitle="Citized Feedback"
                    tableData={data?.data?.data ?? []}
                    tableColumns={columns}
                    // showEdit={activeUser?.permissions.includes("update feedback")}
                    // handleShowEditForm={handleShowEditForm}
                    //------- bulk edit -----------
                    showBulkEdit={activeUser?.permissions.includes("create feedback report")}
                    handleBulkEdit={handleShowEditForm}
                    //--------- bulk delete ------------
                    handleBulkDelete={(e, item) => handleDelete(e, item)}
                    showBulkDelete={activeUser?.permissions.includes("create feedback report")}
                    loading={isLoading || status === "loading" || deleteMutationIsLoading}
                    // //
                    handleViewPage={(rowData) => {
                        // navigate("product-category-brand", { state: { productCategoryBrandData: rowData } });
                        handleShowFeedbackModal(rowData);
                    }}
                    showViewPage={multipleSelect ? false : true}
                    hideRowViewPage={false}
                    // //
                    // exportButton={true}
                    // pdfExportTitle="Feedback Reports"
                    // csvExportTitle="Feedback Reports"
                    //multiple select
                    selection={multipleSelect ? true : false}
                    showSelectAllCheckbox={multipleSelect ? true : false}
                    showTextRowsSelected={multipleSelect ? true : false}
                    selectionChange={(selectedRows) => {
                        console.log("selected rows on sselection change : ", selectedRows);
                        setTableSelectedRows(selectedRows);
                    }}
                />

                {showFeedbackModal && <FeedbackReportDetailsModal showProposingColumns={showProposingColumns} showAgreedColumns={showAgreedColumns} feedbackReportData={selectedFeedback} show={showFeedbackModal} onHide={handleCloseFeedbackModal} onClose={handleCloseFeedbackModal} />}
                {selectedItem && <EditForm rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />}
            </Panel>
        </div>
    );
}

export default ListPage;
