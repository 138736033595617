import React, { useEffect, useState, useRef, useMemo } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import FeedbackDetailsModal from "../../global_views/FeedbackDetailsModal";

import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";

import { useNavigate } from "react-router-dom";

import { getAllFeedbacks } from "../../../services/feedback/feedback-service";

import { Dropdown } from "primereact/dropdown";
import MuiTable from "../../../components/general_components/MuiTable";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import { Panel } from "primereact/panel";
import Tooltip from "@mui/material/Tooltip";

import { Tag } from "primereact/tag";

//
import useHandleQueryError from "../../../hooks/useHandleQueryError";
//
import useHandleMutationError from "../../../hooks/useHandleMutationError.js";

//
import { MultiSelect } from "primereact/multiselect";
import { classNames } from "primereact/utils";

import MuiTablePdfTemplate from "../../global_views/report_templates/MuiTablePdfTemplate";

import AdvancedFilterForm from "./AdvancedFilterForm";
import InlineExpandableText from "../../../components/helpers/InlineExpandableText";

function ListPage({ loggedInUserData, groupDetails, ...props }) {
    const navigate = useNavigate();

    const [selectedFilters, setSelectedFilters] = useState({
        startDate: moment().subtract(30, "days").toDate(),
        endDate: moment().toDate(),
        selectedStatuses: [],
    });

    // Memoize the selectedStatuses array
    const memorisedSelectedStatuses = useMemo(() => {
        return selectedFilters?.selectedStatuses.map((status) => status.code);
    }, [selectedFilters?.selectedStatuses]); // Only recompute if statuses array changes

    const queryClient = useQueryClient();
    const { data, isLoading, isError, error, status } = useQuery({
        queryKey: ["feedback", "by_created_by_group_id", groupDetails?.id, selectedFilters],
        queryFn: () =>
            getAllFeedbacks({
                created_by_group_id: groupDetails?.id,
                spatie_current_statuses: memorisedSelectedStatuses,
                startDate: selectedFilters?.startDate ? moment(selectedFilters?.startDate).format("YYYY-MM-DD HH:mm:ss") : null,
                endDate: selectedFilters?.endDate ? moment(selectedFilters?.endDate).format("YYYY-MM-DD HH:mm:ss") : null,
            }),
    });
    console.log("🚀 ~ Feedbacks overview ListPage ~ data: ", data);

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(isError, error);

    //
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [selectedFeedback, setSelectedFeedback] = useState(null);

    const handleShowFeedbackModal = (rowData) => {
        setSelectedFeedback(rowData);
        setShowFeedbackModal(true);
    };

    const handleCloseFeedbackModal = () => {
        setShowFeedbackModal(false);
    };

    const getSeverityColor = (status) => {
        switch (status) {
            case "submitted":
                return "info"; // Blue
            case "auditable":
                return "warning"; // Yellow/Orange
            case "non audit":
                return "danger"; // Red
            case "summarised":
                return "success"; // Green
            case "tagged":
                return "success"; // Green
            default:
                return "secondary"; // Gray or any other default color
        }
    };

    const columns = [
        { title: "#", width: "5%", field: "id", hidden: true },

        {
            title: "Feedback No",
            field: "feedback_no",
            render: (rowData) => (
                <Tag
                    value={rowData?.feedback_no ? rowData?.feedback_no : "N/A"}
                    severity={getSeverityColor(rowData?.spatie_current_status?.name)} // 'info' for blue, 'success' for green
                />
            ),
        },

        // {
        //     title: "Title",
        //     field: "title",
        //     render: (rowData) => {
        //         const maxLength = 20; // Define max length before truncation
        //         const title = rowData?.title || "No title";
        //         return title.length > maxLength
        //             ? `${title.substring(0, maxLength)}...` // Truncate and add ellipses
        //             : title; // Display full title if it's short enough
        //     },
        // },
        {
            title: "Message",
            field: "message",
            render: (rowData) => {
                const maxLength = 20; // Define max length before truncation
                const message = rowData?.message || "No message";
                return <InlineExpandableText text={message} maxLength={maxLength} />;
            },
        },

        { title: "Sector", field: "sector.name", hidden: false },
        {
            title: "Has Attachments",
            field: "has_attachments",
            render: (rowData) => {
                return rowData?.has_attachments ? "Yes" : "No";
            },
            hidden: false,
        },
        { title: "Location Scope", field: "location_scope", hidden: false },
        { title: "Country", field: "country.name", hidden: false },
        { title: "Region", field: "region.name", hidden: false },
        { title: "District", field: "district.name", hidden: false },
        { title: "County", field: "county.name", hidden: false },
        { title: "Subcounty", field: "subcounty.name", hidden: false },
        { title: "Parish", field: "parish.name", hidden: false },
        { title: "Village", field: "village.name", hidden: false },
        { title: "Latitude", field: "latitude", hidden: false },
        { title: "Longitude", field: "longitude", hidden: false },
        { title: "Current Status", field: "spatie_current_status.name", hidden: false },
        { title: "Current Status Reason", field: "spatie_current_status.reason", hidden: false },
        { title: "Created By", field: "created_by.name", hidden: false },
        { title: "Created By Email", field: "created_by.email", hidden: false },
        { title: "Updated By", field: "updated_by.name", hidden: false },
        {
            title: "Date",
            field: "created_at",
            hidden: false,
            render: (rowData) => {
                return moment(rowData?.created_at).format("lll");
            },
        },
        {
            title: "Updated At",
            field: "updated_at",
            hidden: false,
            render: (rowData) => {
                return moment(rowData?.updated_at).format("lll");
            },
        },
        { title: "Status", field: "status", hidden: false },
        { title: "Submit Status", field: "feedback_submit_status", hidden: false },
        {
            title: "View",
            field: "created_by.name",
            render: (rowData) => {
                return (
                    <div style={{ cursor: "pointer" }} onClick={() => handleShowFeedbackModal(rowData)}>
                        <Tooltip title="Click to view feedback">
                            <VisibilityIcon style={{ color: "blue" }} />
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    //

    //============== status change ===================

    const handleSubmitAdvancedFilter = (formData) => {
        console.log("Form submitted with:", formData);

        setSelectedFilters(formData);
        // Handle form submission (e.g., API call, state update)
    };

    // Conditionally format start and end dates or use "N/A" if null
    const formattedStartDate = selectedFilters.startDate ? moment(selectedFilters.startDate).format("MMMM D, YYYY") : "N/A";
    const formattedEndDate = selectedFilters.endDate ? moment(selectedFilters.endDate).format("MMMM D, YYYY") : "N/A";

    return (
        <div style={{ width: "100%" }}>
            {/* <div className="col-12 xl:col-12">
                <div className="card">
                    <p>Funders Are Attched onto subprojects</p>
                </div>
            </div> */}
            <Panel header="Feedback" style={{ marginBottom: "20px" }} toggleable>
                <div>
                    <AdvancedFilterForm initialData={selectedFilters} onSubmit={handleSubmitAdvancedFilter} />
                </div>

                <MuiTable
                    // tableTitle="Citized Feedback"
                    tableData={data?.data?.data ?? []}
                    tableColumns={columns}
                    loading={isLoading || status === "loading"}
                    // //

                    exportButton={true}
                    pdfExportTitle={`Citizen Feedback Plaform Report: ${formattedStartDate} - ${formattedEndDate}`}
                    csvExportTitle={`Citizen Feedback Plaform Report: ${formattedStartDate} - ${formattedEndDate}`}
                    //
                    pdfCustomExporting={true}
                    pdfCustomReportTemplate={MuiTablePdfTemplate}
                    // //multiple select
                />

                {showFeedbackModal && <FeedbackDetailsModal feedbackDetailData={selectedFeedback} show={showFeedbackModal} onHide={handleCloseFeedbackModal} onClose={handleCloseFeedbackModal} />}
            </Panel>
        </div>
    );
}

export default ListPage;
