import moment from "moment";
import React, { useEffect, useState } from "react";

import { getAllCountrys, getCountrysById, postCountrys, updateCountrys, deleteCountryById } from "../../../services/locations/countries-service";
import CreateForm from "./CreateForm";
import EditForm from "./EditForm";
import MuiTable from "../../../components/general_components/MuiTable";
import WaterIsLoading from "../../../components/general_components/WaterIsLoading";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import { Panel } from "primereact/panel";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import useHandleQueryError from "../../../hooks/useHandleQueryError";
//
import useHandleMutationError from "../../../hooks/useHandleMutationError.js";

function ListPage() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [districts, setDistricts] = useState([]);
    const [selectedItem, setSelectedItem] = useState({ id: null });
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const columns = [
        {
            title: "Name",
            cellStyle: {
                whiteSpace: "nowrap",
                padding: "8px",
            },
            field: "name",
        },
        {
            title: "Code",
            field: "code",
        },
        {
            title: "Date",
            field: "created_at",
            render: (rowData) => moment(rowData.created_at).format("lll"),
        },
    ];

    const getListOfCountriesQuery = useQuery({
        queryKey: ["countries"],
        queryFn: () => getAllCountrys(),
    });

    console.log("getListOfCountriesQuery : ", getListOfCountriesQuery);

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(getListOfCountriesQuery?.isError, getListOfCountriesQuery?.error);

    const [deleteMutationIsLoading, setDeleteMutationIsLoading] = useState(false);
    const deleteMutation = useMutation({
        mutationFn: deleteCountryById,
        onSuccess: (data) => {
            queryClient.invalidateQueries(["countries"]);
            setDeleteMutationIsLoading(false);
            toast.success("Record Deleted Successfully");
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(deleteMutation?.error, setDeleteMutationIsLoading);

    const onFormClose = () => {
        setShowAddForm(false);
    };

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete? ");
    //     if (result === true) {
    //         setLoading(true);
    //         deleteDistrictMutation(id);
    //         setLoading(false);
    //     }
    // };

    const handleDelete = (event, id) => {
        let deleteDistrictId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(deleteDistrictId),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (deleteDistrictId) => {
        if (deleteDistrictId !== null) {
            setDeleteMutationIsLoading(true);
            deleteMutation.mutate(deleteDistrictId);
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    return (
        <div style={{ width: "100%" }}>
            <Panel header="Countries" style={{ marginBottom: "20px" }} toggleable>
                {/* <div className="d-flex justify-content-end mb-3" style={{ width: "100%" }}>
                    {activeUser?.permissions.includes("add programs") && <Button label="Add Program" className="p-button-primary right-0" onClick={() => setShowAddForm(true)} />}
                    <ProgramsCreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} />
                </div> */}
                <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end" }}>
                    <Button onClick={() => setShowAddForm(true)}>Add Country</Button>

                    <CreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} />
                </div>
                <MuiTable
                    tableTitle="Countries"
                    tableData={getListOfCountriesQuery?.data?.data?.data ?? []}
                    tableColumns={columns}
                    handleShowEditForm={handleShowEditForm}
                    handleDelete={(e, item_id) => handleDelete(e, item_id)}
                    showEdit={true}
                    showDelete={true}
                    loading={loading || getListOfCountriesQuery.isLoading || getListOfCountriesQuery.status == "loading" || deleteMutationIsLoading}
                    exportButton={true}
                    pdfExportTitle="Countries"
                    csvExportTitle="Countries"
                />
                {/* <ConfirmDialog />; */}
                <EditForm initialData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />
            </Panel>
        </div>
    );
}

export default ListPage;
