import axiosAPI from "../axiosApi";

export async function getAllAuditAreas(params = {}) {
    const response = await axiosAPI.get("audit-areas", { params: params });
    return response;
}

export async function getAuditAreaById(id) {
    const response = await axiosAPI.get(`audit-areas/` + id);
    return response;
}

export async function postAuditArea(data) {
    const response = await axiosAPI.post(`audit-areas`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function updateAuditArea(id, data) {
    const response = await axiosAPI.post(`audit-areas/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function deleteAuditAreaById(id) {
    const response = await axiosAPI.delete(`audit-areas/${id}`);
    return response;
}
