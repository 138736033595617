import React from "react";
import moment from "moment";

//
import useAuthContext from "../../../context/AuthContext";

import FeedbackBySectorBarChart from "./charts/FeedbackBySectorBarChart";
import FeedbackByRegionPieChart from "./charts/FeedbackByRegionPieChart";
import FeedbackByLocationScopeDoughnutChart from "./charts/FeedbackByLocationScopeDoughnutChart";
import FeedbackByRegionTable from "./tables/row/FeedbackByRegionTable";
import FeedbackBySectorTable from "./tables/row/FeedbackBySectorTable";
import FeedbackByLocationScopeTable from "./tables/row/FeedbackByLocationScopeTable";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressBar } from "primereact/progressbar";
import { Tag } from "primereact/tag";

const PrintableFeedbackReportTemplate = ({ feedbackReportData, showProposingColumns, showAgreedColumns }) => {
    const summarisedFeedback = feedbackReportData?.feedback_report_summarised_feedback?.map((item) => item.summarised_feedback) || [];

    const totalFeedback = summarisedFeedback.length;
    const uniqueRegions = new Set(summarisedFeedback.map((item) => item.region?.name).filter(Boolean)).size;
    const uniqueSectors = new Set(summarisedFeedback.map((item) => item.sector?.name).filter(Boolean)).size;

    //
    const { getUserQuery } = useAuthContext();

    const loggedInUserDetails = getUserQuery?.data?.data;

    const getFeedbackReportSeverityColor = (status) => {
        switch (status) {
            case "submitted":
                return "info"; // Blue
            case "Corporate Management":
                return "primary"; // Dark Blue or any strong color to represent high-level management
            case "AAGC":
                return "warning"; // Yellow/Orange (indicating it's under AAGC review)
            case "Accepted by AAGC":
                return "success"; // Green (indicating approval or completion by AAGC)
            case "Rejected by AAGC":
                return "danger"; // Red (indicating rejection or failure by AAGC)
            case "Directorate Officer":
                return "info"; // Blue (indicating it's being handled by a Directorate Officer)
            case "in progress":
                return "warning"; // Yellow/Orange (indicating it's still in progress)
            case "done":
                return "success"; // Green (indicating completion)
            case "failed":
                return "danger"; // Red (indicating failure)
            case "not satisfied":
                return "danger"; // Red (indicating the task is completed but with an unsatisfactory outcome)
            case "completed":
                return "success"; // Green (indicating completion)
            default:
                return "secondary"; // Gray or any other default color for unknown statuses
        }
    };

    const styles = {
        container: {
            padding: "20px",
            fontFamily: "Arial, sans-serif",
            fontSize: "12px",
            width: "100%",
        },
        header: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "20px",
            flexDirection: "column",
            width: "100%",
        },
        logo: {
            height: "50px",
        },
        title: {
            fontSize: "18px",
            fontWeight: "bold",
        },
        paragraphContainer: {
            marginTop: "20px",
        },
        paragraph: {
            marginBottom: "10px",
            lineHeight: "1.6",
        },
        noDataMessage: {
            fontSize: "14px",
            color: "#888",
            textAlign: "center",
            marginTop: "20px",
        },
        footer: {
            marginTop: "20px",
            textAlign: "center",
            fontSize: "10px",
            color: "#666",
        },
        tablesContainer: {
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginTop: "20px",
            gap: "20px",
        },
        table: {
            width: "100%", // Adjust width for responsive design
        },
        statsContainer: {
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
            fontSize: "14px",
            fontWeight: "bold",
            borderBottom: "0.2px solid #ddd",
            padding: "10px",
            borderRadius: "5px",
        },

        chartsContainer: {
            display: "flex",
            // flexDirection: "column",
            marginTop: "20px",
            gap: "20px",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
        },
        chart: {
            display: "flex",
            // flexDirection: "column",
            gap: "1rem",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            width: "500px", // Adjust width for responsive design
        },
    };

    // Helper function to determine progress bar color
    const getProgressBarColor = (completion) => {
        if (completion <= 33) {
            return "red"; // Low completion
        } else if (completion <= 66) {
            return "orange"; // Medium completion
        }
        return "green"; // High completion
    };

    const capitalizeFirstLetter = (str) => {
        if (!str) return "N/A";
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    return (
        <table id="print-content" style={styles.container}>
            <thead>
                <tr>
                    <td>
                        <div className="header-space">&nbsp;</div>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <div className="content" style={{ width: "100%" }}>
                            {/* Report Details */}
                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap", padding: "10px" }}>
                                {/* col 1 */}
                                <div style={{ width: "300px", padding: "5px" }}>
                                    <div className="p-1">
                                        <strong>Title:</strong> {feedbackReportData?.name || "N/A"}
                                    </div>
                                    <div className="p-1">
                                        <strong>Type:</strong> {capitalizeFirstLetter(feedbackReportData?.type)}
                                    </div>

                                    {feedbackReportData?.type === "quarterly report" && (
                                        <>
                                            <div className="p-1">
                                                <strong>Financial Year:</strong>
                                                <Tag value={feedbackReportData?.financial_year?.name || "N/A"} severity="primary" />
                                            </div>
                                            <div className="p-1">
                                                <strong>Quarter:</strong>
                                                <Tag value={feedbackReportData?.quarter?.name || "N/A"} severity="warning" />
                                            </div>
                                        </>
                                    )}

                                    <div className="p-1">
                                        <strong>Start Date:</strong>
                                        {feedbackReportData?.start_date ? moment(feedbackReportData.start_date).format("dddd, MMMM D, YYYY") : "N/A"}
                                    </div>

                                    <div className="p-1">
                                        <strong>End Date:</strong>
                                        {feedbackReportData?.end_date ? moment(feedbackReportData.end_date).format("dddd, MMMM D, YYYY") : "N/A"}
                                    </div>
                                </div>

                                {/* Description */}
                                <div style={{ width: "300px", padding: "5px" }}>
                                    <div className="p-1">
                                        <strong>Completion:</strong>
                                        <div>
                                            {/* <small>{feedbackReportData?.completion ?? 0}%</small> */}
                                            <ProgressBar value={feedbackReportData?.completion || 0} color={getProgressBarColor(feedbackReportData?.completion)} showValue style={{ height: "16px", width: "100px" }} />
                                        </div>
                                    </div>
                                    <div className="p-1">
                                        <strong>Description:</strong>
                                        {feedbackReportData?.description || "No description"}
                                    </div>

                                    <div className="p-1">
                                        <strong>Current Status:</strong>
                                        <Tag
                                            value={feedbackReportData?.spatie_current_status?.name ? feedbackReportData?.spatie_current_status?.name : "N/A"}
                                            severity={getFeedbackReportSeverityColor(feedbackReportData?.spatie_current_status?.name)} // 'info' for blue, 'success' for green
                                        />
                                    </div>

                                    <div className="p-1">
                                        <strong>Reason:</strong>
                                        {feedbackReportData?.spatie_current_status?.reason || "N/A"}
                                    </div>
                                </div>
                            </div>

                            {/* Statistics Section */}
                            <div style={styles.statsContainer}>
                                <div>Total Feedback: {totalFeedback}</div>
                                <div>Number of Regions: {uniqueRegions}</div>
                                <div>Number of Sectors: {uniqueSectors}</div>
                            </div>

                            {/* Tables Section */}
                            <div style={styles.tablesContainer}>
                                <div style={styles.table}>
                                    <FeedbackByLocationScopeTable data={summarisedFeedback} />
                                </div>
                                <div style={styles.table}>
                                    <FeedbackByRegionTable data={summarisedFeedback} />
                                </div>
                                <div style={styles.table}>
                                    <FeedbackBySectorTable data={summarisedFeedback} />
                                </div>
                            </div>

                            {/* DataTable */}
                            <div style={{ margin: "1rem 0" }}>
                                <small>Tagged Summarised Feedback</small>
                                <DataTable value={summarisedFeedback} responsiveLayout="scroll" showGridlines stripedRows>
                                    {/* Always visible columns */}
                                    <Column field="feedback_no" header="No." />
                                    <Column field="created_at" header="Date Submitted" body={(rowData) => moment(rowData?.created_at).format("MMMM Do YYYY, h:mm:ss a")} />
                                    <Column field="message" header="Issue as Recorded in CFP" />
                                    <Column field="location_scope" header="Scope" />
                                    <Column field="district.name" header="District" />
                                    <Column field="sector.name" header="Sector" />
                                    <Column field="has_attachments" header="Attachment Y/N" body={(rowData) => (rowData?.has_attachments ? "Yes" : "No")} />
                                    <Column
                                        field="audit_areas"
                                        header="Identified Audit Areas"
                                        body={(rowData) => {
                                            console.log("dsgsdfgsegRow Data:", rowData);
                                            const auditAreas = Array.isArray(rowData?.audit_areas) ? rowData.audit_areas : [];
                                            return auditAreas.length > 0 ? auditAreas.map((area, index) => <div key={index}>{area?.name}.</div>) : "N/A";
                                        }}
                                    />

                                    {/* Conditional columns */}
                                    {showProposingColumns && <Column field="proposed_actions" header="Proposed Actions" body={(rowData) => rowData?.proposed_actions || "N/A"} />}
                                    {showProposingColumns && <Column field="proposed_directorate.name" header="Proposed Responsible Directorate" body={(rowData) => rowData?.proposed_directorate?.name || "N/A"} />}
                                    {showAgreedColumns && <Column field="agreed_actions" header="Agreed Actions" body={(rowData) => rowData?.agreed_actions || "N/A"} />}
                                    {showAgreedColumns && <Column field="responsible_directorate.name" header="Agreed Responsible Directorate" body={(rowData) => rowData?.responsible_directorate?.name || "N/A"} />}
                                </DataTable>
                            </div>

                            {/* Charts Section */}
                            <div style={styles.chartsContainer}>
                                <div style={styles.chart}>
                                    <FeedbackByLocationScopeDoughnutChart data={summarisedFeedback} />
                                </div>
                                <div style={styles.chart}>
                                    <FeedbackByRegionPieChart data={summarisedFeedback} />
                                </div>
                                <div style={{ ...styles.chart, width: "700px" }}>
                                    <FeedbackBySectorBarChart data={summarisedFeedback} />
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <div className="footer-space">&nbsp;</div>
                    </td>
                </tr>
            </tfoot>
            <div className="header" style={styles.header}>
                <img src="assets/oag_photos/uganda_oag.png" alt="Logo" style={{ height: "50px" }} />
                <span style={styles.title}>Feedback Report</span>
            </div>
            <div className="footer" style={styles.footer}>
                <div>
                    Generated by {loggedInUserDetails?.name} on {moment().format("dddd, MMMM Do YYYY, h:mm:ss A")}
                </div>
                <div>© {new Date().getFullYear()} OAG Citizen Feedback Platform</div>
            </div>
        </table>
    );
};

export default PrintableFeedbackReportTemplate;
