import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { toast } from "react-toastify";

import { getAllDirectorates } from "../../../../services/directorates/directorates-service";

import useHandleQueryError from "../../../../hooks/useHandleQueryError";

import { useQuery } from "@tanstack/react-query";
import setFieldTouched from "final-form-set-field-touched";

import { AutoComplete } from "primereact/autocomplete";
import { ProgressSpinner } from "primereact/progressspinner";

const DirectorateRow = ({ onSubmit, initialData, type }) => {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);

    // Directorate state
    // Determine the initial directorate based on the type
    const [selectedDirectorate, setSelectedDirectorate] = useState(initialData?.directorate);
    const [dropdownDirectorates, setDropdownDirectorates] = useState([]);

    const directoratesQuery = useQuery({
        queryKey: ["directorates"],
        queryFn: () => getAllDirectorates(), // Fetch directorates
    });

    // Handle query errors
    useHandleQueryError(directoratesQuery?.isError, directoratesQuery?.error);

    // Validate function for the form
    const validate = (values) => {
        const errors = {};
        // Validate 'directorate'
        if (!values.directorate) errors.directorate = "Directorate is required";
        return errors;
    };

    // Handle form submission
    const onSubmitForm = (data, form) => {
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("Please fill in all required fields");
        }
    };

    // Handle confirmation of submission
    const onConfirm = () => {
        if (pendingData) {
            onSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    // Handle cancellation of dialog
    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    return (
        <div className="card p-fluid">
            <Form
                onSubmit={onSubmitForm}
                initialValues={initialData}
                validate={validate}
                render={({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Field name="directorate">
                            {({ input, meta }) => {
                                const directoratesData = directoratesQuery?.data?.data?.data || [];

                                const fetchSuggestions = (event) => {
                                    let query = event.query.toLowerCase();
                                    let filtered = directoratesData.filter((dir) => dir?.name?.toLowerCase().includes(query));
                                    setDropdownDirectorates(filtered);
                                };

                                return (
                                    <div className="p-field m-4">
                                        <label htmlFor="directorate">Directorate</label>
                                        <AutoComplete
                                            {...input}
                                            multiple={false}
                                            suggestions={dropdownDirectorates}
                                            completeMethod={fetchSuggestions}
                                            field="name"
                                            value={selectedDirectorate}
                                            onChange={(e) => {
                                                setSelectedDirectorate(e.value);
                                                input.onChange(e.value);
                                            }}
                                            dropdown={true}
                                            disabled={directoratesQuery?.isLoading}
                                            placeholder="Select directorate"
                                            className={classNames({ "p-invalid": meta.touched && meta.error })}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                        {directoratesQuery?.isLoading && <ProgressSpinner style={{ width: "20px", height: "20px" }} />}
                                    </div>
                                );
                            }}
                        </Field>
                        <Button type="submit" label="Submit" className="p-button-primary" />
                    </form>
                )}
            />

            <Dialog
                header="Confirmation"
                visible={showConfirmDialog}
                style={{ minWidth: "350px" }}
                maximizable
                modal
                onHide={onCancel}
                footer={
                    <div>
                        <Button label="Yes" icon="pi pi-check" onClick={onConfirm} autoFocus />
                        <Button label="No" icon="pi pi-times" onClick={onCancel} className="p-button-secondary" />
                    </div>
                }
            >
                <p>Are you sure you want to submit?</p>
            </Dialog>
        </div>
    );
};

export default DirectorateRow;
