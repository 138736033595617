import React, { useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const FeedbackByLocationScopeTable = ({ data = [] }) => {
    // Aggregate feedback by location_scope
    const feedbackByLocationScope = useMemo(() => {
        const aggregation = {};
        data.forEach((row) => {
            const locationScope = row.location_scope; // Use the `location_scope` key
            if (locationScope) {
                if (!aggregation[locationScope]) {
                    aggregation[locationScope] = 1;
                } else {
                    aggregation[locationScope]++;
                }
            }
        });
        return Object.entries(aggregation).map(([name, count]) => ({
            name,
            count,
        }));
    }, [data]);

    return (
        <div>
            <small style={{ marginBottom: "5px" }}>Feedback By Location Scope</small>
            <DataTable value={feedbackByLocationScope} showGridlines paginator rows={10} style={{ width: "100%" }}>
                <Column field="name" header="Location Scope" sortable />
                <Column field="count" header="Feedback Count" sortable />
            </DataTable>
        </div>
    );
};

export default FeedbackByLocationScopeTable;
