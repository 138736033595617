import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { toast } from "react-toastify";
import moment from "moment";
import { Accordion, AccordionTab } from "primereact/accordion";

const AdvancedFilterForm = ({ onSubmit, initialData, selectedTargetAudience = [] }) => {
    const [accordionActiveIndex, setAccordionActiveIndex] = useState(null);

    const targetAudienceOptions = [
        { id: 1, name: "All", code: "all" },
        { id: 2, name: "Groups", code: "groups" },
        { id: 3, name: "Users", code: "users" },
    ];

    const validate = (values) => {
        const errors = {};
        if (values.startDate && values.endDate && moment(values.startDate).isAfter(values.endDate)) {
            errors.startDate = "Start date cannot be after end date";
        }
        return errors;
    };

    const onSubmitForm = (data, form) => {
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            onSubmit(data);
        } else {
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("Please correct the errors before submitting");
        }
    };

    // const initialValues = {
    //     startDate: initialData?.startDate ?? moment().subtract(30, "days").toDate(),
    //     endDate: initialData?.endDate ?? moment().toDate(),
    //     selectedTargetAudience: initialData?.selectedTargetAudience ?? selectedTargetAudience,
    // };

    const handleClearFilters = (form) => {
        form.restart({}); // Resets all fields to initialValues
        onSubmit({});
    };

    return (
        <div className="card p-fluid">
            <Accordion activeIndex={accordionActiveIndex} onTabChange={(e) => setAccordionActiveIndex(e.index)}>
                <AccordionTab header="Filters">
                    <Form
                        onSubmit={onSubmitForm}
                        initialValues={initialData}
                        initialValuesEqual={() => true}
                        validate={validate}
                        render={({ handleSubmit, form, values }) => (
                            <form onSubmit={handleSubmit} className="p-grid p-fluid">
                                <div className="grid">
                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Field name="startDate">
                                            {({ input, meta }) => (
                                                <div className="p-field m-4">
                                                    <label htmlFor="startDate">Start Date</label>
                                                    <Calendar id="startDate" {...input} dateFormat="dd/mm/yy" showIcon className={classNames({ "p-invalid": meta.error && meta.touched })} />
                                                    {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                                </div>
                                            )}
                                        </Field>
                                    </div>

                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Field name="endDate">
                                            {({ input, meta }) => (
                                                <div className="p-field m-4">
                                                    <label htmlFor="endDate">End Date</label>
                                                    <Calendar id="endDate" {...input} dateFormat="dd/mm/yy" showIcon className={classNames({ "p-invalid": meta.error && meta.touched })} />
                                                    {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                                </div>
                                            )}
                                        </Field>
                                    </div>

                                    <div className="col-12 md:col-6 lg:col-3">
                                        <Field name="selectedTargetAudience">
                                            {({ input, meta }) => (
                                                <div className="p-field m-4">
                                                    <label htmlFor="targetAudience">Filter by Target Audience</label>
                                                    <MultiSelect id="targetAudience" {...input} options={targetAudienceOptions} optionLabel="name" placeholder="Select Target Audience" className={classNames("w-full", { "p-invalid": meta.error && meta.touched })} display="chip" />
                                                    {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                                </div>
                                            )}
                                        </Field>
                                    </div>

                                    <div className="p-col-12 m-4">
                                        <div className="p-field m-4">
                                            <Button type="submit" onClick={() => setAccordionActiveIndex(null)} label="Apply Filters" className="p-button-primary" />
                                        </div>
                                    </div>

                                    <div className="p-col-12 m-4">
                                        <div className="p-field m-2">
                                            <Button type="button" label="Clear Filters" className="p-button-secondary ml-2" onClick={() => handleClearFilters(form)} />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    />
                </AccordionTab>
            </Accordion>
        </div>
    );
};

export default AdvancedFilterForm;
