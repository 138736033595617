import React, { useEffect, useState, useRef } from "react";

import { getAllActivityLogs, postToBulkDeleteActivityLogs } from "../../../services/activity-logs/activity-logs-service.js";
import EditForm from "../EditForm.jsx";
import CreateForm from "../CreateForm.jsx";
import WaterIsLoading from "../../../components/general_components/WaterIsLoading";
import moment from "moment";
import { Link } from "react-router-dom";
import MuiTable from "../../../components/general_components/MuiTable";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import UserDetailsPage from "../UserDetailsPage.jsx";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

import { Panel } from "primereact/panel";

//
import useHandleQueryError from "../../../hooks/useHandleQueryError";
//
import useHandleMutationError from "../../../hooks/useHandleMutationError.js";

//
import AdvancedFilterForm from "../AdvancedFilterForm.jsx";

//
import SystemLogDetails from "./SystemLogDetails";

import { Toast as PrimeReactToast } from "primereact/toast";

import InlineExpandableText from "../../../components/helpers/InlineExpandableText";

function List({ groupDetails, loggedInUserData }) {
    const queryClient = useQueryClient();
    const [selectedItem, setSelectedItem] = useState({ id: null });

    const [showUserForm, setShowUserForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    const [systemLogShowModal, setSystemLogShowModal] = useState(false);
    const [systemLog, setSystemLog] = useState();

    const handleOpenSystemLogModal = (rowData) => {
        setSystemLog(rowData);
        setSystemLogShowModal(true);
    };

    const handleCloseSystemLogModal = () => {
        setSystemLogShowModal(false);
    };

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    const handleShowUserForm = () => {
        setShowUserForm(true);
    };
    const handleCloseUserForm = () => {
        setShowUserForm(false);
    };

    const [selectedFilters, setSelectedFilters] = useState({
        startDate: moment().subtract(7, "days").toDate(),
        endDate: moment().toDate(),
        // selectedStatuses: [],
    });

    const getListOfActivityLogs = useQuery({ queryKey: ["activity-logs", selectedFilters], queryFn: () => getAllActivityLogs({ ...selectedFilters }) });
    console.log("sys logs list : ", getListOfActivityLogs?.data?.data);

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(getListOfActivityLogs?.isError, getListOfActivityLogs?.error);

    console.log("users list : ", getListOfActivityLogs?.data?.data);

    //
    const [deleteMutationIsLoading, setDeleteMutationIsLoading] = useState(false);
    const deleteMutation = useMutation({
        mutationFn: postToBulkDeleteActivityLogs,
        onSuccess: (data) => {
            // queryClient.resetQueries(["users"]);
            queryClient.invalidateQueries(["activity-logs"]);
            setDeleteMutationIsLoading(false);
            toast.success("deleted Successfully");
            console.log("deleted user succesfully ooooo: ");
            setTableSelectedRows([]);
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(deleteMutation?.error, setDeleteMutationIsLoading);

    // const handleDelete = async (event, id) => {
    //     console.log("users is xxxxx : ", id);
    //     var result = window.confirm("Are you sure you want to delete? ");
    //     if (result === true) {
    //         setLoading(true);
    //         deleteUserMutation.mutate(id);
    //     }
    // };

    const handleDelete = (event, id) => {
        console.log("Delete Id Is : ", id);

        let deleteUserId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(deleteUserId),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (deleteUserId) => {
        if (validateSelectedRows()) {
            console.log("Ohh Yea delete User Id Not Null");
            setDeleteMutationIsLoading(true);
            // deleteMutation.mutate(deleteUserId);
            deleteMutation.mutate({ activity_logs: tableSelectedRows });
        }
    };

    const cancelDelete = () => {
        // setDeleteDirectorateId(null);
    };

    const columns = [
        {
            title: "#",
            width: "5%",
            field: "id",
            render: (rowData) => {
                return <div>{rowData.tableData.id + 1}</div>;
            },
        },
        {
            title: "Name",
            field: "log_name",
            render: (rowData) => {
                return (
                    <>
                        <span style={{ color: "blue", cursor: "pointer" }} onClick={() => handleOpenSystemLogModal(rowData)}>
                            {rowData?.log_name}
                        </span>
                    </>
                );
            },
        },
        {
            title: "Description",
            field: "description",
            render: (rowData) => {
                const maxLength = 20; // Define max length before truncation
                const description = rowData?.description || "No description";
                return <InlineExpandableText text={description} maxLength={maxLength} />;
            },
        },
        {
            title: "Ip Address",
            field: "properties.ip",
            render: (rowData) => {
                return <span>{rowData?.properties?.ip || "System"}</span>;
            },
        },
        {
            title: "Country",
            field: "properties.country",
            hidden: true,
            render: (rowData) => {
                return <span>{rowData?.properties?.country || "N/A"}</span>;
            },
        },
        {
            title: "City",
            field: "properties.city",
            render: (rowData) => {
                return <span>{rowData?.properties?.city || "N/A"}</span>;
            },
        },

        {
            title: "User Agent",
            field: "properties.user_agent",

            render: (rowData) => {
                const maxLength = 20; // Define max length before truncation
                const userAgent = rowData?.properties?.user_agent || "No User Agent";
                return <InlineExpandableText text={userAgent} maxLength={maxLength} />;
            },
        },

        {
            title: "Causer",
            field: "properties.created_by",
            render: (rowData) => {
                return <span>{rowData?.properties?.created_by || "System"}</span>;
            },
        },
        {
            title: "Causer Email",
            field: "properties.created_by_email",
            render: (rowData) => {
                return <div>{rowData?.properties?.created_by_email || "N/A"}</div>;
            },
        },
        {
            title: "Date",
            field: "created_at",
            render: (rowData) => {
                return <div>{moment(rowData?.created_at).format("YYYY-MM-DD")}</div>;
            },
        },
        {
            title: "Time",
            field: "created_at",
            render: (rowData) => {
                return <div>{moment(rowData?.created_at).format("HH:mm:ss")}</div>;
            },
        },

        {
            title: "Region",
            field: "properties.region",
            hidden: true,
            render: (rowData) => {
                return <span>{rowData?.properties?.region || "N/A"}</span>;
            },
        },
        {
            title: "Latitude",
            field: "properties.latitude",
            hidden: true,
            render: (rowData) => {
                return <span>{rowData?.properties?.latitude || "N/A"}</span>;
            },
        },
        {
            title: "Longitude",
            field: "properties.longitude",
            hidden: true,
            render: (rowData) => {
                return <span>{rowData?.properties?.longitude || "N/A"}</span>;
            },
        },
        {
            title: "Timezone",
            field: "properties.timezone",
            hidden: true,
            render: (rowData) => {
                return <span>{rowData?.properties?.timezone || "N/A"}</span>;
            },
        },
        {
            title: "Device",
            field: "properties.device",
            hidden: true,
            render: (rowData) => {
                return <span>{rowData?.properties?.device || "N/A"}</span>;
            },
        },
        {
            title: "Platform",
            field: "properties.platform",
            hidden: true,
            render: (rowData) => {
                return <span>{rowData?.properties?.platform || "N/A"}</span>;
            },
        },
        {
            title: "Platform Version",
            field: "properties.platform_version",
            hidden: true,
            render: (rowData) => {
                return <span>{rowData?.properties?.platform_version || "N/A"}</span>;
            },
        },
        {
            title: "Browser",
            field: "properties.browser",
            hidden: true,
            render: (rowData) => {
                return <span>{rowData?.properties?.browser || "N/A"}</span>;
            },
        },
        {
            title: "Browser Version",
            field: "properties.browser_version",
            hidden: true,
            render: (rowData) => {
                return <span>{rowData?.properties?.browser_version || "N/A"}</span>;
            },
        },
    ];

    //
    //table selection
    const primeReactToastRef = useRef(null);
    const [tableSelectedRows, setTableSelectedRows] = useState([]);
    const [showNotificationsForm, setShowNotificationsForm] = useState(false);

    const validateSelectedRows = () => {
        if (tableSelectedRows.length === 0) {
            primeReactToastRef.current.show({ severity: "warn", summary: "Warning", detail: "No rows selected", life: 3000 });
            return false;
        }

        return true;
    };

    //============== status change ===================

    const handleSubmitAdvancedFilter = (formData) => {
        console.log("Form submitted with:", formData);

        setSelectedFilters(formData);
        // Handle form submission (e.g., API call, state update)
    };

    return (
        <div style={{ width: "100%" }}>
            <PrimeReactToast ref={primeReactToastRef} />
            <Panel header="System Logs" style={{ marginBottom: "20px" }} toggleable>
                <div>
                    <AdvancedFilterForm initialData={selectedFilters} onSubmit={handleSubmitAdvancedFilter} />
                </div>
                <div>
                    <div xs={12}>
                        <div>
                            <div>
                                <MuiTable
                                    tableTitle="System Logs"
                                    tableData={getListOfActivityLogs?.data?.data?.data ?? []}
                                    tableColumns={columns}
                                    // handleShowEditForm={handleShowEditForm}
                                    // showEdit={loggedInUserData?.permissions?.includes("update user")}

                                    //
                                    handleDelete={(e, item_id) => handleDelete(e, item_id)}
                                    showDelete={loggedInUserData?.permissions?.includes("delete system logs")}
                                    loading={getListOfActivityLogs.isLoading || getListOfActivityLogs.status == "loading" || deleteMutationIsLoading}
                                    //
                                    exportButton={true}
                                    pdfExportTitle="System Logs"
                                    csvExportTitle="System Logs"
                                    //multiple select
                                    selection={true}
                                    showSelectAllCheckbox={true}
                                    showTextRowsSelected={true}
                                    selectionChange={(selectedRows) => {
                                        console.log("selected rows on sselection change : ", selectedRows);
                                        setTableSelectedRows(selectedRows);
                                    }}
                                    //
                                    // //
                                    // handleViewPage={(rowData) => {
                                    //     handleOpenSystemLogModal(rowData);
                                    // }}
                                    // showViewPage={true}
                                    // hideRowViewPage={false}
                                />

                                <SystemLogDetails log={systemLog} showModal={systemLogShowModal} handleCloseModal={handleCloseSystemLogModal} />
                                <EditForm rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} loggedInUserData={loggedInUserData} />
                                <CreateForm show={showUserForm} onHide={handleCloseUserForm} onClose={handleCloseUserForm} loggedInUserData={loggedInUserData} />
                                {/* {(getListOfActivityLogs.isLoading || getListOfActivityLogs.status == "loading") && <WaterIsLoading />} */}
                            </div>
                        </div>
                    </div>
                </div>
            </Panel>
        </div>
    );
}

export default List;
