import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { MapContainer, TileLayer, Marker, useMapEvents, LayersControl } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import MapIcon from "@mui/icons-material/Map"; // Material UI Map icon

// Fix marker icon issue in Leaflet with Webpack
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const parseSafeFloat = (value) => {
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? "" : parsedValue;
};

const LocationPickerMap = ({ setLocationPickerMapSelectedLatLng, locationPickerMapSelectedLatLng }) => {
    const { BaseLayer } = LayersControl;

    const [visible, setVisible] = useState(false); // Controls map dialog visibility

    const MapClickHandler = () => {
        useMapEvents({
            click(e) {
                setLocationPickerMapSelectedLatLng(e.latlng);
            },
        });
        return locationPickerMapSelectedLatLng ? <Marker position={{ lat: parseSafeFloat(locationPickerMapSelectedLatLng?.lat), lng: parseSafeFloat(locationPickerMapSelectedLatLng?.lng) }} /> : null;
    };

    return (
        <>
            {/* Button to open the map */}
            <button type="button" className="p-button" onClick={() => setVisible(true)}>
                <MapIcon style={{ marginRight: "0.5rem" }} />
                Open Map
            </button>

            {/* Map Dialog */}
            <Dialog header="Select Location" visible={visible} onHide={() => setVisible(false)} style={{ minWidth: "60vw" }} maximizable>
                <MapContainer center={[parseSafeFloat(locationPickerMapSelectedLatLng?.lat), parseSafeFloat(locationPickerMapSelectedLatLng?.lng)]} zoom={7} scrollWheelZoom={true} attributionControl={false} fullscreenControl={true} style={{ minHeight: "80vh", width: "100%" }}>
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors' />
                    <LayersControl>
                        <BaseLayer checked name="OpenStreetMap">
                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                        </BaseLayer>

                        <BaseLayer name="Terrain View">
                            <TileLayer url="https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}" maxZoom={20} subdomains={["mt1", "mt2", "mt3"]} />
                        </BaseLayer>

                        <BaseLayer name="Satellite View">
                            <TileLayer url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" maxZoom={20} subdomains={["mt1", "mt2", "mt3"]} />
                        </BaseLayer>
                        <BaseLayer name="Hybrid View">
                            <TileLayer url="https://{s}.google.com/vt/lyrs=h&x={x}&y={y}&z={z}" maxZoom={20} subdomains={["mt1", "mt2", "mt3"]} />
                        </BaseLayer>
                    </LayersControl>
                    <MapClickHandler />
                    {locationPickerMapSelectedLatLng && (
                        <Marker
                            position={[parseSafeFloat(locationPickerMapSelectedLatLng?.lat), parseSafeFloat(locationPickerMapSelectedLatLng?.lng)]}
                            draggable={true}
                            eventHandlers={{
                                dragend: (event) => {
                                    const latlng = event.target.getLatLng();
                                    setLocationPickerMapSelectedLatLng(latlng);
                                },
                            }}
                        />
                    )}
                </MapContainer>
            </Dialog>
        </>
    );
};

export default LocationPickerMap;
