import React, { useMemo } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

// Enable Highcharts' Pie module for doughnut charts
import HighchartsMore from "highcharts/highcharts-more";
HighchartsMore(Highcharts);

const FeedbackByLocationScopeDoughnutChart = ({ data = [] }) => {
    console.log("🚀 ~ FeedbackByLocationScopeDoughnutChart ~ data:", data);

    // Aggregate feedback by location scope based on available fields like region, sector, etc.
    const feedbackByLocationScope = useMemo(() => {
        const aggregation = {};

        data.forEach((row) => {
            // Determine the location scope based on available properties
            let locationScope = row.location_scope || "Unknown"; // Use sector name or default to "Unknown"

            // Aggregate by location scope
            if (locationScope) {
                if (!aggregation[locationScope]) {
                    aggregation[locationScope] = 1;
                } else {
                    aggregation[locationScope]++;
                }
            }
        });

        return Object.entries(aggregation).map(([name, count]) => ({
            name,
            y: count,
        }));
    }, [data]);

    // Generate chart options for the doughnut chart
    const chartOptions = {
        chart: {
            type: "pie",
        },
        title: {
            text: "<small>Feedback By Location Scope</small>",
            useHtml: true,
        },
        plotOptions: {
            pie: {
                innerSize: "50%", // Doughnut chart with a hole
                dataLabels: {
                    enabled: true,
                    format: "<b>{point.name}</b>: {point.y}",
                },
            },
        },
        series: [
            {
                name: "Feedback Count",
                colorByPoint: true,
                data: feedbackByLocationScope,
            },
        ],
        exporting: {
            enabled: false, // Disable exporting module
        },
        credits: {
            enabled: false, // Disable credits link
        },
    };

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default FeedbackByLocationScopeDoughnutChart;
