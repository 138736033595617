import React, { useMemo } from "react";

const FeedbackByRegionTable = ({ data = [] }) => {
    // Aggregate feedback by region
    const feedbackByRegion = useMemo(() => {
        const aggregation = {};
        data.forEach((row) => {
            const regionName = row?.region?.name; // Access region name from the nested structure
            if (regionName) {
                if (!aggregation[regionName]) {
                    aggregation[regionName] = 1;
                } else {
                    aggregation[regionName]++;
                }
            }
        });
        return Object.entries(aggregation).map(([name, count]) => ({
            name,
            count,
        }));
    }, [data]);

    return (
        <div>
            <small style={{ marginBotton: "5px" }}>Feedback By Region</small>
            <table
                style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    tableLayout: "fixed", // Ensures equal column widths
                }}
            >
                <thead>
                    <tr>
                        <th
                            style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "left",
                                wordWrap: "break-word", // Forces wrapping of long text
                                overflowWrap: "break-word", // For better browser support
                            }}
                        >
                            Region
                        </th>
                        <th
                            style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "left",
                                wordWrap: "break-word", // Forces wrapping of long text
                                overflowWrap: "break-word", // For better browser support
                            }}
                        >
                            Feedback Count
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {feedbackByRegion.map(({ name, count }) => (
                        <tr key={name}>
                            <td
                                style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    wordWrap: "break-word", // Forces wrapping of long text
                                    overflowWrap: "break-word", // For better browser support
                                }}
                            >
                                {name}
                            </td>
                            <td
                                style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    wordWrap: "break-word", // Forces wrapping of long text
                                    overflowWrap: "break-word", // For better browser support
                                }}
                            >
                                {count}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default FeedbackByRegionTable;
