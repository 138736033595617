import React, { useMemo } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const FeedbackBySectorBarChart = ({ data = [] }) => {
    console.log("🚀 ~ FeedbackBySectorBarChart ~ data:", data);

    // Aggregate feedback by sector
    const feedbackBySector = useMemo(() => {
        const aggregation = {};
        data.forEach((row) => {
            const sectorName = row?.sector?.name; // Access sector name from the nested object
            if (sectorName) {
                // Aggregate by sector name
                if (!aggregation[sectorName]) {
                    aggregation[sectorName] = 1;
                } else {
                    aggregation[sectorName]++;
                }
            }
        });
        return Object.entries(aggregation).map(([name, count]) => ({
            name,
            count,
        }));
    }, [data]);

    // Generate chart options
    const chartOptions = {
        chart: {
            type: "column", // Vertical bars
        },
        title: {
            text: "Feedback By Sector",
        },
        xAxis: {
            categories: feedbackBySector.map((item) => item.name),
            title: {
                text: "Sectors",
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: "Feedback Count",
            },
        },
        series: [
            {
                name: "Feedback Count",
                colorByPoint: true,
                data: feedbackBySector.map((item) => item.count),
            },
        ],
        plotOptions: {
            column: {
                // Apply column-specific options
                dataLabels: {
                    enabled: true,
                },
            },
        },
        exporting: {
            enabled: false, // Disable exporting module
        },
        credits: {
            enabled: false, // Disable credits link
        },
        legend: {
            enabled: false, // Disable legend
        },
    };

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default FeedbackBySectorBarChart;
