import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { toast } from "react-toastify";

//
import moment from "moment";
import { Calendar } from "primereact/calendar";

const TimelineRow = ({ onSubmit, initialData }) => {
    console.log("🚀 ~ TimelineRow ~ initialData:", initialData);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);

    // Validate function for the form
    const validate = (values) => {
        const errors = {};
        if (!values.timeline_start_date) {
            errors.timeline_start_date = "timeline start date is required";
        }
        if (!values.timeline_end_date) {
            errors.timeline_end_date = "timeline end date is required";
        }

        // Ensure end date is not before the start date
        if (values.timeline_start_date && values.timeline_end_date && moment(values.timeline_end_date).isBefore(moment(values.timeline_start_date))) {
            errors.timeline_end_date = "Timeline end date cannot be earlier than the start date";
        }

        return errors;
    };

    // Handle form submission
    const onSubmitForm = (data, form) => {
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("Please fill in all required fields");
        }
    };

    // Handle confirmation of submission
    const onConfirm = () => {
        if (pendingData) {
            onSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    // Handle cancellation of dialog
    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    return (
        <div className="card p-fluid">
            <Form
                onSubmit={onSubmitForm}
                initialValues={initialData}
                validate={validate}
                render={({ handleSubmit, form, values }) => (
                    <form onSubmit={handleSubmit}>
                        {/* Date Range Display */}
                        <div className="m-4">
                            {values.timeline_start_date && values.timeline_end_date && (
                                <div>
                                    <strong>Date Range:</strong> {moment(values.timeline_start_date).format("MMMM Do YYYY")} - {moment(values.timeline_end_date).format("MMMM Do YYYY")}{" "}
                                    <span>({moment.duration(moment(values.timeline_end_date).diff(moment(values.timeline_start_date))).humanize()})</span>
                                </div>
                            )}
                        </div>

                        <Field name="timeline_start_date">
                            {({ input, meta }) => (
                                <div className="p-field m-4">
                                    <label htmlFor="timeline_start_date">Timeline Start Date</label>
                                    <Calendar {...input} id="timeline_start_date" showIcon showButtonBar dateFormat="dd/mm/yy" value={input.value ? moment.utc(input.value).toDate() : null} className={classNames({ "p-invalid": meta.error && meta.touched })} />
                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                </div>
                            )}
                        </Field>

                        <Field name="timeline_end_date">
                            {({ input, meta }) => (
                                <div className="p-field m-4">
                                    <label htmlFor="timeline_end_date">Timeline End Date</label>
                                    <Calendar {...input} id="timeline_end_date" showIcon showButtonBar dateFormat="dd/mm/yy" value={input.value ? moment.utc(input.value).toDate() : null} className={classNames({ "p-invalid": meta.error && meta.touched })} />
                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                </div>
                            )}
                        </Field>
                        <Button type="submit" label="Submit" className="p-button-primary" />
                    </form>
                )}
            />

            <Dialog
                header="Confirmation"
                visible={showConfirmDialog}
                style={{ minWidth: "350px" }}
                maximizable
                modal
                onHide={onCancel}
                footer={
                    <div>
                        <Button label="Yes" icon="pi pi-check" onClick={onConfirm} autoFocus />
                        <Button label="No" icon="pi pi-times" onClick={onCancel} className="p-button-secondary" />
                    </div>
                }
            >
                <p>Are you sure you want to submit ?</p>
            </Dialog>
        </div>
    );
};

export default TimelineRow;
