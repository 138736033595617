import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { toast } from "react-toastify";
import { MultiSelect } from "primereact/multiselect";

const NotificationsRow = ({ onSubmit }) => {
    //
    const statuses = [
        { label: "Not Started", code: "Not Started" },
        { label: "In Progress", code: "In Progress" },
        { label: "Completed", code: "Completed" },
        { label: "Not Applicable", code: "Not Applicable" },
    ];

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);

    // Validate function for the form
    const validate = (values) => {
        const errors = {};
        if (!values.notification) {
            errors.notification = "Notification content is required";
        }

        if (!values.statuses) {
            errors.statuses = "Statuses content is required";
        }

        return errors;
    };

    // Handle form submission
    const onSubmitForm = (data, form) => {
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            setPendingData(data);
            setShowConfirmDialog(true);
        } else {
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("Please fill in all required fields");
        }
    };

    // Handle confirmation of submission
    const onConfirm = () => {
        if (pendingData) {
            onSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    // Handle cancellation of dialog
    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    return (
        <div className="card p-fluid">
            <Form
                onSubmit={onSubmitForm}
                validate={validate}
                render={({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Field name="statuses">
                            {({ input, meta }) => (
                                <div className="p-field">
                                    <label htmlFor="statuses">Filter by Status</label>
                                    <MultiSelect id="statuses" {...input} options={statuses} optionLabel="label" placeholder="Select Statuses" className={classNames("w-full", { "p-invalid": meta.error && meta.touched })} display="chip" />
                                    {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                </div>
                            )}
                        </Field>

                        <Field name="notification">
                            {({ input, meta }) => (
                                <div className="field">
                                    <label htmlFor="notification">Notification</label>
                                    <InputTextarea id="notification" {...input} rows={3} cols={30} className={classNames({ "p-invalid": meta.touched && meta.error })} />
                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                </div>
                            )}
                        </Field>

                        <Button type="submit" label="Submit" className="p-button-primary" />
                    </form>
                )}
            />

            <Dialog
                header="Confirmation"
                visible={showConfirmDialog}
                style={{ width: "350px" }}
                modal
                onHide={onCancel}
                footer={
                    <div>
                        <Button label="Yes" icon="pi pi-check" onClick={onConfirm} autoFocus />
                        <Button label="No" icon="pi pi-times" onClick={onCancel} className="p-button-secondary" />
                    </div>
                }
            >
                <p>Are you sure you want to submit this notification?</p>
            </Dialog>
        </div>
    );
};

export default NotificationsRow;
