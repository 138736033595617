import React, { useState, useEffect } from "react";

import { Dialog } from "primereact/dialog";

import { postToBulkAssignFeedbackReportToAAGA } from "../../../services/feedback-reports/feedback-reports-service";

import SendReportToAAGARow from "../widgets/SendReportToAAGARow";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

//
import useHandleMutationError from "../../../hooks/useHandleMutationError.js";

function SendReportToAAGAMutation({ selectedTableRowsData, setTableSelectedRows, ...props }) {
    const queryClient = useQueryClient();

    const [creactMutationIsLoading, setCreactMutationIsLoading] = useState(false);
    const creactMutation = useMutation({
        mutationFn: postToBulkAssignFeedbackReportToAAGA,
        onSuccess: () => {
            queryClient.invalidateQueries(["feedback-reports"]);
            // queryClient.resetQueries(["summarised_feedback"]);
            toast.success("Action Taken Successfully");
            props.onClose();
            setCreactMutationIsLoading(false);
            setTableSelectedRows([]);
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(creactMutation?.error, setCreactMutationIsLoading);

    const handleSubmit = async (data) => {
        // event.preventDefault();
        setCreactMutationIsLoading(true);

        console.log("feedback report take Action by aagc form data we are submitting : ", data);
        let finalData = {
            action: data?.action,
            comment: data?.comment,
            feedback_reports: selectedTableRowsData,
        };
        console.log("🚀 ~ handleSubmit ~ finalData:", finalData);
        creactMutation.mutate(finalData);
    };

    return (
        <div>
            <p>Choose report fate</p>
            <SendReportToAAGARow onSubmit={handleSubmit} productCategoryBrandData={props?.productCategoryBrandData} />
            {/* <h4>{creactProgramsMutation.status}</h4> */}
            {creactMutationIsLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
        </div>
    );
}

export default SendReportToAAGAMutation;
