import React from "react";

import { Grid, Stack } from "@mui/material";
import { Panel } from "primereact/panel";

//
import StatististicsCards from "./StatististicsCards";

//
import useAuthContext from "../../../context/AuthContext";

function CardsPage() {
    const { getUserQuery } = useAuthContext();
    const loggedInUserData = getUserQuery?.data?.data;
    return (
        <Panel header={`Statistics`} toggleable style={{ minWidth: "100%" }}>
            <Grid style={{ padding: "10px" }} container spacing={2} justifyContent="center" alignItems="center">
                <StatististicsCards />
            </Grid>
        </Panel>
    );
}

export default CardsPage;
