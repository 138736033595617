import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { classNames } from "primereact/utils";
import { toast } from "react-toastify";
import moment from "moment";
import setFieldTouched from "final-form-set-field-touched";

//
import { AutoComplete } from "primereact/autocomplete";
import { ProgressSpinner } from "primereact/progressspinner";

//
import { getAllFinancialYears } from "../../../services/financial-years/financial-years-service";
import { getAllQuarters } from "../../../services/financial-years/quarters";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
//
import useHandleQueryError from "../../../hooks/useHandleQueryError";

import TaggedSummarisedFeedbackOnCreateTable from "./TaggedSummarisedFeedbackOnCreateTable";

function QuarterlyReportRowForm({ handleSubmit, initialData }) {
    const [type, setType] = useState(initialData?.type);
    // Financial Year and Quarter States
    const [dropdownFinancialYears, setDropdownFinancialYears] = useState([]);
    const [selectedFinancialYear, setSelectedFinancialYear] = useState(initialData?.financial_year);

    const [dropdownQuarters, setDropdownQuarters] = useState([]);
    const [selectedQuarter, setSelectedQuarter] = useState(initialData?.quarter);

    // Fetch Financial Years
    const financialYearsQuery = useQuery({
        queryKey: ["financialYears"],
        queryFn: getAllFinancialYears, // Replace with your actual API service
        enabled: type === "quarterly report" ? true : false,
    });

    useHandleQueryError(financialYearsQuery?.isError, financialYearsQuery?.error);

    // Fetch Quarters (Dependent on Selected Financial Year)
    const quartersQuery = useQuery({
        queryKey: ["quarters"],
        queryFn: () => getAllQuarters(), // Replace with your actual API service
        enabled: type === "quarterly report" ? true : false, // Fetch only when a financial year is selected
    });

    useHandleQueryError(quartersQuery?.isError, quartersQuery?.error);

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [pendingData, setPendingData] = useState(null);

    const typeOptions = [
        { label: "Quarterly Report", value: "quarterly report" },
        { label: "Urgent Report", value: "urgent report" },
    ];

    const statusOptions = [
        { label: "Active", value: "active" },
        { label: "Deactive", value: "deactive" },
    ];

    const validate = (values) => {
        const errors = {};
        if (!values.name) errors.name = "Name is required";
        if (!values.description) errors.description = "Description is required";
        if (!values.type) errors.type = "Type is required";
        if (!values.status) errors.status = "Status is required";
        if (!values.start_date) errors.start_date = "Start date is required";
        if (!values.end_date) errors.end_date = "End date is required";

        if (values.type === "quarterly report") {
            if (!values.financial_year) errors.financial_year = "Financial year is required";
            if (!values.quarter) errors.quarter = "Quarter is required";
        }

        return errors;
    };

    const onSubmitForm = (data, form) => {
        const errors = validate(data);
        if (Object.keys(errors).length === 0) {
            let finalData = { ...data };
            setPendingData(finalData);
            setShowConfirmDialog(true);
        } else {
            // Mark all fields as touched to show validation errors
            Object.keys(errors).forEach((field) => {
                form.mutators.setFieldTouched(field, true);
            });
            toast.warning("Please fill in all required fields");
        }
    };

    const onConfirm = () => {
        if (pendingData) {
            handleSubmit(pendingData);
            setPendingData(null);
        }
        setShowConfirmDialog(false);
    };

    const onCancel = () => {
        setShowConfirmDialog(false);
    };

    return (
        <div className="col-12 md:col-12">
            <div className="card p-fluid">
                <Form
                    onSubmit={onSubmitForm}
                    initialValues={initialData}
                    validate={validate}
                    mutators={{ setFieldTouched }}
                    render={({ handleSubmit, values, form, pristine }) => (
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                onSubmitForm(values, form);
                            }}
                        >
                            <Field name="name">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="name">Name</label>
                                        <InputText {...input} id="name" type="text" className={classNames({ "p-invalid": meta.error && meta.touched })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            <Field name="description">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="description">Description</label>
                                        <InputTextarea {...input} id="description" rows={3} className={classNames({ "p-invalid": meta.error && meta.touched })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            <Field name="type">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="type">Type</label>
                                        <Dropdown
                                            {...input}
                                            options={typeOptions}
                                            placeholder="Select a Type"
                                            className={classNames({ "p-invalid": meta.error && meta.touched })}
                                            onChange={(e) => {
                                                input.onChange(e.value); // Update the type value
                                                setType(e.value);

                                                // Clear financial_year and quarter when type is not "Quarter Report"
                                                if (e.value !== "quarterly report") {
                                                    form.batch(() => {
                                                        form.change("financial_year", undefined);
                                                        form.change("quarter", undefined);
                                                    });

                                                    setSelectedFinancialYear(null);
                                                    setSelectedQuarter(null);
                                                }

                                                // Update the form with calculated dates
                                                form.change("startDate", null);
                                                form.change("endDate", null);
                                            }}
                                            disabled={true}
                                        />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            {values.type === "quarterly report" && (
                                <>
                                    {/* Financial Year Field */}
                                    <Field name="financial_year">
                                        {({ input, meta }) => {
                                            const financialYearsData = financialYearsQuery?.data?.data?.data || [];

                                            const fetchFinancialYearSuggestions = (event) => {
                                                let query = event.query.toLowerCase();
                                                let filtered = financialYearsData.filter((year) => year?.name?.toLowerCase().includes(query));
                                                setDropdownFinancialYears(filtered);
                                            };

                                            return (
                                                <div className="p-field m-4">
                                                    <label htmlFor="financial_year">Financial Year</label>
                                                    <AutoComplete
                                                        {...input}
                                                        multiple={false}
                                                        suggestions={dropdownFinancialYears}
                                                        completeMethod={fetchFinancialYearSuggestions}
                                                        field="name"
                                                        value={selectedFinancialYear}
                                                        onChange={(e) => {
                                                            setSelectedFinancialYear(e.value);
                                                            input.onChange(e.value);

                                                            // Reset quarter when financial year changes
                                                            setSelectedQuarter(null);
                                                            setDropdownQuarters([]);

                                                            // Update the form with calculated dates
                                                            form.change("start_date", null);
                                                            form.change("end_date", null);
                                                        }}
                                                        dropdown={true}
                                                        disabled={financialYearsQuery?.isLoading}
                                                        placeholder="Select Financial Year"
                                                        className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                    {/* Show loading spinner if financialYearsQuery is loading */}
                                                    {financialYearsQuery?.isLoading && financialYearsQuery?.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            );
                                        }}
                                    </Field>

                                    {/* Quarter Field */}
                                    <Field name="quarter">
                                        {({ input, meta }) => {
                                            const quartersData = quartersQuery?.data?.data?.data || [];

                                            const fetchQuarterSuggestions = (event) => {
                                                let query = event.query.toLowerCase();
                                                let filtered = quartersData.filter((quarter) => quarter?.name?.toLowerCase().includes(query));
                                                setDropdownQuarters(filtered);
                                            };

                                            return (
                                                <div className="p-field m-4">
                                                    <label htmlFor="quarter">Quarter</label>
                                                    <AutoComplete
                                                        {...input}
                                                        multiple={false}
                                                        suggestions={dropdownQuarters}
                                                        completeMethod={fetchQuarterSuggestions}
                                                        field="name"
                                                        value={selectedQuarter}
                                                        onChange={(e) => {
                                                            setSelectedQuarter(e.value);
                                                            input.onChange(e.value);

                                                            // Prefill logic
                                                            if (selectedFinancialYear && e.value) {
                                                                const { start_month, end_month } = e.value; // Use end_month instead of number_of_months
                                                                const { start_date, end_date } = selectedFinancialYear;

                                                                // Ensure start_date and end_date are valid
                                                                if (moment(start_date, "YYYY-MM-DD", true).isValid() && moment(end_date, "YYYY-MM-DD", true).isValid()) {
                                                                    const financialYearStart = moment(start_date, "YYYY-MM-DD");
                                                                    const financialYearEnd = moment(end_date, "YYYY-MM-DD");

                                                                    // Ensure start_month and end_month are valid
                                                                    if (start_month > 0 && start_month <= 12 && end_month > 0 && end_month <= 12) {
                                                                        // Calculate the number of months
                                                                        let number_of_months;
                                                                        if (end_month >= start_month) {
                                                                            number_of_months = end_month - start_month + 1;
                                                                        } else {
                                                                            // Handles cases where end_month is in the next calendar year
                                                                            number_of_months = 12 - start_month + 1 + end_month;
                                                                        }

                                                                        // Calculate the start date within the financial year
                                                                        let startDate = financialYearStart.clone().month(start_month - 1);

                                                                        // If the start month exceeds the calendar year, adjust the year
                                                                        if (startDate.isBefore(financialYearStart)) {
                                                                            startDate.add(1, "year");
                                                                        }

                                                                        // Calculate the end date
                                                                        let endDate = startDate.clone().add(number_of_months, "months").subtract(1, "day");

                                                                        // Ensure the calculated dates are within the financial year range
                                                                        if (startDate.isBefore(financialYearStart)) {
                                                                            startDate = financialYearStart.clone();
                                                                        }
                                                                        if (endDate.isAfter(financialYearEnd)) {
                                                                            endDate = financialYearEnd.clone();
                                                                        }

                                                                        // Update the form with calculated dates
                                                                        form.change("start_date", startDate.toDate());
                                                                        form.change("end_date", endDate.toDate());
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                        dropdown={true}
                                                        disabled={quartersQuery?.isLoading || !selectedFinancialYear}
                                                        placeholder="Select Quarter"
                                                        className={classNames({ "p-invalid": meta.touched && meta.error })}
                                                    />
                                                    {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                                    {/* Show loading spinner if quartersQuery is loading */}
                                                    {quartersQuery?.isLoading && quartersQuery?.isFetching && <ProgressSpinner style={{ width: "10px", height: "10px" }} strokeWidth="4" />}
                                                </div>
                                            );
                                        }}
                                    </Field>
                                </>
                            )}
                            <Field name="start_date">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="start_date">Start Date</label>
                                        <Calendar {...input} id="start_date" showIcon showButtonBar dateFormat="dd/mm/yy" disabled={true} value={input.value ? moment.utc(input.value).toDate() : null} className={classNames({ "p-invalid": meta.error && meta.touched })} />
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            <Field name="end_date">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="end_date">End Date</label>
                                        <Calendar {...input} id="end_date" showIcon showButtonBar dateFormat="dd/mm/yy" disabled={true} value={input.value ? moment.utc(input.value).toDate() : null} className={classNames({ "p-invalid": meta.error && meta.touched })} />
                                        {meta.error && meta.touched && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>
                            <Field name="status">
                                {({ input, meta }) => (
                                    <div className="p-field m-4">
                                        <label htmlFor="status">Status</label>
                                        <Dropdown {...input} options={statusOptions} placeholder="Select a Status" className={classNames({ "p-invalid": meta.error && meta.touched })} />
                                        {meta.touched && meta.error && <small className="p-error">{meta.error}</small>}
                                    </div>
                                )}
                            </Field>

                            <Button type="submit" label="Save" className="p-button-primary" />
                        </form>
                    )}
                />
                <Dialog
                    header="Confirmation"
                    visible={showConfirmDialog}
                    onHide={onCancel}
                    footer={
                        <div>
                            <Button label="Yes" onClick={onConfirm} />
                            <Button label="No" onClick={onCancel} className="p-button-secondary" />
                        </div>
                    }
                >
                    Are you sure you want to submit?
                </Dialog>
            </div>
        </div>
    );
}

export default QuarterlyReportRowForm;
