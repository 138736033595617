import React, { useMemo } from "react";

const FeedbackByLocationScopeTable = ({ data = [] }) => {
    // Aggregate feedback by location_scope
    const feedbackByLocationScope = useMemo(() => {
        const aggregation = {};
        data.forEach((row) => {
            const locationScope = row.location_scope; // Use the `location_scope` key
            if (locationScope) {
                if (!aggregation[locationScope]) {
                    aggregation[locationScope] = 1;
                } else {
                    aggregation[locationScope]++;
                }
            }
        });
        return Object.entries(aggregation).map(([name, count]) => ({
            name,
            count,
        }));
    }, [data]);

    return (
        <div>
            <small style={{ marginBotton: "5px" }}>Feedback By Location Scope</small>
            <table
                style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    tableLayout: "fixed", // Ensures equal column widths
                }}
            >
                <thead>
                    <tr>
                        <th
                            style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "left",
                            }}
                        >
                            Location Scope
                        </th>
                        <th
                            style={{
                                border: "1px solid #ddd",
                                padding: "8px",
                                textAlign: "left",
                            }}
                        >
                            Feedback Count
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {feedbackByLocationScope.map(({ name, count }) => (
                        <tr key={name}>
                            <td
                                style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    wordWrap: "break-word", // Forces wrapping of long text
                                    overflowWrap: "break-word", // For better browser support
                                }}
                            >
                                <p>{name}</p>
                            </td>
                            <td
                                style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    wordWrap: "break-word", // Forces wrapping of long text
                                    overflowWrap: "break-word", // For better browser support
                                }}
                            >
                                <p>{count}</p>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default FeedbackByLocationScopeTable;
