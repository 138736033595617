import React, { useState, useEffect } from "react";

import { userProfileUpdate } from "../../services/auth/user-service";

import RowFormForUserProfileUpdate from "./widgets/RowFormForUserProfileUpdate";
import { useQueryClient, useMutation } from "@tanstack/react-query";

import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

//
import useHandleMutationError from "../../hooks/useHandleMutationError.js";

function UpdateUserProfile({ loggedInUserData, ...props }) {
    const queryClient = useQueryClient();

    const [editMutationIsLoading, setEditMutationIsLoading] = useState(false);

    const editMutation = useMutation({
        mutationFn: (variables) => userProfileUpdate(loggedInUserData?.id, variables),
        onSuccess: () => {
            setEditMutationIsLoading(false);
            props.onClose();
            toast.success("Edited Successfully");
            // queryClient.removeQueries();
            // queryClient.invalidateQueries(["logged-in-user"]);
            queryClient.resetQueries({ queryKey: ["logged-in-user"] });
            queryClient.removeQueries({ queryKey: ["logged-in-user"] });
            queryClient.clear();

            // queryClient.removeQueries({ queryKey: "logged-in-user" });
        },
    });
    console.log("🚀 ~ UpdateUserProfile ~ editMutation:", editMutation?.error);

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(editMutation?.error, setEditMutationIsLoading);

    const handleSubmit = (data) => {
        console.log("updating user : ", data);
        // let finalData = {
        //     ...data,
        //     directorate_id: data?.directorate?.id,
        //     region_id: data?.region?.id,
        //     district_id: data?.district?.id,
        //     county_id: data?.county?.id,
        //     subcounty_id: data?.subcounty?.id,
        //     parish_id: data?.parish?.id,
        //     village_id: data?.village?.id,
        // };

        let finalData = {
            name: data?.name,
            email: data?.email,
            phone: data?.phone,
            allow_notifications: data?.allow_notifications,
            gender: data?.gender,
            current_password: data?.current_password,
            password: data?.password,
            confirm_password: data?.confirm_password,
        };

        setEditMutationIsLoading(true);
        editMutation.mutate(finalData);
    };

    return (
        <Dialog header="Update Profile Form" visible={props.show} onHide={() => props.onHide()} maximizable>
            {/* <h3>Programs Edit Form</h3> */}
            <p>Edit Data Below</p>
            <RowFormForUserProfileUpdate loggedInUserData={loggedInUserData} initialData={loggedInUserData} handleSubmit={handleSubmit} />
            {/* <h4>{creactProgramsMutation.status}</h4> */}

            {editMutationIsLoading && (
                <center>
                    <ProgressSpinner
                        style={{
                            width: "50px",
                            height: "50px",
                            borderWidth: "8px", // Border thickness
                            borderColor: "blue", // Border color
                            animationDuration: "1s",
                        }}
                        strokeWidth="8"
                        animationDuration="1s"
                    />
                </center>
            )}
        </Dialog>
    );
}

export default UpdateUserProfile;
