import React, { useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const FeedbackBySectorTable = ({ data = [] }) => {
    // Aggregate feedback by sector
    const feedbackBySector = useMemo(() => {
        const aggregation = {};
        data.forEach((row) => {
            const sectorName = row?.sector?.name; // Access sector name from the nested structure
            if (sectorName) {
                if (!aggregation[sectorName]) {
                    aggregation[sectorName] = 1;
                } else {
                    aggregation[sectorName]++;
                }
            }
        });
        return Object.entries(aggregation).map(([name, count]) => ({
            name,
            count,
        }));
    }, [data]);

    return (
        <div>
            <small style={{ marginBottom: "5px" }}>Feedback By Sector</small>
            <DataTable value={feedbackBySector} showGridlines paginator rows={10} style={{ width: "100%" }}>
                <Column field="name" header="Sector" sortable />
                <Column field="count" header="Feedback Count" sortable />
            </DataTable>
        </div>
    );
};

export default FeedbackBySectorTable;
