import React, { useMemo, useState } from "react";
import { Dialog } from "primereact/dialog";
import moment from "moment"; // Import moment
import { Tag } from "primereact/tag";

import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { FileDownload } from "@mui/icons-material";
import { Grid, Typography, Card } from "@mui/material";

import SingleCommentsForm from "../../services-feedback/services-feedback-comments/SingleCommentsForm.jsx";
import SingleCommentsEditForm from "../../services-feedback/services-feedback-comments/SingleCommentsEditForm.jsx";

//
import MuiTable from "../../../components/general_components/MuiTable.jsx";

import { TabView, TabPanel } from "primereact/tabview";

//
import { getAllServicesFeedbackComments, getServicesFeedbackCommentById, postServicesFeedbackComment, updateServicesFeedbackComment, deleteServicesFeedbackCommentById } from "../../../services/services-feedback/services-feedback-comments-service.js";
import { toast } from "react-toastify";
//
import useHandleQueryError from "../../../hooks/useHandleQueryError.js";
import useHandleMutationError from "../../../hooks/useHandleMutationError.js";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";

//
import { Accordion, AccordionTab } from "primereact/accordion";

function ServicesFeedbackDetailsModalWithComments({ activeUser, feedbackDetailData, ...props }) {
    console.log("🚀 ~ ServicesFeedbackDetailsModalWithComments ~ feedbackDetailData fsdsdsd:", feedbackDetailData);
    const formatDate = (dateString) => {
        return moment(dateString).format("MMMM Do YYYY, h:mm:ss a"); // Example format
    };

    const getServicesFeedbackSeverityColor = (status) => {
        switch (status?.toLowerCase()) {
            case "submitted":
                return "info"; // Blue
            case "in progress":
                return "warning"; // Yellow/Orange
            case "resolved":
                return "success"; // Green
            default:
                return "secondary"; // Default gray
        }
    };

    // Function to determine the attachment type and render accordingly
    const renderAttachment = (attachment) => {
        const { type, file_path } = attachment;
        // Handle case where type is null or undefined
        const lowerCaseType = type ? type.toLowerCase() : "";

        const getFileName = (filePath) => {
            // Split the path by '/' and get the last element
            return filePath.split("/").pop();
        };

        const fileName = getFileName(file_path);

        // Extract file extension from file_path
        const fileExtension = file_path.substring(file_path.lastIndexOf(".") + 1).toLowerCase();

        // Check if it's an image (handles both "Pictures" and "image")
        if (lowerCaseType === "pictures" || lowerCaseType === "image" || lowerCaseType.includes("image")) {
            return <Image src={`${process.env.REACT_APP_BASE_URL}${file_path}`} alt="Attachment" width="150" preview />;
        }

        // Check if it's a video (handles both "Video" and "video")
        if (lowerCaseType === "video" || lowerCaseType.includes("video")) {
            return (
                <video width="320" height="240" controls>
                    <source
                        src={`${process.env.REACT_APP_BASE_URL}${file_path}`}
                        type="video/mp4"
                        // type={`video/${fileExtension}`}
                    />
                    Your browser does not support the video tag.
                </video>
            );
        }

        // Check if it's a document (PDF)
        if (lowerCaseType === "document" || lowerCaseType === "application/pdf") {
            return (
                <a href={`${process.env.REACT_APP_BASE_URL}${file_path}`} target="_blank" rel="noopener noreferrer" download>
                    <Button label={fileName} icon="pi pi-file-pdf" />
                </a>
            );
        }

        // Check if it's an audio file
        if (lowerCaseType === "audio") {
            return (
                <audio controls>
                    <source
                        src={`${process.env.REACT_APP_BASE_URL}${file_path}`}
                        type="audio/mp3"
                        // type={`audio/${fileExtension}`}
                    />
                    Your browser does not support the audio tag.
                </audio>
            );
        }

        // Default: File Download for other types
        return (
            <a href={`${process.env.REACT_APP_BASE_URL}${file_path}`} download>
                <Button label="Download fileName" icon={<FileDownload />} />
            </a>
        );
    };

    //=================== comments ====================

    const queryClient = useQueryClient();
    const { data, isLoading, isError, error, status } = useQuery({
        queryKey: ["services_feedback", "comments", "oag_service_feedback_id", feedbackDetailData?.id],
        queryFn: () => getAllServicesFeedbackComments({ oag_service_feedback_id: feedbackDetailData?.id }),
    });
    console.log("🚀 ~Services feedback comments ListPage ~ data:", data?.data?.data);

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(isError, error);

    const [deleteMutationIsLoading, setDeleteMutationIsLoading] = useState(false);
    const deleteMutation = useMutation({
        mutationFn: (variables) => deleteServicesFeedbackCommentById(variables),
        onSuccess: (data) => {
            queryClient.invalidateQueries(["services_feedback", "comments"]);
            toast.success("Deleted Successfully");
            setDeleteMutationIsLoading(false);
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(deleteMutation?.error, setDeleteMutationIsLoading);

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         ProgramDeleteMutation.mutate(id);
    //     }
    // };

    const handleDelete = (event, id) => {
        let selectedDeleteId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(selectedDeleteId),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (selectedDeleteId) => {
        if (selectedDeleteId !== null) {
            setDeleteMutationIsLoading(true);
            deleteMutation.mutate(selectedDeleteId);
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };
    const commentColumns = [
        { title: "#", width: "5%", field: "id", hidden: true },
        {
            title: "comment",
            field: "comment",
        },
        { title: "Created By", field: "created_by.name", hidden: false },
        { title: "Created By Email", field: "created_by.email", hidden: true },
        { title: "Updated By", field: "updated_by.name", hidden: true },
        {
            title: "Date",
            field: "feedback.created_at",
            hidden: false,
            render: (rowData) => {
                return moment(rowData?.feedback?.created_at).format("lll");
            },
        },
        {
            title: "Updated At",
            field: "feedback.updated_at",
            hidden: true,
            render: (rowData) => {
                return moment(rowData?.feedback?.updated_at).format("lll");
            },
        },
    ];

    //
    //table selection
    const [showCommentsForm, setShoCommentsForm] = useState(false);

    const handleShowCommentsForm = () => {
        if (feedbackDetailData) {
            setShoCommentsForm(true);
        }
    };

    const handleCloseCommentsForm = () => {
        // setTableSelectedRows([]);
        setShoCommentsForm(false);
    };

    return (
        <Dialog
            header={
                <div>
                    <div style={{ color: "green" }}>Feedback Details</div>
                </div>
            }
            visible={props.show}
            style={{ minWidth: "70vw" }}
            onHide={props.onHide}
            maximizable
        >
            <TabView scrollable={true}>
                <TabPanel header="Feedback details">
                    {/* Feedback Information */}
                    <div className="card" style={{ marginBottom: "1rem" }} title="Feedback Information">
                        <Grid container spacing={2}>
                            {/* Column 1 */}
                            <Grid item sm={12} md={6} lg={3}>
                                {/* <Typography variant="body1">
                            <strong>Feedback Number:</strong> <Tag value={feedbackDetailData?.feedback_no ? feedbackDetailData?.feedback_no : "N/A"} severity={getServicesFeedbackSeverityColor(feedbackDetailData?.spatie_current_status?.name)} />
                        </Typography> */}
                                <Typography variant="body1">
                                    <strong>Anonymous:</strong> {feedbackDetailData?.is_anonymous ? "Yes" : "No"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Topic:</strong> {feedbackDetailData?.topic?.name || "N/A"}
                                </Typography>

                                <Typography variant="body1">
                                    <strong>Created By:</strong> {feedbackDetailData?.created_by?.name || "N/A"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Created At:</strong> {feedbackDetailData?.created_at ? moment(feedbackDetailData.created_at).format("MMMM Do YYYY, h:mm:ss a") : "N/A"}
                                </Typography>

                                {/* <Typography variant="body1">
                            <strong>Accuracy:</strong> {feedbackDetailData?.accuracy || "N/A"}
                        </Typography> */}
                            </Grid>

                            {/* Column 2 */}
                            <Grid item sm={12} md={6} lg={3}>
                                {/* <Typography variant="body1">
                            <strong>Status:</strong> {tagStatus(feedbackDetailData?.status)}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Submit Status:</strong> {tagStatus(feedbackDetailData?.feedback_submit_status)}
                        </Typography> */}

                                <Typography variant="body1">
                                    <strong>Updated By:</strong> {feedbackDetailData?.updated_by?.name || "N/A"}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Updated At:</strong> {feedbackDetailData?.updated_at ? moment(feedbackDetailData.updated_at).format("MMMM Do YYYY, h:mm:ss a") : "N/A"}
                                </Typography>
                            </Grid>

                            {/* Full Width Row for Message */}
                            <Grid item xs={12}>
                                <Typography variant="body1" style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                                    <strong>Message:</strong> {feedbackDetailData?.message || "No message provided."}
                                </Typography>
                            </Grid>

                            {/* Spatie Status */}

                            <Grid item xs={12} sm={6}>
                                <Typography variant="body1">
                                    <strong>Current Status:</strong> <Tag value={feedbackDetailData?.spatie_current_status?.name ?? "Unknown"} severity={getServicesFeedbackSeverityColor(feedbackDetailData?.spatie_current_status?.name)} />
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Comment:</strong> {feedbackDetailData?.spatie_current_status?.reason}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>

                    {/* Feedback Attachments */}
                    <div className="card" title="Attachments" style={{ marginTop: "1rem" }}>
                        <Typography variant="body1">
                            <strong>Attachments:</strong>
                        </Typography>

                        <Accordion multiple>
                            {/* Pictures Section */}
                            {feedbackDetailData?.attachments?.filter((attachment) => attachment.type?.toLowerCase().includes("pictures")).length > 0 && (
                                <AccordionTab header="Pictures">
                                    <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem", justifyContent: "center" }}>
                                        {feedbackDetailData?.attachments?.filter((attachment) => attachment.type?.toLowerCase().includes("pictures")).length > 0 ? (
                                            feedbackDetailData?.attachments?.filter((attachment) => attachment.type?.toLowerCase().includes("pictures")).map((attachment, index) => <div key={index}>{renderAttachment(attachment)}</div>)
                                        ) : (
                                            <p>No pictures available</p>
                                        )}
                                    </div>
                                </AccordionTab>
                            )}

                            {/* Audio Section */}
                            {feedbackDetailData?.attachments?.filter((attachment) => attachment.type?.toLowerCase().includes("audio")).length > 0 && (
                                <AccordionTab header="Audio">
                                    <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem", justifyContent: "center" }}>
                                        {feedbackDetailData?.attachments?.filter((attachment) => attachment.type?.toLowerCase().includes("audio")).length > 0 ? (
                                            feedbackDetailData?.attachments?.filter((attachment) => attachment.type?.toLowerCase().includes("audio")).map((attachment, index) => <div key={index}>{renderAttachment(attachment)}</div>)
                                        ) : (
                                            <p>No audio files available</p>
                                        )}
                                    </div>
                                </AccordionTab>
                            )}

                            {/* Videos Section */}
                            {feedbackDetailData?.attachments?.filter((attachment) => attachment.type?.toLowerCase().includes("video")).length > 0 && (
                                <AccordionTab header="Videos">
                                    <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem", justifyContent: "center" }}>
                                        {feedbackDetailData?.attachments?.filter((attachment) => attachment.type?.toLowerCase().includes("video")).length > 0 ? (
                                            feedbackDetailData?.attachments?.filter((attachment) => attachment.type?.toLowerCase().includes("video")).map((attachment, index) => <div key={index}>{renderAttachment(attachment)}</div>)
                                        ) : (
                                            <p>No videos available</p>
                                        )}
                                    </div>
                                </AccordionTab>
                            )}

                            {/* Documents Section */}
                            {feedbackDetailData?.attachments?.filter((attachment) => attachment.type?.toLowerCase() === "application/pdf" || attachment.type?.toLowerCase() === "document").length > 0 && (
                                <AccordionTab header="Documents">
                                    <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem", justifyContent: "center" }}>
                                        {feedbackDetailData?.attachments?.filter((attachment) => attachment.type?.toLowerCase() === "application/pdf" || attachment.type?.toLowerCase() === "document").length > 0 ? (
                                            feedbackDetailData?.attachments?.filter((attachment) => attachment.type?.toLowerCase() === "application/pdf" || attachment.type?.toLowerCase() === "document").map((attachment, index) => <div key={index}>{renderAttachment(attachment)}</div>)
                                        ) : (
                                            <p>No documents available</p>
                                        )}
                                    </div>
                                </AccordionTab>
                            )}

                            {/* Other Files Section */}

                            {feedbackDetailData?.attachments?.filter(
                                (attachment) =>
                                    !attachment.type?.toLowerCase().includes("pictures") && !attachment.type?.toLowerCase().includes("audio") && !attachment.type?.toLowerCase().includes("video") && attachment.type?.toLowerCase() !== "application/pdf" && attachment.type?.toLowerCase() !== "document"
                            ).length > 0 && (
                                <AccordionTab header="Others">
                                    <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem", justifyContent: "center" }}>
                                        {feedbackDetailData?.attachments?.filter(
                                            (attachment) =>
                                                !attachment.type?.toLowerCase().includes("pictures") &&
                                                !attachment.type?.toLowerCase().includes("audio") &&
                                                !attachment.type?.toLowerCase().includes("video") &&
                                                attachment.type?.toLowerCase() !== "application/pdf" &&
                                                attachment.type?.toLowerCase() !== "document"
                                        ).length > 0 ? (
                                            feedbackDetailData?.attachments
                                                ?.filter(
                                                    (attachment) =>
                                                        !attachment.type?.toLowerCase().includes("pictures") &&
                                                        !attachment.type?.toLowerCase().includes("audio") &&
                                                        !attachment.type?.toLowerCase().includes("video") &&
                                                        attachment.type?.toLowerCase() !== "application/pdf" &&
                                                        attachment.type?.toLowerCase() !== "document"
                                                )
                                                .map((attachment, index) => <div key={index}>{renderAttachment(attachment)}</div>)
                                        ) : (
                                            <p>No other files available</p>
                                        )}
                                    </div>
                                </AccordionTab>
                            )}
                        </Accordion>
                    </div>
                </TabPanel>

                <TabPanel header="Comments">
                    {/* Feedback Comments */}
                    <div>
                        {/* <Typography variant="body1">
                    <strong>Attachments:</strong>
                </Typography> */}

                        <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                            {activeUser?.permissions.includes("create services feedback comments") && (
                                <Button
                                    label="Comment"
                                    className="p-button-primary"
                                    onClick={() => {
                                        handleShowCommentsForm();
                                    }}
                                />
                            )}
                            {showCommentsForm && <SingleCommentsForm selectedTableRowData={feedbackDetailData} show={showCommentsForm} onHide={handleCloseCommentsForm} onClose={handleCloseCommentsForm} />}
                        </div>

                        <MuiTable
                            tableTitle="Comments"
                            tableData={data?.data?.data ?? []}
                            tableColumns={commentColumns}
                            handleShowEditForm={handleShowEditForm}
                            handleDelete={(e, item_id) => handleDelete(e, item_id)}
                            showEdit={activeUser?.permissions.includes("update services feedback comments")}
                            showDelete={activeUser?.permissions.includes("delete services feedback comments")}
                            loading={isLoading || status === "loading" || deleteMutationIsLoading}
                            //
                            //
                            hideRowEdit={(rowData) => (rowData?.created_by?.id !== activeUser?.id ? true : false)}
                            hideRowDelete={(rowData) => (rowData?.created_by?.id !== activeUser?.id ? true : false)}
                            // //
                            // handleViewPage={(rowData) => {
                            //     navigate("product-category-brand", { state: { productCategoryBrandData: rowData } });
                            // }}
                            // showViewPage={true}
                            // hideRowViewPage={false}
                            //
                            exportButton={true}
                            pdfExportTitle="Feedback Comments"
                            csvExportTitle="Feedback Comments"
                        />

                        {selectedItem && <SingleCommentsEditForm rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />}
                    </div>
                </TabPanel>
            </TabView>
        </Dialog>
    );
}

export default ServicesFeedbackDetailsModalWithComments;
