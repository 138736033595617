import axiosAPI from "../axiosApi";

export async function getAllFeedbackReports(params = {}) {
    const response = await axiosAPI.get("feedback-reports", { params: params });
    return response;
}

export async function getFeedbackReportById(id) {
    const response = await axiosAPI.get(`feedback-reports/` + id);
    return response;
}

export async function postFeedbackReport(data) {
    const response = await axiosAPI.post(`feedback-reports`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function updateFeedbackReport(id, data) {
    const response = await axiosAPI.post(`feedback-reports/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function deleteFeedbackReportById(id) {
    const response = await axiosAPI.delete(`feedback-reports/${id}`);
    return response;
}

export async function postToBulkDestroyFeedbackReports(data) {
    const response = await axiosAPI.post(`bulk-destroy-feedback-reports`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function postToBulkUpdateFeedbackStatus(data) {
    const response = await axiosAPI.post(`bulk-update-feedback-report-status`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function postToCreateFeedbackQuarterlyReport(data) {
    const response = await axiosAPI.post(`store-feedback-quarterly-report`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function postToBulkUpdateFeedbackReportStatus(data) {
    const response = await axiosAPI.post(`bulkUpdateFeedbackReportStatus`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

//============================== aagc ==============================

export async function postToBulkUpdateFeedbackReportStatusByAAGC(data) {
    const response = await axiosAPI.post(`aagc-bulk-evaluate-feedback-report`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function postToBulkAssignFeedbackReportToAAGA(data) {
    const response = await axiosAPI.post(`bulk-assign-feedback-reports-to-aaga`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

//=============== aaga ==========================

export async function postToBulkAssignFeedbackReportToAMC(data) {
    const response = await axiosAPI.post(`bulk-assign-feedback-reports-to-amc`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

//=============== sent notifications through reort ==========================

export async function postToSendNotificationThrouhReport(data) {
    const response = await axiosAPI.post(`sent-notifications-through-report`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}

export async function postToSendNotificationThrouhSumFb(data) {
    const response = await axiosAPI.post(`sent-notifications-through-sum-fb`, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
}
