import React, { useEffect, useState, useRef, useMemo } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import CreateForm from "./CreateForm";

import EditForm from "./EditForm";
import CommentsForm from "./CommentsForm";
import FeedbackDetailsModal from "../../global_views/FeedbackDetailsModal";
import TakeActionForm from "./TakeActionForm";
import ApprovalForm from "./ApprovalForm";

import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";

import { useNavigate } from "react-router-dom";

import { getAllFeedbacks, getFeedbackById, postFeedback, postToUpdateFeedbackStatus, updateFeedback, deleteFeedbackById, postToBulkDestroyFeedback } from "../../../services/feedback/feedback-service";

import { Dropdown } from "primereact/dropdown";
import MuiTable from "../../../components/general_components/MuiTable";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import { Panel } from "primereact/panel";
import Tooltip from "@mui/material/Tooltip";

import { Tag } from "primereact/tag";

//
import useHandleQueryError from "../../../hooks/useHandleQueryError";
import handleMutationError from "../../../hooks/useHandleMutationError";

//
import { MultiSelect } from "primereact/multiselect";
import { classNames } from "primereact/utils";

//
import useAuthContext from "../../../context/AuthContext";

import AdvancedFilterForm from "./AdvancedFilterForm";
import useHandleMutationError from "../../../hooks/useHandleMutationError.js";

import MuiTablePdfTemplate from "../../global_views/report_templates/MuiTablePdfTemplate";
import { useReactToPrint } from "react-to-print";

import InlineExpandableText from "../../../components/helpers/InlineExpandableText";

function ListPage({ groupDetails, ...props }) {
    const navigate = useNavigate();

    const { getUserQuery } = useAuthContext();
    const loggedInUserData = getUserQuery?.data?.data;

    const [selectedFilters, setSelectedFilters] = useState({
        startDate: moment().subtract(30, "days").toDate(),
        endDate: moment().toDate(),
        selectedStatuses: [],
        groupTypes: loggedInUserData?.role === "CSO Admin" ? loggedInUserData?.group_types ?? [] : [],
        groups: loggedInUserData?.role === "CSO Admin" ? loggedInUserData?.groups ?? [] : [],
    });
    console.log("🚀dsfgdsfsd ~ ListPage ~ selectedFilters:", selectedFilters);

    // Memoize the selectedStatuses array
    const memorisedSelectedStatuses = useMemo(() => {
        return selectedFilters?.selectedStatuses.map((status) => status.code);
    }, [selectedFilters?.selectedStatuses]); // Only recompute if statuses array changes

    const queryClient = useQueryClient();
    const { data, isLoading, isError, error, status } = useQuery({
        queryKey: ["feedback", selectedFilters],
        queryFn: () =>
            getAllFeedbacks({
                created_by_group_types: selectedFilters?.groupTypes,
                created_by_groups: selectedFilters?.groups,
                spatie_current_statuses: memorisedSelectedStatuses,
                startDate: selectedFilters?.startDate ? moment(selectedFilters?.startDate).format("YYYY-MM-DD HH:mm:ss") : null,
                endDate: selectedFilters?.endDate ? moment(selectedFilters?.endDate).format("YYYY-MM-DD HH:mm:ss") : null,
            }),
    });
    console.log("🚀 ~ Feedbacks overview ListPage ~ data: ", data);

    // Use the custom hook to handle errors with useMemo on the error object
    useHandleQueryError(isError, error);

    const [deleteMutationIsLoading, setDeleteMutationIsLoading] = useState(false);
    const deleteMutation = useMutation({
        mutationFn: (variables) => postToBulkDestroyFeedback(variables),
        onSuccess: (data) => {
            queryClient.invalidateQueries(["feedback"]);
            toast.success("Deleted Successfully");
            setDeleteMutationIsLoading(false);
        },
    });

    // Use the useHandleMutationError hook, to handle mutation errors and state
    useHandleMutationError(deleteMutation?.error, setDeleteMutationIsLoading);

    // const handleDelete = async (event, id) => {
    //     var result = window.confirm("Are you sure you want to delete?");
    //     if (result === true) {
    //         ProgramDeleteMutation.mutate(id);
    //     }
    // };

    const handleDelete = (e, data) => {
        console.log("🚀 ~ handleDelete ~ data:", data);
        // let selectedDeleteId = id;
        confirmDialog({
            message: "Are you sure you want to delete?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => confirmDelete(data),
            reject: cancelDelete,
        });
    };

    const confirmDelete = (selectedDeleteData) => {
        if (selectedDeleteData !== null) {
            setDeleteMutationIsLoading(true);
            deleteMutation.mutate({ feedbacks: selectedDeleteData });
        }
    };

    const cancelDelete = () => {
        // setDeleteProgramId(null);
    };

    const [selectedItem, setSelectedItem] = useState();

    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    const handleShowEditForm = (item) => {
        setSelectedItem(item);
        setShowEditForm(true);
        console.log("handleShowEditForm is : ", item);
    };
    const handleCloseEditForm = () => {
        setSelectedItem({ id: null });
        setShowEditForm(false);
    };

    // const activeUser = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : undefined;
    const activeUser = loggedInUserData;

    const onFormClose = () => {
        setShowAddForm(false);
    };

    //
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [selectedFeedback, setSelectedFeedback] = useState(null);

    const handleShowFeedbackModal = (rowData) => {
        setSelectedFeedback(rowData);
        setShowFeedbackModal(true);
    };

    const handleCloseFeedbackModal = () => {
        setShowFeedbackModal(false);
    };

    const getSeverityColor = (status) => {
        switch (status) {
            case "submitted":
                return "info"; // Blue
            case "auditable":
                return "warning"; // Yellow/Orange
            case "non audit":
                return "danger"; // Red
            case "summarised":
                return "success"; // Green
            case "tagged":
                return "success"; // Green
            default:
                return "secondary"; // Gray or any other default color
        }
    };

    const columns = [
        { title: "#", width: "5%", field: "id", hidden: true },

        {
            title: "Feedback No",
            field: "feedback_no",
            render: (rowData) => (
                <Tag
                    value={rowData?.feedback_no ? rowData?.feedback_no : "N/A"}
                    severity={getSeverityColor(rowData?.spatie_current_status?.name)} // 'info' for blue, 'success' for green
                />
            ),
        },

        // {
        //     title: "Title",
        //     field: "title",
        //     render: (rowData) => {
        //         const maxLength = 20; // Define max length before truncation
        //         const title = rowData?.title || "No title";
        //         return title.length > maxLength
        //             ? `${title.substring(0, maxLength)}...` // Truncate and add ellipses
        //             : title; // Display full title if it's short enough
        //     },
        // },
        {
            title: "Message",
            field: "message",
            render: (rowData) => {
                const maxLength = 20; // Define max length before truncation
                const message = rowData?.message || "No message";
                return <InlineExpandableText text={message} maxLength={maxLength} />;
            },
        },

        { title: "Sector", field: "sector.name", hidden: false },
        {
            title: "Has Attachments",
            field: "has_attachments",
            render: (rowData) => {
                return rowData?.has_attachments ? "Yes" : "No";
            },
            hidden: false,
        },
        { title: "Location Scope", field: "location_scope", hidden: false },
        { title: "Country", field: "country.name", hidden: false },
        { title: "Region", field: "region.name", hidden: false },
        { title: "District", field: "district.name", hidden: false },
        { title: "County", field: "county.name", hidden: false },
        { title: "Subcounty", field: "subcounty.name", hidden: false },
        { title: "Parish", field: "parish.name", hidden: false },
        { title: "Village", field: "village.name", hidden: false },
        { title: "Latitude", field: "latitude", hidden: false },
        { title: "Longitude", field: "longitude", hidden: false },
        { title: "Current Status", field: "spatie_current_status.name", hidden: false },
        { title: "Current Status Reason", field: "spatie_current_status.reason", hidden: false },
        { title: "Created By", field: "created_by.name", hidden: false },
        { title: "Created By Email", field: "created_by.email", hidden: false },
        { title: "Updated By", field: "updated_by.name", hidden: false },
        {
            title: "Date",
            field: "created_at",
            hidden: false,
            render: (rowData) => {
                return moment(rowData?.created_at).format("lll");
            },
        },
        {
            title: "Updated At",
            field: "updated_at",
            hidden: false,
            render: (rowData) => {
                return moment(rowData?.updated_at).format("lll");
            },
        },
        { title: "Status", field: "status", hidden: false },
        { title: "Submit Status", field: "feedback_submit_status", hidden: false },
        {
            title: "View",
            field: "created_by.name",
            render: (rowData) => {
                return (
                    <div style={{ cursor: "pointer" }} onClick={() => handleShowFeedbackModal(rowData)}>
                        <Tooltip title="Click to view feedback">
                            <VisibilityIcon style={{ color: "blue" }} />
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    //
    //table selection
    const primeToast = useRef(null);
    const [tableSelectedRows, setTableSelectedRows] = useState([]);
    const [showCommentsForm, setShoCommentsForm] = useState(false);

    const validateSelectedRows = () => {
        if (tableSelectedRows.length === 0) {
            primeToast.current.show({ severity: "warn", summary: "Warning", detail: "No rows selected", life: 3000 });
            return false;
        }

        return true;
    };

    const handleShowCommentsForm = () => {
        if (validateSelectedRows()) {
            setShoCommentsForm(true);
        }
    };

    const handleCloseCommentsForm = () => {
        // setTableSelectedRows([]);
        setShoCommentsForm(false);
    };

    //=========== take action
    const [showTakeActionForm, setShoTakeActionForm] = useState(false);

    const takeActionvalidateSelectedRows = () => {
        if (tableSelectedRows.length === 0) {
            primeToast.current.show({ severity: "warn", summary: "Warning", detail: "No rows selected", life: 3000 });
            return false;
        }

        return true;
    };

    const handleShowTakeActionForm = () => {
        if (takeActionvalidateSelectedRows()) {
            setShoTakeActionForm(true);
        }
    };

    const handleCloseTakeActionForm = () => {
        // setTableSelectedRows([]);
        setShoTakeActionForm(false);
    };

    //=============== evaluate feedback thus approve or reject ============

    const [showEvaluteFeedbackForm, setShowEvaluteFeedbackForm] = useState(false);

    const evaluateFeedBackvalidateSelectedRows = () => {
        if (tableSelectedRows.length === 0) {
            primeToast.current.show({ severity: "warn", summary: "Warning", detail: "No rows selected", life: 3000 });
            return false;
        }

        return true;
    };

    const handleShowEvaluteFeedbackForm = () => {
        if (evaluateFeedBackvalidateSelectedRows()) {
            setShowEvaluteFeedbackForm(true);
        }
    };

    const handleCloseEvaluteFeedbackForm = () => {
        // setTableSelectedRows([]);
        setShowEvaluteFeedbackForm(false);
    };

    //============== status change ===================

    const handleSubmitAdvancedFilter = (formData) => {
        console.log("dsdfsdf Form submitted with:", formData);

        setSelectedFilters(formData);
        // Handle form submission (e.g., API call, state update)
    };

    // Conditionally format start and end dates or use "N/A" if null
    const formattedStartDate = selectedFilters.startDate ? moment(selectedFilters.startDate).format("MMMM D, YYYY") : "N/A";
    const formattedEndDate = selectedFilters.endDate ? moment(selectedFilters.endDate).format("MMMM D, YYYY") : "N/A";

    return (
        <div style={{ width: "100%" }}>
            {/* <div className="col-12 xl:col-12">
                <div className="card">
                    <p>Funders Are Attched onto subprojects</p>
                </div>
            </div> */}
            <Panel header="Feedback" style={{ marginBottom: "20px" }} toggleable>
                <div>
                    <AdvancedFilterForm initialData={selectedFilters} onSubmit={handleSubmitAdvancedFilter} />
                </div>
                <div style={{ height: "3rem", margin: "1rem", display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
                    {/* {activeUser?.permissions.includes("create feedback") && tableSelectedRows.length > 0 && (
                        <ButtonhandleSubmitAdvancedFilter
                            label="Take Action"
                            className="p-button-primary"
                            onClick={() => {
                                handleShowEvaluteFeedbackForm();
                            }}
                        />
                    )}
                    {showEvaluteFeedbackForm && <ApprovalForm setTableSelectedRows={setTableSelectedRows} selectedTableRowsData={tableSelectedRows} show={showEvaluteFeedbackForm} onHide={handleCloseEvaluteFeedbackForm} onClose={handleCloseEvaluteFeedbackForm} />} */}

                    {/* {activeUser?.permissions.includes("create feedback") && tableSelectedRows.length > 0 && (
                    <Button
                        label="Take Action"
                        className="p-button-primary"
                        onClick={() => {
                            handleShowTakeActionForm();
                        }}
                    />
                )}
                {showTakeActionForm && <TakeActionForm setTableSelectedRows={setTableSelectedRows} selectedTableRowsData={tableSelectedRows} show={showTakeActionForm} onHide={handleCloseTakeActionForm} onClose={handleCloseTakeActionForm} />} */}

                    {activeUser?.permissions.includes("notify feedback reporters") && tableSelectedRows.length > 0 && (
                        <Button
                            label="Notify Reporter"
                            className="p-button-primary"
                            onClick={() => {
                                handleShowCommentsForm();
                            }}
                        />
                    )}
                    {showCommentsForm && <CommentsForm setTableSelectedRows={setTableSelectedRows} selectedTableRowsData={tableSelectedRows} show={showCommentsForm} onHide={handleCloseCommentsForm} onClose={handleCloseCommentsForm} />}

                    {/* {activeUser?.permissions.includes("create feedback") && <Button label="Add Feedback" className="p-button-primary" onClick={() => setShowAddForm(true)} />}
                    <CreateForm show={showAddForm} onHide={() => setShowAddForm(false)} onClose={onFormClose} productCategoryBrandData={productCategoryBrandData} /> */}
                </div>

                <MuiTable
                    // tableTitle="Citized Feedback"
                    tableData={data?.data?.data ?? []}
                    tableColumns={columns}
                    // showEdit={activeUser?.permissions.includes("update feedback")}
                    // handleShowEditForm={handleShowEditForm}
                    // handleBulkDelete={(e, item) => handleDelete(e, item)}
                    // showBulkDelete={activeUser?.permissions.includes("delete feedback")}
                    loading={isLoading || status === "loading" || deleteMutationIsLoading}
                    // //
                    // handleViewPage={(rowData) => {
                    //     navigate("product-category-brand", { state: { productCategoryBrandData: rowData } });
                    // }}
                    // showViewPage={true}
                    // hideRowViewPage={false}
                    //
                    exportButton={true}
                    pdfExportTitle={`Citizen Feedback Plaform Report: ${formattedStartDate} - ${formattedEndDate}`}
                    csvExportTitle={`Citizen Feedback Plaform Report: ${formattedStartDate} - ${formattedEndDate}`}
                    //
                    pdfCustomExporting={true}
                    pdfCustomReportTemplate={MuiTablePdfTemplate}
                    // handlePdfCustomDownload={handlePrint}
                    // //multiple select
                    selection={activeUser?.permissions.includes("notify feedback reporters") ? true : false}
                    showSelectAllCheckbox={activeUser?.permissions.includes("notify feedback reporters") ? true : false}
                    showTextRowsSelected={activeUser?.permissions.includes("notify feedback reporters") ? true : false}
                    selectionChange={(selectedRows) => {
                        console.log("selected rows on sselection change : ", selectedRows);
                        setTableSelectedRows(selectedRows);
                    }}
                />

                {showFeedbackModal && <FeedbackDetailsModal feedbackDetailData={selectedFeedback} show={showFeedbackModal} onHide={handleCloseFeedbackModal} onClose={handleCloseFeedbackModal} />}
                {selectedItem && <EditForm rowData={selectedItem} show={showEditForm} onHide={handleCloseEditForm} onClose={handleCloseEditForm} />}
            </Panel>
        </div>
    );
}

export default ListPage;
